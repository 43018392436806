
// SLICK SLIDER
// Used in Related Content carousel and Staff members NodeList



// DEFAULT STYLES

// This is the default file that comes with slick slider, any unnecessary rules will be commented out
// https://github.com/kenwheeler/slick/blob/master/slick/slick-theme.css

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	// height: 100%;
	min-height: 1px;
	
	// [dir="rtl"] & {
	//     float: right;
	// }

	img {
		display: block;
	}
	
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}



// CUSTOM EUROPOL STYLES

.slick-slider {
	width: 100%; // fix: when the number of items is less than maxSlidesToShow, slides are not visible.


	
	//* ANCHOR LAYOUT *//

	display: grid;

	.slick-list {
		margin-left: calc(-1 * var(--slickSliderItemSpacing, 0));
		margin-right: calc(-1 * var(--slickSliderItemSpacing, 0));
	}



	//* ANCHOR TEASERS' LIST *//

	.slick-track {
		display: flex;
		align-items: stretch;
	}

	.slick-list {
		// So that box-shadow is visible
		padding-top: var(--slickSliderListSpacingTb, $slick-slider-list-spacing-tb);
		padding-bottom: var(--slickSliderListSpacingTb, $slick-slider-list-spacing-tb);
		margin-top: calc(-1 * var(--slickSliderListSpacingTb, #{$slick-slider-list-spacing-tb}));
		margin-bottom: calc(-1 * var(--slickSliderListSpacingTb, #{$slick-slider-list-spacing-tb}));
	}

	// ITEM
	.slick-slide {
		& > div,
		article {
			height: 100%;
		}

		// padding
		& > div {
			padding: 0 var(--slickSliderItemSpacing, 0px);
		}
	}



	//* ANCHOR ARROW NAVIGATION *//

	.slick-arrow {
		@extend %navigation-arrow;

		// Js override
		&[style*="display: block"] {
			display: flex !important;
		}

		@include breakpoint($slick-slider-breakpoint down) {
			width: $slick-slider-nav-button-size-mobile;
			height: $slick-slider-nav-button-size-mobile;
			margin-top: $slick-slider-list-dots-spacing;

			&:after {
				font-size: rem-calc(16);
			}

			@include hover {
				color: Primary(1);
			}

			&.focus-visible {
				border-color: Primary(0);
			}
		}

		@include breakpoint($slick-slider-breakpoint up) {
			&.slick-prev {
				margin-left: calc(-1 * var(--pageSpacing));
			}

			&.slick-next {
				margin-right: calc(-1 * var(--pageSpacing));
			}
		}
	}



	//* ANCHOR DOT NAVIGATION *//

	.slick-dots {
		@extend %list-neutralizer;
		padding-top: $slick-slider-list-dots-spacing;
		align-items: center;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;

		// Js override
		&[style*="display: block"] {
			display: flex !important;
		}


		// DOTS
		li {
			display: flex;
			padding: Spacing(4);

			@include breakpoint($slick-slider-breakpoint up) {
				padding: Spacing(8);
			}
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: Spacing(8);
			height: Spacing(8);
			font-size: 0px;
			border-radius: 50%;
			background-color: var(--slickSliderDotBgColor, $slick-slider-dot-bg-color);
			box-shadow: 0 0 0 0px transparent;
			transition: $global-transition;

			@include breakpoint($slick-slider-breakpoint up) {
				width: rem-calc(15);
				height: rem-calc(15);
			}

			@include hover {
				background-color: var(--slickSliderDotBgColorHover, $slick-slider-dot-bg-color-hover);
				box-shadow: 0 0 0 2px var(--slickSliderDotBgColorHover, $slick-slider-dot-bg-color-hover);
			}

			&.focus-visible {
				box-shadow: 0 0 0 3px var(--slickSliderDotBgColor, $slick-slider-dot-bg-color);
			}
		}

		li.slick-active button {
			--slickSliderDotBgColor: var(--slickSliderDotBgColorActive, #{$slick-slider-dot-bg-color-active});
		}
	}
}