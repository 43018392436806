
// COMMON ELEMENTS
// This file contains general rules about common html elements.


//* ANCHOR BOX SIZING *//

*,
*:before,
*:after {
	box-sizing: border-box;
}



//* ANCHOR BODY *//

body {
	margin: 0;
	padding: 0;
	background: $body-bg-color;
	font-family: $font-family;
	font-weight: $font-weight-regular;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	* {
		color: var(--txColor, $text-color);
	}
}



//* ANCHOR SCROLLBARS *//

html, // FF fix
body {
	scroll-behavior: smooth;

	@media (hover: hover) and (pointer: fine) {
		@include scrollbars($scrollbar-width, $scrollbar-thumb-color, $scrollbar-track-color);
	}
}



//* ANCHOR FOCUS *//

// Deactivates outline on focus since we are using focus-visible polyfill. 

*:focus {
	outline: 0px;
}



//* ANCHOR LINKS *//

a {
	text-decoration: none;
	color: var(--linkColor, $link-color);
	transition: $global-transition;

	// REMOVE FOCUS OUTLINE
	&:focus {
		outline: 0px;
	}


	// EXTERNAL
	&[rel~="external"],
	&.external {
		@extend %external-link;
	}
}



//* ANCHOR OVERRIDE USER AGENT *//

input,
button,
select,
textarea {
	font-family: $font-family;
}

select {
	white-space: normal;
	margin: 0px; // Safari fix

	option {
		white-space: normal;
	}
}



//* ANCHOR IFRAMES AND VIDEOS *//

iframe {
	border: 0px;
	width: 100%;
}

.video-wrapper {
	position: relative;
	height: 0;
	margin-bottom: 1rem;
	padding-bottom: 56.25%;
	overflow: hidden;
	border-radius: $border-radius-8;
	margin-bottom: var(--globalVerticalSpacing);

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
}