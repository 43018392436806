
// HOME PAGE

.NodePanel.ct_home_page {
	padding-top: Spacing(24);
	padding-left: 0px;
	padding-right: 0px;

	@include breakpoint($main-breakpoint up) {
		padding-top: Spacing(48);
	}



	//* ANCHOR H1 *//

	h1 {
		padding: 0px var(--pageSpacing);
		margin-bottom: Spacing(24);
		font-size: 0px;
		line-height: 18px;
		background-image: url(~sass/images/logo.svg);
		background-repeat: no-repeat;
		background-size: 194px 18px;
		background-position: center center;


		// Show only for sr in large screens
		@include breakpoint($fixed-app-header-breakpoint up) {
			position: absolute !important;
			width: 1px !important;
			height: 1px !important;
			padding: 0 !important;
			overflow: hidden !important;
			clip: rect(0, 0, 0, 0) !important;
			white-space: nowrap !important;
			border: 0 !important;
		}
	}



	//* ANCHOR SECTIONS *//

	.layout {

		// VARIABLES
		--layoutPaddingTop: 			#{Spacing(48)};
		--layoutPaddingBottom: 			#{Spacing(32)};

		@include breakpoint($main-breakpoint up) {
			--layoutPaddingTop:		 	#{Spacing(80)};
			--layoutPaddingBottom: 		#{Spacing(48)};
		}

		padding-top: var(--layoutPaddingTop);
		padding-bottom: var(--layoutPaddingBottom);

		@include breakpoint($main-breakpoint up) {
			& + section.layout {
				margin-top: Spacing(32);
			}

			&.crimeAreas {
				--layoutPaddingTop:		 #{Spacing(68)};
				--layoutPaddingBottom: 	 #{Spacing(68)};
			}
		}


		// HEADERS
		h2 {
			margin-bottom: Spacing(48);
			font-family: $font-family-condensed;
			font-size: rem-calc(24);
			line-height: 1;
			font-weight: bold;
			color: #{Grey(1)};
			text-transform: uppercase;
			text-align: center;

			@include breakpoint($typography-breakpoint up) {
				margin-bottom: Spacing(64);
				font-size: rem-calc(64);
			}
		}


		// BUTTON SPACING
		& > div > a.button {
			margin-top: Spacing(24);

			@include breakpoint($main-breakpoint up) {
				margin-top: Spacing(48);
			} 
		}


		// FIRST
		&.bannersNews {
			--layoutPaddingTop: 0;
			column-gap: 0px;
			row-gap: 0px;

			@include breakpoint($main-breakpoint up) {
				grid-template-columns: 53% 47%;
			}
		}


		// GREY
		@include breakpoint($main-breakpoint up) {
			&.grey:before {
				height: 50%;
			}

			// Section that is grey in desktop and white in devices 
			&.guides {
				@include full-screen-background($bg-grey);

				&:before {
					height: 50%;
				}

				&:after {
					--curveColor: #{$curve-color};
				}
			}

			&.crimeAreas:after {
				content: none;
			}
		}

		&.updates:after {
			--curveColor: #{$curve-color};
		}
	}



	//* ANCHOR BLUE TEASERS *//

	.NodeTeaser.ct_news {
		@extend %blue-bg-teaser;
	}

	// More specific in order to override global teaser hover styles
	.NodeTeaser.ct_document .content-wrapper {
		@extend %blue-bg-teaser;
	}

	// Remove bg color from teaser image on hover
	.NodeTeaser.ct_document,
	.NodeTeaser.ct_guide {
		@include hover {
			.no-image {
				--nodeTeaserNoImageBgColor: #{$teaser-image-bg-color-dark};
			}
		}
	}



	//* ANCHOR BANNERS *//

	.banners {
		@extend %theme-dark-blue;
		padding-bottom: Spacing(48);
	
		@include breakpoint($main-breakpoint up) {
			padding: 0 Spacing(32) Spacing(80);
		}
	
	
	
		//* ANCHOR BACKGROUND *//
	
		@include full-screen-background(Blue(2));
	
		&:before {
			top: auto;
			bottom: 0px;
			height: calc(100% - 18vw);
	
			@include breakpoint($main-breakpoint up) {
				height: calc(100% - 8.8vw);
			}
		}
	
	
	
		//* ANCHOR ITEMS *//
	
		$focus-border-width: Spacing(4);
		$banner-spacing: Spacing(16);
	
		.slick-slider .slick-slide {
			
			article {
				position: relative;
				height: auto;
				padding: $focus-border-width;
			}
	
	
			// TITLE
			h3 {
				--linkColor: white;
				margin-top: Spacing(32);
				margin-bottom: $banner-spacing;
				font-family: $font-family-condensed;
				font-size: rem-calc(36);
				line-height: 1;
				font-weight: $font-weight-regular;
				text-align: center;
				
				@include breakpoint($main-breakpoint up) {
					padding: 0px Spacing(20);
					margin-top: Spacing(48);
					font-size: rem-calc(45);
				}
	
				// Make the entire box clickable
				a {
					&:before {
						content: " ";
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						border-radius: 12px;
						transition: $global-transition;
					}
	
					@include hover {
						--linkColor: 	#{Orange(0)};
					}
	
					&.focus-visible:before {
						box-shadow: inset 0 0 0 $focus-border-width Orange(0);
					}
				}
			}
	
	
			// BODY
			.ClickableDrupalContent {
				text-align: center;
				margin-bottom: Spacing(16);
	
				p {
					@extend %h4;
					margin-bottom: Spacing(8);
				}
	
				*:last-child {
					margin-bottom: 0px;
				}
			}
	
	
			// WITH IMAGE
			.image-wrapper {
				border-radius: $border-radius-8;
				overflow: hidden;

				img {
					width: 100%;
					height: auto;
				}
			}
	
	
			// WITH VIDEO
			.video-wrapper {
				z-index: 1;
			}
		}
	}



	//* ANCHOR TRANSLATIONS DROPDOWN *//

	section.bannersNews > div:first-child {
		display: flex;
		flex-direction: column;
		align-items: center;

		.dropdown-filter {
			margin-top: Spacing(64);
			margin-left: Spacing(32);
			margin-right: Spacing(32);

			@include breakpoint($main-breakpoint down) {
				display: none;
			}
		}
	}



	//* ANCHOR NEWS *//

	.news {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include breakpoint($main-breakpoint down) {
			@include decorative-curve(top);
			--curveColor: #{Blue(2)};
		}

		h2 {
			margin-top: Spacing(48);

			@include breakpoint($main-breakpoint up) {
				position: absolute !important;
				width: 1px !important;
				height: 1px !important;
				padding: 0 !important;
				overflow: hidden !important;
				clip: rect(0, 0, 0, 0) !important;
				white-space: nowrap !important;
				border: 0 !important;
			}
		}

		& > div:first-of-type {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: Spacing(16);
			margin-bottom: var(--globalVerticalSpacing);

			@include breakpoint($mobile-1 down) {
				justify-content: center;
				flex-direction: column;
			}

			a.button {
				margin-bottom: 0px;
			}
		}
		
		
		// MAGIC GRID
		$grid-spacing: Spacing(8);

		& > div {
			width: 100%;
		}
		
		.newsMagicGrid ul {
			@extend %list-neutralizer;
			margin: -#{$grid-spacing};

			& > li {
				width: 100%;
				max-width: 100%;
				padding: $grid-spacing;

				@include breakpoint($mobile-0 up) {
					width: 50%;
				}

				@include breakpoint($tablet-0 up) {
					width: 33.33%;
				}

				@include breakpoint($main-breakpoint up) {
					width: 50%;
				}
			}
		}
	}



	//* ANCHOR ADS *//

	.ads {
		@include breakpoint($main-breakpoint up) {
			margin-bottom: calc(var(--globalVerticalSpacing) + #{Spacing(56)});

			& + section.layout.curve:after {
				--curveColor: white;
			}
		}

		.ClickableDrupalContent {
			display: flex;
			flex-direction: column;
		
			@include breakpoint($main-breakpoint up) {
				display: grid;
				grid-template-columns: 37.5fr 62.5fr;
				column-gap: Spacing(80);
			}

			h2 {
				@include typography(h2);
				color: var(--txColor);
				text-transform: none;
				text-align: left;
			}

			.image {
				order: -1;
				grid-column: 1;
				grid-row: 1 / span 30; // all available rows

				@include breakpoint($main-breakpoint up) {
					margin-bottom: 0px;
				}
			}

			& > *:not(.image) {
				grid-column: 2;
			}
		}
	}



	//* ANCHOR DOCUMENTS *//

	.documents {

		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}


		// SLICK
		.slick-slider {
			--slickSliderItemSpacing: #{Spacing(8)};
			--slickSliderListSpacingTb: #{Spacing(32)};

			@include breakpoint($laptop-0 up) {
				--slickSliderItemSpacing: #{Spacing(16)};
			}
		}


		// TEASERS
		.NodeTeaser.ct_document {
			--nodeTeaserImageWrapperPaddingT: #{$teaser-image-wrapper-padding-top-portrait};

			// Only for devices that support hover
			@media (hover: hover) and (pointer: fine) {
				transition: $global-transition;

				.image-wrapper {
					border-radius: $teaser-border-radius;
				}

				.content-wrapper {
					position: absolute;
					bottom: -100px;
					width: 100%;
					opacity: 0;
					max-height: calc(100% - 60px);
					transition: $global-transition;

					// In case the content is taller than the container.
					h3 {
						overflow: hidden;
					}

					// Remove focus styling
					a.focus-visible:before {
						box-shadow: none;
					}
				}

				&:hover,
				&:focus-within {
					box-shadow: none;
					z-index: 1;

					.content-wrapper {
						bottom: 0px;
						opacity: 1;
					}
				}
			}
		}
	}


	
	//* ANCHOR PREVENTION GUIDES *//

	.guides {

		// VARIABLES
		$hover-helper-extra-height: Spacing(24);


		// BUTTON ALIGNMENT
		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}


		// SLICK
		.slick-slider {
			--slickSliderItemSpacing: #{Spacing(8)};
			--slickSliderListSpacingTb: #{Spacing(32)};
		}


		// TEASERS
		.NodeTeaser.ct_guide {
			--nodeTeaserImageWrapperPaddingT: #{$teaser-image-wrapper-padding-top-portrait};
			box-shadow: none;
			overflow: visible;

			.image-wrapper {
				box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.24);
				border-radius: $teaser-border-radius;
			}

			.content-wrapper {
				background-color: transparent;
				flex-grow: 0;

				// Hover helper
				&:before {
					content: " ";
					position: absolute;
					top: -$hover-helper-extra-height;
					left: 0px;
					width: 100%;
					height: 0px;
					background-color: $bg-dark-blue;
					z-index: -1;
					border-radius: $teaser-border-radius;
					transition: $global-transition;
				}
			}

			.publish-date {
				display: none;
			}

			@include hover {
				--nodeTeaserTxColor: 		white;

				.content-wrapper:before {
					height: calc(100% + #{$hover-helper-extra-height});
				}
			}
		}


		// INVERTED TEASER
		@include breakpoint($mobile-0 up) {
			.slick-slide:nth-child(even) .NodeTeaser.ct_guide .content-wrapper {
				order: -1;
				margin-top: auto;

				&:before {
					top: auto;
					bottom: -$hover-helper-extra-height;
				}

				h3 {
					margin-bottom: 0px;
					margin-top: auto;
				}

				a:before {
					bottom: auto;
					top: 0px;
				}
			}
		}
	}



	//* ANCHOR CENTRES *//

	.centres {
		
		&.layout.grey:before {
			height: 70%;
			background: linear-gradient(180deg, #EBEBEB 80%, #FFFFFF 100%);
		}

		ul {
			@extend %list-neutralizer;
			display: grid;
			gap: Spacing(16);
			grid-template-columns: repeat(var(--itemsPerRow, 1), 1fr);

			@include breakpoint($mobile-0 up) {
				--itemsPerRow: 2;
			}

			@include breakpoint($tablet-0 up) {
				--itemsPerRow: 3;
			}
			
			@include breakpoint($laptop-1 up) {
				--itemsPerRow: 5;
				gap: Spacing(20);
			}
		}
	}



	//* ANCHOR UPDATES *//

	.updates {

		// VARIABLES
		$filters-spacing: 				Spacing(8);
		$results-spacing: 				Spacing(8);
		$events-bg-color: 				Blue(1);
		$events-bg-color-hover: 		Blue(2);
		$agreements-tx-color: 			white;
		$agreements-bg-color: 			Green(2);
		$agreements-bg-color-hover: 	darken(Green(2), 3%);
		$agreements-tx-color-focus: 	Green(2);

		--updatesVerticalSpacing: 		#{Spacing(32)};

		@include breakpoint($main-breakpoint up) {
			--updatesVerticalSpacing: #{Spacing(48)};
		}


		// STYLING
		ul {
			@extend %list-neutralizer;
		}


		// FILTERS
		ul.filters {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin: -$filters-spacing;
			margin-bottom: calc(var(--updatesVerticalSpacing) - #{$filters-spacing});
			padding-bottom: 0px;
		
			li {
				padding: $filters-spacing;
			}

			button {
				margin-bottom: 0px;

				&.vacancies {
					@extend %button-turquoise;
				}

				&.events {
					--btnBgColor:		#{$events-bg-color};
					--btnTxColor:		white;
					--btnBgColorHover:	#{$events-bg-color-hover};
					--btnBrColorFocus:	#{Blue(2)};
					--btnTxColorFocus:	#{Blue(3)};
				}

				&.procurement {
					@extend %button-yellow;
				}

				&.all {
					@extend %button-orange;
				}

				&.active {
					pointer-events: none;
				}

				&.agreements {
					--btnBgColor:		#{$agreements-bg-color};
					--btnTxColor:		#{$agreements-tx-color};
					--btnBgColorHover:	#{$agreements-bg-color-hover};
					--btnBrColorFocus:	#{$agreements-bg-color};
					--btnTxColorFocus:	#{$agreements-tx-color-focus};
				}
			}
		}


		// RESULTS

		& > div > div {
			width: 100%;
			max-width: 1256px;
			margin: 0 auto;
		}

		.results ul {
			margin: -$results-spacing;

			& > li {
				width: 100%;
				padding: $results-spacing;

				@include breakpoint($mobile-0 up) {
					width: 50%;
				}
	
				@include breakpoint($tablet-0 up) {
					width: 33.33%;
				}
			}
		}


		// ITEMS
		article {
			position: relative;
			padding: Spacing(32);
			transition: $global-transition;

			* {
				color: var(--updateItemTxColor);
				transition: $global-transition;
			}

			// Title
			h3 {
				@extend %paragraph;
				font-weight: $font-weight-bold;
				margin-bottom: Spacing(4);
			}

			// Content
			& > div {
				font-size: rem-calc(12);
				line-height: rem-calc(20);
				font-weight: $font-weight-bold;

				&:last-child,
				label {
					text-transform: uppercase;
				}

				&:last-child {
					margin-top: Spacing(32);
				}
			}


			// Clickable box and backgrounds
			a {
				&:before,
				&:after {
					content: " ";
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					border-radius: $border-radius-8;
				}

				&:before {
					background-color: var(--updateItemBgColor);
					z-index: -1;
					transition: $global-transition;
				}

				@include hover {
					&:before {
						background-color: var(--updateItemBgColorHover);
					}
				}
			}


			// Focus
			&:focus-within * {
				color: var(--updateItemTxColorFocus);
			}

			a.focus-visible:before {
				background-color: white;
				box-shadow: 0px 0px 0px 5px var(--updateItemBgColor), 0px 8px 4px rgba(0, 0, 0, 0.4);
			}


			// Color themes
			&.ct_vacancy {
				--updateItemBgColor: 		#{Secondary(0)};
				--updateItemBgColorHover: 	#{Secondary(1)};
				--updateItemTxColor: 		#{Blue(3)};
				--updateItemTxColorFocus: 	#{Blue(3)};
			}

			&.ct_event {
				--updateItemBgColor: 		#{$events-bg-color};
				--updateItemBgColorHover: 	#{$events-bg-color-hover};
				--updateItemTxColor: 		white;
				--updateItemTxColorFocus: 	#{Blue(3)};
			}

			&.ct_tender {
				--updateItemBgColor: 		#{Yellow(0)};
				--updateItemBgColorHover: 	#{Yellow(1)};
				--updateItemTxColor: 		#{Grey(2)};
				--updateItemTxColorFocus: 	#{Grey(2)};
			}
			
			&.ct_agreement {
				--updateItemBgColor: 		#{$agreements-bg-color};
				--updateItemBgColorHover: 	#{$agreements-bg-color-hover};
				--updateItemTxColor: 		#{$agreements-tx-color};
				--updateItemTxColorFocus: 	#{$agreements-tx-color-focus};
			}
		}


		// BUTTONS
		.inline-links {
			justify-content: center;
			margin-bottom: 0px;
			margin-top: calc(var(--updatesVerticalSpacing) - #{$results-spacing});
		}
	}



	//* ANCHOR CRIME AREAS *//

	.crimeAreas > div {

		// MOBILE ONLY
		@include breakpoint($main-breakpoint down) {
			padding-left: var(--pageSpacing); 
			padding-right: var(--pageSpacing); 
		}


		// DESKTOP ONLY
		@include breakpoint($main-breakpoint up) {
			display: flex;
			align-items: stretch;
			padding-right: var(--pageSpacing);
			min-height: 200px;

			& > div {
				&:first-child {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 272px;
					width: 288px;
				}

				&:last-child {
					flex-grow: 1;
					display: flex;
					align-items: center;
					padding: Spacing(32) Spacing(48);
					border-left: 1px solid Grey(0);
					border-right: 1px solid Grey(0);
				}
			}

			// Header
			h2 {
				display: flex;
				justify-content: center;
				margin: 0px;
				padding-top: calc(#{Spacing(24)} + var(--pageSpacing));
				transform: rotate(-90deg);
				max-width: 200px;
				height: 288px;

				@include get-icon(before, $common-icons, box-curve, Blue(2), var(--curveFontSize, #{rem-calc(8)}), 1);

				&:before {
					position: absolute;
					top: 0px;
				}
			}
		}


		// LIST
		ul {
			@extend %inline-list-with-circles;
	
			a {
				font-family: $font-family-condensed;
				font-weight: $font-weight-regular;

				@include breakpoint($main-breakpoint up) {
					display: inline-block;
					margin-top: Spacing(8);
					margin-bottom: Spacing(8);
					font-size: rem-calc(24);
					line-height: rem-calc(36);
				}
			}

			li:nth-child(even) a {
				--linkColor: #{Purple(0)};
			}
		}
	}
}