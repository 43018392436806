// ICONS


//* ANCHOR ICON MAPS *//

$common-icons: (
	quotes:							'\e900',
	search:							'\e903',
	header-active-indicator:		'\e901',
	header-active-indicator-small:	'\e936',
	external:						'\e902',
	expandable-arrow:				'\e907',
	link-arrow:						'\e909',
	box-curve:						'\e908',
	email:							'\e904',
	email-fill:						'\e92c',
	retry:							'\e910',
	download:						'\e912',
	update:							'\e913',
	minus:							'\e911', // TODO remove if expandable info is not implemented
	tag:							'\e914',
	filters: 						'\e91b',
	close: 							'\e91c',
	check-mark: 					'\e91d',
	share: 							'\e916',
	cookie: 						'\e937',
	expand: 						'\e938',
	menu: 							'\e93a',
);


// HEADER
$header-icons: (
	search:			map-get($common-icons, search),
	contact:		'\e93b',
	language:		'\e905'
);


// SOCIAL
$social-icons: (
	facebook:					'\e90a',
	instagram:					'\e90b',
	linkedIn:					'\e90c',
	rss:						'\e90d',
	X:							'\e90e',
	youTube:					'\e90f',
	bluesky:					'\e93c'
);


// TOP META
$top-meta-icons: (
	event-date: 				'\e915',	
	event-location: 			'\e917',	
	event-registration:	 		'\e92d',	
	event-venue:	 			'\e92e',
	tender-awarded: 			'\e92f',	
	tender-number: 				'\e930',	
	tender-status: 				'\e931',	
	tender-type:	 			'\e932',	
);


// CONTENT TYPE ICONS
$content-type-icons: (
	agreement: 					('\e922', rem-calc(57)),
	country: 					('\e923', rem-calc(57)),
	document: 					('\e924', rem-calc(58)),
	event: 						('\e925', rem-calc(51)),
	faq: 						('\e926', rem-calc(67)),
	multimedia: 				('\e935', rem-calc(52)),
	news: 						('\e927', rem-calc(54)),
	operation: 					('\e928', rem-calc(57)),
	page: 						('\e929', rem-calc(52)),
	staff_member: 				('\e92a', rem-calc(70)),
	tender: 					('\e92b', rem-calc(51)),
	guide: 						('\e939', rem-calc(58)),
);


// FILE TYPE ICONS
$file-type-icons: (
	txt: 						('\e91f', rem-calc(16)),
	pdf: 						('\e919', rem-calc(16)),
	epub: 						('\e921', rem-calc(16)),
	rtf: 						('\e91e', rem-calc(16)),
	zip: 						('\e920', rem-calc(13)),
	
	// Word
	doc: 						('\e91a', rem-calc(13)),
	docx: 						('\e91a', rem-calc(13)),
	odt: 						('\e91a', rem-calc(13)),

	// Excel
	xls: 						('\e918', rem-calc(13)),
	xlsx: 						('\e918', rem-calc(13)),
	ods: 						('\e918', rem-calc(13)),

	// Power point
	ppt: 						('\e906', rem-calc(16)),
	pptx: 						('\e906', rem-calc(16)),
	pps: 						('\e906', rem-calc(16)),
	ppsx: 						('\e906', rem-calc(16)),
	odp: 						('\e906', rem-calc(16)),
);


// MESSAGES
$message-states: (
	pending: 					('\e933', Secondary(0), rem-calc(30)),	
	error:						('\e934', Red(0), rem-calc(22)),
	success:					(map-get($common-icons, check-mark), Green(1), rem-calc(20)),
);



//* ANCHOR ICON MIXIN *//

// USE: Add icon on selector's pseudo element.

// INSTRUCTIONS: 	
//	Extend on the parent of the pseudo-element that you want to add the icon.
//	$position - the pseudo-element, can be before, after or both. 
// 	$map - the map that includes the selected icon
//	$icon - the key of the icon in the map
// 	$color - the color of the icon
//	$font-size - the font-size of the icon
// 	$line-height - the line-height of the icon
// 	$only-icon - add true if you wish to remove the text of the parent element. Default is false

//EXAMPLES: 
//	@include get-icon(before, $common-icons, search, white, 1rem, 1.3, true);
//	@include get-icon(both, $common-icons, search, primary(0), rem-calc(10));


// MIXIN
@mixin get-icon($position: before, $map: $common-icons, $icon: default, $color: black, $font-size: 1rem, $line-height: 1, $only-icon: false) {
	
	$content: map-get($map, $icon);
	
	@if $position == both {
		$position: 'before, &:after';
	}

	@if $only-icon == true {
		font-size: 0px;
	}

	&:#{$position} {
		content: $content;
		color: $color;
		font-size: $font-size;
		line-height: $line-height;

		// Required icon styles
		font-family: 'europol-icons';
		speak: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;

		// Better Font Rendering
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}