
// PLACEHOLDERS

//* ANCHOR TYPOGRAPHY PLACEHOLDERS *//

%paragraph {
	@include typography(p, true);
}

%small-text {
	@include typography('p.small-text', true);
}

%h2 {
	@include typography(h2, true);
}

%h3 {
	@include typography(h3, true);
}

%h4 {
	@include typography(h4, true);
}

%h5 {
	@include typography(h5, true);
}

%small-capitalized-heading {
	@include typography('.decorative-heading', true);
}



//* ANCHOR SHOW FOR SCREEN READERS *//

%show-for-sr {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}



//* ANCHOR LIST NEUTRALIZER *//
// removes default list styling

%list-neutralizer {
	margin-bottom: 0px;
	margin-top: 0px;
	padding-left: 0px;
	list-style-type: none;
}



//* ANCHOR LIST ITEM STYLING *//

$li-gutter: Spacing(16);

%list-item {
	position: relative;
	@extend %paragraph;

	// Margin between lis
	& + li,
	& + ul,
	& + ol {
		margin-top: var(--listItemSpacing, $li-gutter);
	}

	// Nested lists
	ul,
	ol {
		margin-top: var(--listItemSpacing, $li-gutter);
		margin-bottom: 0px;
		margin-left: 0px;
	}
}



//* ANCHOR UNORDERED LIST DECORATION *//

$ul-padding-left: 			Spacing(48);
$ul-decoration-left: 		20px;
$ul-decoration-top: 		7px;
$ul-circle-br-width: 		3px; // used in circle lists

%ul-item {
	position: relative;
	padding-left: var(--ulPaddingLeft, $ul-padding-left);

	&:before {
		content: " ";
		position: absolute;
		top: var(--ulDecorationTop, $ul-decoration-top);
		left: var(--ulDecorationLeft, $ul-decoration-left);
		display: inline-block;
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background-color: var(--decorationColor, $decoration-yellow);
	}
}



//* ANCHOR REMOVE LIST STYLING *//
// removes custom list styles

%list-styling-remover {

	& > li {
		padding-left: 0px;

		&:before {
			content: none;
		}

		& + li {
			margin-top: 0px;
		}
	}
}



//* ANCHOR EXTERNAL LINK *//

%external-link {
	@include get-icon(after, $common-icons, external, inherit, rem-calc(12));

	&:after {
		margin-left: Spacing(6);
		margin-right: 2px;
	}
}



//* ANCHOR UNDERLINED LINK *//

%underlined-link {
	border-bottom: 1px solid var(--linkColor, $link-color);

	// Hover animation
	@include bg-color-animation(transparent, var(--linkColor, $link-color), btt, var(--linkContrastColor, $link-contrast-color), hover);

	// Focus
	&.focus-visible {
		border-radius: 2px;
		box-shadow: 0px 0px 0px 2px var(--linkColor, $link-color);
		border-bottom: 0px;
	}
}



//* ANCHOR BUTTONS WITH ICONS *//
// Only one of the specific placeholders is needed since they both extend the general one.	

// LEFT
%btn-icon-left {
	@extend %btn-with-icon;
	padding-left: $btn-extra-padding;

	&:before {
		left: var(--btnPaddingLr, $btn-padding-lr);
	}
}


// RIGHT 
%btn-icon-right {
	@extend %btn-with-icon;
	padding-right: $btn-extra-padding;

	&:before {
		right: var(--btnPaddingLr, $btn-padding-lr);
	}
}


// GENERAL RULES FOR BOTH CASES
%btn-with-icon {
	position: relative;

	&:before {
		position: absolute;
		top: 0px;
		bottom: 0px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $btn-icon-size;
		height: $btn-icon-size;
	}
}



//* ANCHOR HEADER LINKS AND BUTTONS *//
// Extended on menu links and toggler buttons

%header-links-buttons {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: Spacing(16);
	color: $link-color;
	

	// HOVER
	@include hover {
		color: Primary(1);
	}


	// FOCUS
	&.focus-visible {
		box-shadow: inset 0 0 0 2px Primary(0);
		border-radius: $border-radius-8;
	}
}



//* ANCHOR INLINE LIST WITH CIRCLES *//
// Inline for large screens and default for the rest.

$inline-list-breakpoint: $mobile-1;


%inline-list-item {
	font-size: rem-calc(16);
	font-weight: $font-weight-bold;
	line-height: rem-calc(24);
	overflow-wrap: break-word;
}


%inline-list-with-circles {
	@extend %list-neutralizer;


	li {
		@extend %list-item;
		@extend %ul-item;
		--decorationColor: #{Secondary(0)};
		--listItemSpacing: #{Spacing(12)};


		// TABLET UP
		@media print, screen and (min-width: $inline-list-breakpoint) {
			display: inline;
			line-height: 1.9;
			padding-left: 0px;


			// Removes mobile styles
			& + li {
				margin-top: 0px;
			}

			&:before {
				content: none;
			}


			// Decorative circle
			&:not(:last-child):after {
				content: " ";
				display: inline-block;
				width: 10px;
				height: 10px;
				margin: 0 Spacing(16);
				border-radius: 50%;
				background-color: var(--decorationColor, Secondary(0));
			}
		}
	}


	// ITEMS
	a, span {
		@extend %inline-list-item;
		font-size: rem-calc(16);
		font-weight: $font-weight-bold;
		line-height: rem-calc(24);
		overflow-wrap: break-word;
	}

	a {
		@extend %underlined-link;
		border-bottom: 0px;
	}
}



//* ANCHOR INLINE LIST WITH CIRCLES *//

$nav-arrow-size: 				rem-calc(80);
$nav-arrow-breakpoint: 			$tablet-1;

%navigation-arrow {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $nav-arrow-size;
	height: $nav-arrow-size;
	color: var(--slickArrowColor, Secondary(0));
	border-radius: 50%;
	border: 2px solid transparent;
	@include get-icon(after, $common-icons, link-arrow, inherit, rem-calc(48), 0, true);

	&:after {
		transition: $global-transition;
		margin-left: 4px;
	}

	&.previous,
	&.slick-prev {
		left: 0px;
		transform: rotate(180deg);
	}

	&.next,
	&.slick-next {
		right: 0px;
	}


	// HOVER
	@include hover {
		color: var(--slickArrowColorHover, Secondary(1));
	}


	// FOCUS
	&.focus-visible {
		border-color: var(--linkColor);
	}
}



//* ANCHOR BLUE BACKGROUND TEASER *//

%blue-bg-teaser {
	--nodeTeaserBgColor: 		#{$teaser-bg-color-dark};
	--nodeTeaserTxColor: 		white;
	--nodeTeaserTagIconColor: 	#{Secondary(0)};
	--nodeTeaserFocusColor: 	#{Secondary(0)};
	--nodeTeaserNoImageBgColor: #{$teaser-image-bg-color-dark};
}



//* ANCHOR FORM LABEL *//
// Used in .Form and TalentLink iframe

%form-label {
	display: block;
	@extend %h5;
	margin-bottom: Spacing(8);

	@include breakpoint($main-breakpoint up) {
		margin-bottom: Spacing(16);
	}
}



//* ANCHOR FORM INPUT *//
// Used in .Form and TalentLink iframe

%form-input {
	width: 100%;
	padding: Spacing(12) $input-padding-lr;
	@extend %paragraph;
	font-weight: $font-weight-bold;
	color: Grey(1);
	border: 1px solid $input-br-color;
	border-radius: $border-radius-8;
	transition: border-color $global-transition, box-shadow $global-transition;

	@include breakpoint($main-breakpoint up) {
		padding-top: Spacing(16);
		padding-bottom: Spacing(16);
	}
}



//* ANCHOR FORTHCOMING LABEL *//
// Used in Publication teasers and nodes.
// EPOLD8-386

%forthcoming-label {
	padding: Spacing(6) Spacing(12);
	background-color: Yellow(0);
	color: $text-color;
	@include typography('.decorative-heading');
	border-radius: $border-radius-34;
}