
// MESSAGES

div.message {

	
	//* ANCHOR VARIABLES *//

	$message-border-width: 6px;



	//* ANCHOR STYLING *//

	position: relative;
	padding: Spacing(24);
	background-color: $bg-dark-blue;
	border-radius: $border-radius-16;

	@include breakpoint($main-breakpoint up) {
		padding: Spacing(32);
	}



	//* ANCHOR CONTENT *//

	p {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0px;
		--txColor: white;
		@include get-icon(before, $common-icons, icon, inherit, inherit, 1);

		// ICON
		&:before {
			flex-grow: 0;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: Spacing(56);
			height: Spacing(56);
			margin-right: Spacing(20);
			border-radius: 50%;
		}
	}



	//* ANCHOR STATES *//

	@each $state, $rules in $message-states {
		&.#{$state} {

			$icon: nth($rules, 1);
			$bg-color: nth($rules, 2);
			$font-size: nth($rules, 3);

			p:before {
				content: $icon;
				background-color: $bg-color;
				font-size: $font-size;
			}
		}
	}

	// pending icon animation
	&.pending {
		@keyframes rotate-center {
			0% {
				transform: rotate(0);
			}
			100% {
				transform: rotate(360deg);
			}
		}
		
		p:before {
			color: Blue(2);
			transform-origin: center center;
			animation: rotate-center 1.2s linear infinite;
		}
	}
}