
// FILTERS

.filters {


	//* ANCHOR VARIABLES *//

	// SEARCH AND TOGGLER PADDING
	--filterItemsPaddingTb: 			#{Spacing(12)};
	--filterItemsPaddingLr: 			#{Spacing(24)};

	@include breakpoint($main-breakpoint up) {
		--filterItemsPaddingTb: 		#{Spacing(16)};
		--filterItemsPaddingLr: 		#{Spacing(24)};
	}


	// SPACING BETWEEN ITEMS
	--filterItemsSpacing: 				#{Spacing(16) * 0.5};

	@include breakpoint($main-breakpoint up) {
		--filterItemsSpacing: 			#{Spacing(24) * 0.5};
	}



	//* ANCHOR LAYOUT *//

	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: var(--globalVerticalSpacing);


	// WRAPPERS
	.fields,
	& > .actions {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin: calc(-1 * var(--filterItemsSpacing));
	}

	@include breakpoint($main-breakpoint up) {
		.fields {
			margin-right: 0px;
		}

		& > .actions {
			margin-left: 0px;
		}
	}


	// CHILDREN
	.search,
	.toggleFilters,
	.sort,
	& > .actions .button {
		margin: var(--filterItemsSpacing);
	}

	@include breakpoint($main-breakpoint down) {
		flex-direction: column;

		.fields {
			margin-bottom: 0px;
		}

		& > .actions {
			margin-top: 0px;
		}
	}



	//* ANCHOR SEARCH, SORT AND TOGGLER COMMON STYLES *//

	.search input,
	.toggleFilters,
	.sort select {
		padding: var(--filterItemsPaddingTb) var(--filterItemsPaddingLr);
		@extend %paragraph;
		font-weight: $font-weight-bold;
		
	}

	@include breakpoint($mobile-0 down) {
		.search,
		.search input,
		.toggleFilters,
		.sort,
		.sort select {
			width: 100%;
		}
	}


	// INPUT AND SELECT
	.search,
	.sort {
		position: relative;
		display: flex;
		align-items: center;

		// Icon
		&:after {
			position: absolute;
			right: var(--filterItemsPaddingLr);
			pointer-events: none;
		}

		&:focus-within:after {
			color: $btn-bg-br-color-hf;
		}

		// Inputs
		input,
		select {
			border: 0px;
			background-color: white;
			border-radius: $border-radius-34;
			transition: $global-transition;
			padding-right: Spacing(56); //icon placeholder

			@include hover {
				box-shadow: 0px 0px 0px $btn-extra-br-width white;
			}

			&.focus-visible {
				box-shadow: 0px 0px 0px $btn-extra-br-width $btn-bg-br-color-hf, 0px 8px 4px rgba(0, 0, 0, 0.4);
			}
		}
	}



	//* ANCHOR SEARCH *//

	.search {

		// ICON
		@include get-icon(after, $common-icons, search, Primary(0), rem-calc(28), 1);

		&:after {
			bottom: var(--filterItemsPaddingTb);
			margin-bottom: -3px;
		}


		// INPUT
		input {
			@include input-placeholder(Grey(1), inherit, inherit, normal);

			@include breakpoint($mobile-0 up) {
				width: rem-calc(322);
			}
		}
	}



	//* ANCHOR SORTING *//

	.sort {

		// ICON
		@include get-icon(after, $common-icons, expandable-arrow, Primary(0), rem-calc(10), 1);

		&:after {
			margin-top: 5px;
		}
		

		// SELECT
		select {
			appearance: none;
			cursor: pointer;
		}
	}



	//* ANCHOR FILTERS TOGGLER *//

	.toggleFilters.button {
		--btnBgColorHover: 	white;
		--btnBgColorFocus:  white;
		--btnBrColorFocus:	#{$btn-bg-br-color-hf};
		--btnTxColorFocus:	#{$btn-bg-br-color-hf};
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		@extend %btn-icon-right;


		// ICON
		@include get-icon(before, $common-icons, filters, inherit, rem-calc(21), 1);


		// COUNTER
		span.counter {
			position: absolute;
			top: -15px;
			right: -15px;
			width: rem-calc(38);
			height: rem-calc(38);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(15);
			font-weight: $font-weight-bold;
			line-height: 1;
			color: Yellow(4);
			text-align: center;
			pointer-events: none;
			background-color: Yellow(0);
			border: 4px solid white;
			border-radius: 50%;
		}
	}



	//* ANCHOR ACTIONS *//
	// Buttons should be default for devices and .large for desktop.

	& > .actions .button {
		@include breakpoint($main-breakpoint up) {
			--btnPaddingTb:			#{Spacing(16)};
			--btnPaddingLr:			#{Spacing(32)};

			--btnFontFamily:		#{$font-family-condensed};
			--btnFontSize:			#{rem-calc(20)};
			--btnLineHeight:		#{rem-calc(24)};
		}
	}
}



// FILTERS MODAL
// For general modal styles see _modals.scss 

.filtersModal {
	

	//* ANCHOR VARIABLES *//

	// SCSS
	$checkbox-radio-size: Spacing(32);


	// SPACING BETWEEN GROUPS
	--fieldsetSpacing: 					#{Spacing(40)};

	@include breakpoint($main-breakpoint up) {
		--fieldsetSpacing: 				#{Spacing(64)};
	}

	
	// LAYOUT
	ul.layout {

		// Items per row
		--itemsPerRow:			1;

		@include breakpoint($mobile-0 up) {
			--itemsPerRow:		2;
		}

		@include breakpoint($tablet-0 up) {
			--itemsPerRow:		3;
		}

		@include breakpoint($laptop-0 up) {
			--itemsPerRow:		4;
		}

		// Gap
		--layoutRowGap:						#{Spacing(8)};

		@include breakpoint($main-breakpoint up) {
			--layoutRowGap:					#{Spacing(16)};
		}
	}



	//* ANCHOR HEADINGS *//

	h1 {
		--txColor: #{Grey(0)};;
	}



	//* ANCHOR FIELDSET *//

	fieldset {
		margin: 0px;
		padding: 0px;
		margin-bottom: var(--fieldsetSpacing);
		border: 0px;

		legend {
			@extend %h3;
			color: Yellow(0);
			margin-bottom: Spacing(40);
		}
	}



	//* ANCHOR LABELS *//

	label {
		@extend %small-text;
		color: var(--linkColor);
		cursor: pointer;
	}



	//* ANCHOR CHECKBOXES AND RADIOS *//
	
	.field-type-checkbox,
	.field-type-radio {
		position: relative;
		display: flex;
		align-items: center;


		// HIDDEN INPUTS
		input {
			position: absolute;
			left: 0px;
			opacity: 0;
			z-index: -10;
		}


		// LABEL
		label {
			position: relative							;
			display: flex;
			align-items: center;
			transition: $global-transition;

			&:before {
				content: " ";
				flex-shrink: 0;
				width: $checkbox-radio-size;
				height: $checkbox-radio-size;
				border: 1px solid Grey(1);
				margin-right: Spacing(16);
				border-radius: var(--borderRadius, 50%);
				transition: inherit;
			}

			&:after {
				position: absolute;
				opacity: 0;
			}
		}
	}


	// CHECKBOX
	.field-type-checkbox label {
		--borderRadius: #{$border-radius-8};
		@include get-icon(after, $common-icons, check-mark, Blue(0), rem-calc(12), 1);

		&:after {
			left: 8px;
		}
	}


	// RADIO
	.field-type-radio label {
		
		&:after {
			content: " ";
			left: 10px;
			width: Spacing(12);
			height: Spacing(12);
			background-color: Blue(0);
			border-radius: 50%;
		}
	}


	// FOCUS
	input.focus-visible + label:before {
		background-color: white;
	}


	// CHECKED
	input:checked + label {

		&:before {
			border-color: Yellow(0);
			background-color: Yellow(0);
		}

		&:after {
			opacity: 1;
		}
	}



	//* ANCHOR ACTIONS *//

	.actions {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: var(--modalPaddingTb) var(--modalPaddingLr);
		background: linear-gradient(transparent 0%, Blue(2) 100%);

		.button {
			margin-bottom: 0px;

			&:first-child {
				margin-right: var(--filterItemsSpacing);
			}
		}
	}
}



// DROPDOWN FILTERS
// Used in home page

label.dropdown-filter {
	position: relative;
	display: inline-flex;
	align-items: stretch;
	border-radius: $border-radius-48;
	overflow: hidden;

	@include breakpoint($mobile-1 down) {
		display: inline-block;
		border-radius: $border-radius-16;
	}


	// Variables
	$padding-lr: Spacing(24);


	& > * {
		display: block;
		--txColor: white;
		font-family: $font-family-condensed;
		font-size: rem-calc(20);
		line-height: rem-calc(24);
		padding: Spacing(12) $padding-lr;

		@include breakpoint($mobile-1 up) {
			display: flex;
			align-items: center;
			padding-top: Spacing(20);
			padding-bottom: Spacing(20);
		}
	}


	// SPAN
	span {
		display: flex;
		align-items: center;
		text-align: center;
		background-color: Primary(0);

		@include breakpoint($mobile-1 up) {
			padding-left: Spacing(40);
			padding-right: Spacing(40);
		}
	}


	// SELECT
	select {
		max-width: 300px;
		padding-right: Spacing(56);
		font-weight: $font-weight-bold;
		border: 0px;
		background-color: Orange(0);
		appearance: none;
		background-image: url(~sass/images/select-arrow-white.png);
		background-position: calc(100% - #{$padding-lr} + 7px) center;
		background-repeat: no-repeat;
		cursor: pointer;
		border-radius: 0 $border-radius-48 $border-radius-48 0;
		transition: $global-transition;

		@include breakpoint($mobile-1 down) {
			width: 100%;
			border-radius: 0 0 $border-radius-16 $border-radius-16;
		}

		option {
			font-size: rem-calc(15);
		}

		@include hover {
			background-color: Orange(1);
		}

		&.focus-visible {
			box-shadow: inset 0px 0px 0px 4px Primary(0);
		}
	}
}