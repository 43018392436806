
// MODALS
// General modal styles

.filtersModal,
.timelineMenuModal {


	//* ANCHOR VARIABLES *//
	
	--modalPaddingTb: 					#{Spacing(16)};
	--modalPaddingLr: 					#{Spacing(24)};
	--modalCloseIconSize: 				#{Spacing(40)};
	
	@include breakpoint($main-breakpoint up) {
		--modalPaddingTb: 				#{Spacing(40)};
		--modalPaddingLr: 				#{Spacing(48)};
	}

	@include breakpoint($typography-breakpoint up) {
		--modalCloseIconSize: 			#{Spacing(56)};
	}


	
	//* ANCHOR LAYOUT *//

	position: fixed;
	top: -100%;
	left: 0px;
	right: 0px;
	margin: auto;
	width: 100%;
	max-width: $app-width;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding-top: var(--modalPaddingTb);
	padding-left: var(--modalPaddingLr);
	text-align: left;
	background-color: Blue(2);
	@extend %theme-dark-blue;
	z-index: $z-index-filters;
	transition: $global-transition;
	visibility: hidden;


	// SCROLLBAR
	& > h1 {
		flex-shrink: 0;
	}

	& > div {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		padding-right: var(--modalPaddingLr);
		padding-bottom: var(--modalPaddingTb);

		@media (hover: hover) and (pointer: fine) {
			@include scrollbars($scrollbar-width, $scrollbar-thumb-color, $scrollbar-track-color);
		}
	}


	// OPEN ANIMATION
	&.open {
		top: 0px;
		visibility: visible;
	}



	//* ANCHOR HEADINGS *//

	h1 {
		text-align: left;
		padding-right: calc(var(--modalPaddingLr) + var(--modalCloseIconSize) + #{Spacing(24)});
	}



	//* ANCHOR CLOSE BUTTON *//

	button.close {
		position: absolute;
		top: var(--modalPaddingTb);
		right: var(--modalPaddingLr);
		width: var(--modalCloseIconSize);
		height: var(--modalCloseIconSize);
		display: flex;
		align-items: center;
		justify-content: center;
		@include get-icon(before, $common-icons, close, var(--linkColor), rem-calc(20), 1, true);
		transition: $global-transition;

		&:before {
			transition: inherit;

			@include breakpoint($typography-breakpoint up) {
				font-size: rem-calc(28)
			}
		}

		@include hover {
			&:before {
				color: Yellow(0);
				transform: rotate(180deg);
			}
		}

		&.focus-visible {
			box-shadow: 0px 0px 0px 2px var(--linkColor);
			border-radius: 4px;
		}
	}
}