
// LANGUAGES
// Translation links in Page CT.

.Languages {
	display: flex;
	flex-wrap: wrap;
	row-gap: Spacing(8);


	//* ANCHOR HEADING *//

	h2 {
		flex-shrink: 0;
		@extend %h4;
		margin-bottom: 0px;
		margin-right: Spacing(20);
	}



	//* ANCHOR LAYOUT *//

	ul {
		@extend %list-neutralizer;
		display: flex;
		flex-wrap: wrap;
		margin: -#{Spacing(4)};

		& > li {
			padding: Spacing(4);
		}
	}



	//* ANCHOR BUTTONS *//

	.button {
		margin-bottom: 0px;
		text-transform: uppercase;

		&.active {
			@extend %button-yellow;
			pointer-events: none;
		}
	}
}