
// BOXES

.box {

	//* ANCHOR VARIABLES *//

	--boxPaddingTb:			#{Spacing(32)};
	--boxPaddingLr:			#{Spacing(24)};

	@include breakpoint($main-breakpoint up) {
		--boxPaddingTb:			#{Spacing(40)};
		--boxPaddingLr:			#{Spacing(40)};
	}



	//* ANCHOR DEFAULT STYLING *//

	margin-bottom: var(--globalVerticalSpacing);
	padding: var(--boxPaddingTb) var(--boxPaddingLr);
	border-radius: $border-radius-8;
	background-color: var(--themeColor, transparent);
	overflow: hidden; // So that floated elements are presented properly and images are cropped
	line-height: 0; // removes white after image


	// MARGIN BOTTOM OF LAST ITEM
	& > *:last-child {
		margin-bottom: 0px;
	}

	// Buttons and highlighted links
	& > div:last-child {
		&:not(.inline-links) > a.button:last-child,
		&:not(.inline-links) > a.highlighted:last-child {
			margin-bottom: 0px;
		}
	}



	//* GREY *//

	&.grey {
		@extend %theme-grey;
	}



	//* ANCHOR BLUE *//

	&.blue {
		@extend %theme-blue;
	}



	//* ANCHOR DARK BLUE *//

	&.dark-blue {
		@extend %theme-dark-blue;
	}



	//* ANCHOR YELLOW *//

	&.yellow {
		@extend %theme-yellow;
	}



	//* ANCHOR WHITE *//

	&.white {
		@extend %theme-white;
	}



	//* ANCHOR WITH CURVE *//

	&.curve {
		@include decorative-curve(top, var(--boxPaddingTb));
	}



	//* ANCHOR WITH BACKGROUND IMAGE *//

	&.image-bg {
		position: relative;

		& > img {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: -1;
			border-radius: 0;
		}


		// WITHOUT COLOR
		&:not(.grey):not(.yellow):not(.blue):not(.dark-blue) {
			@extend %theme-dark-blue;
			background-color: transparent;

			--decorativeHeadingTxColor: white;
			--decorativeHeadingLineColor: white;
		}


		// GREY
		&.grey {
			background-color: transparentize(Grey(-3), 0.2);
		}


		// YELLOW
		&.yellow {
			background-color: transparentize(Yellow(0), 0.21);

			--decorativeHeadingTxColor: #{$text-color};
			--decorativeHeadingLineColor: #{$text-color};
		}


		// BLUE
		&.blue {
			background-color: transparentize(Primary(0), 0.2);
		}

		
		// DARK BLUE
		&.dark-blue {
			background-color: transparentize(Blue(1), 0.56);

			--decorativeHeadingTxColor: white;
			--decorativeHeadingLineColor: white;
		}
	}
}



//* ANCHOR OVERLAPPING BOXES *//

.overlapping-box {

	// VARIABLES
	$overlapping-box-breakpoint: $tablet-0;
	--verticalSpacing: #{Spacing(40)};

	@include breakpoint($overlapping-box-breakpoint up) {
		--columns: 4;
	}
	
	@include breakpoint($main-breakpoint up) {
		--verticalSpacing: #{Spacing(80)};
	}


	// LAYOUT
	position: relative;
	z-index: 0;
	margin-bottom: var(--globalVerticalSpacing);
	
	@include breakpoint($overlapping-box-breakpoint up) {
		display: grid;
		grid-template-columns: repeat(var(--columns, 1), 1fr);
		gap: Spacing(40);
		align-items: center;

		& > * {
			grid-row: 1;
		}
	}


	// IMAGE
	.Image {
		display: block;
		z-index: -1;

		img {
			width: 100%;
			margin-bottom: 0px;

			@include breakpoint($overlapping-box-breakpoint down) {
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;
			}
		}

		@include breakpoint($overlapping-box-breakpoint up) {
			grid-column: 2 / span 3;
		}
	}


	// BOX
	.box {
		margin-bottom: 0px;

		@include breakpoint($overlapping-box-breakpoint down) {
			border-bottom-left-radius: 0px; 
			border-bottom-right-radius: 0px;
		}

		@include breakpoint($overlapping-box-breakpoint up) {
			grid-column: 1 / span 2;
		}
	}


	// ALIGNMENT CLASSES
	@include breakpoint($overlapping-box-breakpoint up) {
		&.bottom {
			align-items: flex-end;

			.Image {
				margin-bottom: var(--verticalSpacing);
			}
		}

		&.top {
			align-items: flex-start;

			.Image {
				margin-top: var(--verticalSpacing);
			}
		}

		&.right {
			.Image {
				grid-column: 1 / span 3;
			}

			.box {
				grid-column: 3 / span 2;
			}
		}
	}
}