
// LIST OF AGREEMENTS

.agreementsPageLists {

	//* ANCHOR VARIABLES *//

	$outer-column-gap: 			Spacing(32);
	
	// CSS
	--innerColumnGap: 			#{Spacing(32)};
	--rowGap: 					#{Spacing(32)};
	--liSpacing: 				#{Spacing(12)};

	@include breakpoint($typography-breakpoint up) {
		--liSpacing: 			#{Spacing(16)};
	}

	@include breakpoint($main-breakpoint up) {
		--innerColumnGap: 		#{Spacing(56)};
		--rowGap: 				#{Spacing(40)};
	}

	// COLORS
	$member-states-color: 	Blue(0);
	$group-1-color: 		Orange(1);
	$group-2-color: 		Yellow(3);
	$group-3-color: 		Green(1);
	$group-4-color: 		#8a2be2; // https://jira.eworx.gr/browse/EPOLD8-420



	//* ANCHOR TYPOGRAPHY *//

	h2 {
		@extend %h3;
		text-transform: uppercase;
	}

	h3, li {
		@extend %paragraph;
		font-weight: $font-weight-bold;
	}

	h3 {
		margin-bottom: Spacing(20);
		text-transform: uppercase;
	}



	//* ANCHOR LINKS *//

	a {
		@extend %underlined-link;
		border-bottom: 0px;
	}

	// COLORS
	.countryAgreements > div > div {
		&:nth-child(1) a {
			--linkColor: 		#{$group-1-color};
		}

		&:nth-child(2) a {
			--linkColor: 		#{$group-2-color};
		}

		&:nth-child(3) a {
			--linkColor: 		#{$group-3-color};
		}

		&:nth-child(4) a {
			--linkColor: 		#{$group-4-color};
		}
	}



	//* ANCHOR LISTS *//

	ul {
		@extend %list-neutralizer;

		li + li {
			margin-top: var(--liSpacing);
		}
	}



	//* ANCHOR MAIN LAYOUT *//

	@include breakpoint($main-breakpoint down) {
		.organisationAgreements {
			margin-top: Spacing(48);
		}
	}

	@include breakpoint($main-breakpoint up) {
		display: grid;
		grid-template-columns: 70% 30%;

		.countryAgreements {
			padding-right: $outer-column-gap;
		}

		.organisationAgreements {
			padding-left: $outer-column-gap;
			border-left: 1px solid Grey(0);
		}
	}



	//* ANCHOR FIRST COLUMN *//
	
	.countryAgreements > div {

		// VARIABLES
		--itemsPerRow: 			1;
		--innerItemsPerRow: 	2;

		@include breakpoint($mobile-0 up) {
			--innerItemsPerRow: 	3;
		}

		@include breakpoint($mobile-1 up) {
			--itemsPerRow: 			4;
			--innerItemsPerRow: 	2;
		}


		// STYLING
		display: grid;
		grid-template-columns: repeat(var(--itemsPerRow), 1fr);
		column-gap: var(--innerColumnGap);
		row-gap: var(--rowGap);
		margin-top: var(--rowGap);

		& > div ul {
			display: grid;
			grid-template-columns: repeat(var(--innerItemsPerRow), 1fr);
			column-gap: var(--innerColumnGap);
			row-gap: var(--liSpacing);

			li + li {
				margin-top: 0px;
			}
		}


		// 50% WIDTH COLUMN WITH 2 COLUMNS INSIDE
		@include breakpoint($mobile-1 up) {
			& > div:nth-child(1),
			& > div:nth-child(3):nth-last-child(2) {
				grid-column: 1 / span 2;
			}

			& > div:nth-child(2),
			& > div:nth-child(4) {
				grid-column: 3 / span 2;
			}
		}


		// THIRD GROUP
		& > div:nth-child(3):last-child {
			@include breakpoint($mobile-1 up) {
				grid-column: 1 / span var(--itemsPerRow);

				ul {
					--innerItemsPerRow: var(--itemsPerRow);
				}
			}
		}
	}



	//* ANCHOR SECOND COLUMN *//

	.organisationAgreements > div + div {
		margin-top: var(--rowGap);
	}



	//* ANCHOR MAP *//

	svg {
		fill: #C2C2C2;
		stroke-width: 0.5;
		stroke: white;


		// GROUP COLORS
		path {
			&.euMemberState {
				fill: $member-states-color;
			}

			&.countriesGroup1 {
				fill: $group-1-color;
			}

			&.countriesGroup2 {
				fill: $group-2-color;
			}

			&.countriesGroup3 {
				fill: $group-3-color;
			}

			&.countriesGroup5 { // for some reason the map doesn't provide a group 4 class and automatically goes to 5.  
				fill: $group-4-color;
			}
		}
	}
}