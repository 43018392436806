
// MENU

.Menu {

	//* ANCHOR GENERAL STYLING *//

	ul {
		@extend %list-neutralizer;
	}


	// First level links
	& > ul > li > a {
		@extend %header-links-buttons;
	}
}



//* ANCHOR MOBILE MENU *//

@include breakpoint($fixed-app-header-breakpoint down) {
	
	.Menu {
		height: 100%;
		overflow: hidden;
		position: relative;


		// INNER LEVELS
		& > ul {
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
		}

		& > ul > li .menuWrapper {
			position: absolute;
			top: 0px;
			left: 100%;
			width: 100%;
			height: 100%;
			background-color: $menu-bg-color;
			transition: left $global-transition;
			z-index: 1;

			& > ul {
				height: 100%;
				overflow-y: auto;
				overflow-x: hidden;
			}

			&.open {
				left: 0;
			}
		}


		$border-color: Blue(0);  

		// LINKS
		ul li {
			border-bottom: 1px solid $border-color;

			a, button {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: Spacing(20) Spacing(24);
				font-size: 16px;
				line-height: 1.5;
				font-weight: $font-weight-regular;
				color: Secondary(0);
			}


			// WITH CHILDREN
			// Main links are hidden and buttons open the next level.
			a.hasChildren {
				display: none;
				
				& + button {
					color: white;
					@include get-icon(after, $common-icons, link-arrow, Secondary(0), 1rem, 1);

					&:after {
						flex-shrink: 0;
						margin-left: Spacing(24);
					}
				}
			}


			// Active
			&:not(.back) {
				& > a,
				& > button {
					position: relative;
				
					&:before {
						content: " ";
						position: absolute;
						top: 0px;
						left: 0px;
						width: Spacing(8);
						height: 100%;
						background-color: Yellow(0);
						opacity: 0;
					}
				}

				a.active,
				a.active + button {
					background-color: transparentize(black, 0.7);

					&:before {
						opacity: 1;
					}
				}
			}
		}

		// Back link
		li.back {

			button {
				position: relative;
				justify-content: flex-start;
				font-weight: $font-weight-bold;
				color: white;
				@include get-icon(before, $common-icons, link-arrow, Secondary(0), 1rem, 1);

				// Arrow
				&:before {
					margin-right: Spacing(12);
					transform: rotate(180deg);
				}

				// Extra border is added with after so that the menu doesn't increase in height from first to second level thus the lines between each level are aligned during the animation.
				&:after {
					content: " ";
					position: absolute;
					bottom: -1px;
					left: 0px;
					width: 100%;
					height: 4px;
					background-color: $border-color;
				}
			}
		}
	}
}



//* ANCHOR MEGA MENU *//

@include breakpoint($fixed-app-header-breakpoint up) {

	$li-spacing: Spacing(16);
	$active-menu-color: Orange(0);

	.Menu {

		// FIRST LEVEL
		& > ul {
			display: flex;
			align-items: stretch;
			height: 100%;
		}

		& > ul > li {
			min-width: 100px;

			// Links
			& > a {
				text-align: center;
				font-family: $font-family-condensed;
				font-weight: $font-weight-regular;
				font-size: rem-calc(17);
				line-height: rem-calc(24);
				text-transform: uppercase;

				&.active {
					color: $active-menu-color;
				}
			}
		}


		// HIDDEN BUTTONS USED IN MOBILE MENU
		a.hasChildren + button {
			display: none;
		}
	}


	// MEGA MENU WRAPPER
	.Menu > ul > li > .menuWrapper {
		position: absolute;
		top: 100%;
		left: 0px;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: $app-width;
		max-height: calc(100vh - var(--headerHeight));
		padding: Spacing(72) Spacing(56);
		background-color: $menu-bg-color;
		overflow-x: hidden;
		overflow-y: auto;
		box-shadow: 0px 5px 10px 0px transparentize(black, 0.6);
		@media (hover: hover) and (pointer: fine) {
			@include scrollbars($scrollbar-width, Yellow(0), Grey(-1));
		}


		// HIDDEN LINKS
		li.parent,
		li.back {
			display: none;
		}


		// LAYOUT
		& > ul {
			flex-grow: 1;
			column-count: 3;
			column-gap: Spacing(40);
		}
	

		// GENERAL STYLING

		// Hove, Focus, Active
		ul li a {
			display: inline-block;

			@include hover {
				color: Yellow(0);
			}

			&.focus-visible {
				box-shadow: 0px 0px 0px 1px white;
				border-radius: 2px;
			}

			&.active {
				color: $active-menu-color;
			}
		}
		

		// INNER LEVELS
		& > ul > li {

			// Second
			&:not(:last-child) {
				margin-bottom: Spacing(40);
			}

			& > a {
				@include typography(h4, true);
				color: Secondary(0);

				&:not(:last-child) {
					margin-bottom: $li-spacing;
				}
			}


			// Third
			& > div > ul > li {
				break-inside: avoid-column;

				&:not(:last-child) {
					margin-bottom: $li-spacing;
				}

				& > a {
					@include typography(p, true);
					color: white;

					&:not(:last-child) {
						margin-bottom: $li-spacing;
					}
				}


				// Fourth
				& > div > ul {
					padding: Spacing(8) 0px;
					margin-left: Spacing(16);
					padding-left: Spacing(16);
					border-left: 1px solid Secondary(0);

					& > li {
						&:not(:last-child) {
							margin-bottom: $li-spacing;
						}

						& > a {
							position: relative;
							margin-bottom: 0px;
							@include typography('p.small-text', true);
							color: transparentize(white, 0.4);
							z-index: 1;
						}
					}
				}
			}
		}
	}



	// OPEN ANIMATION
	// Open mega menu
	.Menu > ul > li {
		$menu-delay: 200ms;


		// CLOSED
		& > .menuWrapper {
			pointer-events: none;
			z-index: -10;
			visibility: hidden;
			opacity: 0;
			transition: 0ms $menu-delay cubic-bezier(0,0,.16,.96);

			& > ul {
				transform: translateY(-12px);
				opacity: 0;
				transition: 
					opacity 350ms $menu-delay cubic-bezier(0,0,.16,.96),
					transform 400ms $menu-delay cubic-bezier(0,0,.16,.96)
				;
			}
		}

		& > a.hasChildren {
			@include get-icon(after, $common-icons, header-active-indicator, $menu-bg-color, rem-calc(16), 1);

			&:after {
				position: absolute;
				bottom: 0px;
				opacity: 0;
				transition: opacity $global-transition;
				transition-delay: $menu-delay;
			}
		}


		// OPEN
		&.open {
			& > .menuWrapper {
				pointer-events: all;
				visibility: visible;
				z-index: $z-index-menu;
				opacity: 1;

				& > ul {
					opacity: 1;
					transform: translateY(0px);
				}
			}

			& > a.hasChildren:after {
				opacity: 1;
			}
		}
	}
}