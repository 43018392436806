
// NODE HEADER

.NodeHeader {


	//* ANCHOR VARIABLES *//

	$node-header-spacing: 			Spacing(24);
	$publish-dated-size: 			rem-calc(86);

	--headerTopMarginBottom: 		#{Spacing(24)};

	@include breakpoint($main-breakpoint up) {
		--headerTopMarginBottom: 	#{Spacing(48)};
	}

	
	// Layout related variables for increased specificity
	&.layout {
		--themeColor: 					#{Blue(2)};
		--layoutPaddingTop: 			#{$node-header-spacing};
		--layoutPaddingBottom: 			#{Spacing(40)};

		@include breakpoint($main-breakpoint up) {
			--layoutPaddingBottom: 		#{Spacing(80)};
		}

		// Curve color on elements with layout class after it
		& + .layout.curve:after {
			--curveColor: 				var(--themeColor, Blue(2));
		}
	}



	//* ANCHOR LAYOUT *//

	position: relative;
	@include decorative-curve(bottom);
	margin-bottom: 0px;

	& > div {
		display: flex;
		flex-direction: column;
		text-align: center;
	}



	//* ANCHOR HEADER TOP *//
	// Contains Breadcrumb and Social Share

	.header-top {
		order: -1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: var(--headerTopMarginBottom);

		@include breakpoint($main-breakpoint up) {
			justify-content: center;
		}

		// Stays on the left side when there is no breadcrumb
		@include breakpoint($main-breakpoint down) {
			.SocialShare {
				margin-left: auto;
			}
		}
	}



	//* ANCHOR BREADCRUMB *//

	.breadcrumb {
		@extend %list-neutralizer;
		display: flex;
		flex-wrap: wrap;
		margin-right: Spacing(32);

		li {
			@extend %paragraph;
			text-align: left;

			// Divider
			&:not(:last-child):after {
				content: "/";
				margin-right: Spacing(8);
				margin-left: Spacing(8);
				color: var(--breadcrumbDividerColor, Yellow(0));
			}
		}

		a {
			@extend %underlined-link;
			border-bottom: 0px;
		}

		
		// MOBILE VERSION
		@include breakpoint($main-breakpoint down) {
			li:not(:last-child) {
				display: none;
			}

			li:last-child a {
				display: flex;
				align-items: center;
				@include get-icon(before, $common-icons, link-arrow, inherit, 1rem);

				&:before {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					width: rem-calc(18);
					height: rem-calc(18);
					margin-right: Spacing(8);
					padding-top: 1px; // better alignment
					transform: rotate(180deg);
				}
			}
		}
	}



	//* ANCHOR TITLE TOP *//
	// Content type in most nodes.
	// Post and contract types in vacancies and internships.

	.title-top {
		order: -1;

		.decorative-heading {
			margin-bottom: $node-header-spacing;

			&:after {
				margin-left: auto;
				margin-right: auto;
			}
		}


		// LIST
		ul {
			@extend %list-neutralizer;

			// Text color
			&.restricted {
				--decorativeHeadingTxColor: #{Red(0)};
				--decorativeHeadingLineColor: #{Red(0)};
			}

			* {
				color: inherit;
			}

			li {
				display: inline;
			}

			label {
				margin-right: 3px;
			}

			// Divider
			li:not(:last-child):after {
				content: "/";
				margin: 0px Spacing(4);
			}
		}
	}



	//* ANCHOR TITLE *//

	h1 {
		margin-bottom: 0px;
		max-width: $column-width;
		margin: 0 auto;
	}



	//* ANCHOR SUBTITLE *//

	.subtitle {
		max-width: 726px;
		margin: $node-header-spacing auto 0 auto;

		* { 
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			font-weight: inherit;
			font-family: inherit;
			margin-bottom: 0px;
		}


		// LIST
		ul {
			@extend %list-neutralizer;

			li {
				display: inline;
			}

			li:not(:last-child):after {
				content: ",";
				margin-right: Spacing(8);
			}

			label {
				margin-right: Spacing(4);
			}
		}
	}



	//* ANCHOR DATES *//

	.dates {
		@extend %list-neutralizer;
		margin-top: $node-header-spacing;
		// Useful in print css
		font-size: rem-calc(14);
		line-height: rem-calc(20);
		color: var(--nodeHeaderMetaTxColor, Yellow(0));


		// DEFAULT DATE STYLING
		li:not(.publish-date) {
			display: inline;
			// Useful in print css
			font-size: inherit;
			font-weight: inherit;
			color: inherit;

			* {
				font-size: inherit;
				line-height: inherit;
				color: inherit;
			}

			label {
				margin-right: Spacing(4);
			}

			&:not(:last-child):after {
				content: ",";
				margin-right: Spacing(8);
			}

			div {
				display: inline;
			}
		}


		// PUBLISH DATE
		.publish-date {
			--txColor: #{$text-color};

			position: absolute;
			bottom: -$publish-dated-size * 0.5;
			right: 0px;
			width: $publish-dated-size;
			height: $publish-dated-size;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $font-family-condensed;
			font-size: rem-calc(16);
			line-height: rem-calc(18);
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			border-radius: 50%;
			background-color: Yellow(0);
			z-index: 1;

			span {
				display: block;
				font-weight: $font-weight-regular;
			}

			// TODO remove it once the date has spans.
			time {
				padding: rem-calc(20px); 
			}
		}
	}



	//* ANCHOR FORTHCOMING LABEL *//

	.forthcoming-label {
		@extend %forthcoming-label;
		position: absolute;
		bottom: 0px;
		right: $publish-dated-size + Spacing(20);
		transform: translateY(50%);
		margin-bottom: 0px;
		z-index: 1;
	}




	//* ANCHOR HEADER BOTTOM *//

	.header-bottom {
		margin-top: Spacing(32);


		// LIST
		ul {
			@extend %list-neutralizer;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: -#{Spacing(8)};

			& > li {
				padding: Spacing(8);
			}
		}


		// GENERAL LINK STYLING
		.button {
			border: 1px solid var(--btnBgColor);
			margin-bottom: 0px;

			&.focus-visible {
				border-color: var(--btnBrColorFocus);
				background-color: white;
			}
		}


		// HOLLOW BUTTONS
		.anchor {
			background-color: var(--themeColor); // transparent creates a blue border between border and box-shadow on hover and focus

			// fixes bg color flickering on hover
			&:after {
				transition: none;
			}

			&:not(.focus-visible) {
				color: var(--linkColor);
			}

			@include hover {
				border-color: var(--btnBgColorHover);

				&:after {
					background-color: var(--themeColor);
				}
			}
		}
	}



	//* ANCHOR NEXT PREVIOUS NAVIGATION *//

	.node-navigation a {
		@extend %navigation-arrow;
		position: absolute;
		top: 0px;
		bottom: 0px;
		margin: auto;
		

		@include breakpoint($nav-arrow-breakpoint down) {
			display: none;
		}
	}


	
	//* ANCHOR GRAPHICS *//
	// Used in Crime areas. Images are uploaded by client.

	.graphics {
		@include breakpoint($main-breakpoint down) {
			display: none;
		}

		img {
			position: absolute;
			width: auto;
			max-width: 30%;
			max-height: 100%;
			z-index: -1;
		}

		.Image:first-child img {
			top: 0px;
			right: calc(-1 * var(--pageSpacing));
		}

		.Image:last-child img {
			bottom: 0px;
			left: calc(-1 * var(--pageSpacing));
		}
	}



	//* ANCHOR CRIME AREA BACKGROUND COLORS *//

	// COLOR MAP
	$crime-area-colors: (
		terrorism: (
			#46002A,
			dark
		),
		drug-trafficking: (
			#382B00,
			dark
		),
		cyber-crime: (
			#360044,
			dark
		),	
		economic-crime: (
			#004347,
			dark
		),	
		organised-property-crime: (
			#380202,
			dark
		),	
		facilitation-of-illegal-immigration: (
			#2C333E,
			dark
		),
		illicit-firearms-trafficking: (
			#002946,
			dark
		),
		trafficking-in-human-beings: (
			#A8CFDF,
			light
		),
		environmental-crime: (
			#ADDBCA,
			light
		),
		child-sexual-exploitation: (
			#E9B1B1,
			light
		),
		forgery-of-money-and-means-of-payment: (
			#8DC9CD,
			light
		),
		corruption: (
			#D7CBC5,
			light
		),
		maritime-piracy: (
			#CFCEDB,
			light
		),
		trafficking-in-stolen-vehicles: (
			#D1B8C7,
			light
		),
		illicit-tobacco-trade: (
			#D6D6D6,
			light
		),
		outlaw-motorcycle-gangs: (
			#D0C5F1,
			light
		),
		mobile-organised-crime-groups: (
			#E0F1C5,
			light
		),
		mafia-structured-organised-crime-groups: (
			#E3C5F1,
			light
		),
		illicit-trafficking-in-cultural-goods-including-antiquities-and-works-of-art: (
			#F1E5C5,
			light
		),
		racism-and-xenophobia: (
			#EDD2A4,
			light
		),
		genocide-crimes-against-humanity-and-war-crimes: (
			#ADC8AC,
			light
		),
		forgery-of-administrative-documents-and-trafficking-therein: (
			#80E0C3,
			light
		),
		intellectual-property-crime: (
			#F9B4B4,
			light
		),
		illicit-trafficking-in-hormonal-substances-and-other-growth-promoters: (
			#F5A4FC,
			light
		),
		crime-connected-with-nuclear-and-radioactive-substances: (
			#B6E080,
			light
		),
		kidnapping-illegal-restraint-and-hostage-taking: (
			#FFAC99,
			light
		),
		illicit-trade-in-human-organs-and-tissue: (
			#FFED7B,
			light
		)
	);


	// EXPORT RULES
	@each $crime-area, $info in $crime-area-colors {
		&.ca_#{$crime-area} {
			--themeColor: #{nth($info, 1)};

			// DARK THEME
			@if nth($info, 2) == dark {
				--decorativeHeadingTxColor:		white;
				--decorativeHeadingLineColor: 	white;
				--nodeHeaderMetaTxColor:		white;
				--subtitleTxColor: 				white;
				--breadcrumbDividerColor: 		white;
			}

			// LIGHT THEME
			@if nth($info, 2) == light {
				--txColor: 							#{$text-color};
				--linkColor:						#{$link-color};
				--decorativeHeadingTxColor:			#{$text-color};
				--decorativeHeadingLineColor: 		#{$text-color};
				--nodeHeaderMetaTxColor:			#{$text-color};
				--subtitleTxColor:					#{$text-color};
				--breadcrumbDividerColor:			#{$text-color};

				.button.turquoise {
					--btnBgColor:			#{$btn-bg-color};
					--btnTxColor: 			#{$btn-tx-color};
					--btnBgColorHover:		#{$btn-bg-br-color-hf};
					--btnBrColorFocus:		#{$btn-bg-br-color-hf};
					--btnTxColorFocus:		#{$btn-bg-br-color-hf};
				}
			}
		}
	}
}