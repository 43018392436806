
// APP HEADER

.AppHeader {
	position: fixed;
	bottom: 0px;
	width: 100%;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	height: var(--headerHeight);
	background-color: Primary(0);
	z-index: $z-index-AppHeader;

	@include breakpoint($fixed-app-header-breakpoint down) {
		box-shadow: 0px 0px 10px 0px transparentize(black, 0.7);
	}
	
	@include breakpoint($fixed-app-header-breakpoint up) {
		position: relative;
		bottom: auto;
		background-color: white;
	}



	//* ANCHOR LOGO *//
	
	.logo {
		a {
			display: block;
			width: rem-calc(170);
			height: 100%;
			font-size: 0px;
			background-color: Primary(0);
			background-image: url(~sass/images/logo-negative.svg);
			background-repeat: no-repeat;
			background-size: 138px 13px;
			background-position: center center;

			@include breakpoint($main-breakpoint up) {
				width: rem-calc(235);
				background-size: 171px 16px;
			}

			&.focus-visible {
				box-shadow: inset 0 0 0 3px Yellow(0);
			}

			// In order for the pattern to appear on the entire header in mobile but only on the logo in large screens.
			@include breakpoint($fixed-app-header-breakpoint up) {
				position: relative;
			}
		}
	}



	//* ANCHOR MAIN NAVIGATION *//

	.mainNavigation {

		// DEVICES
		@include breakpoint($fixed-app-header-breakpoint down) {
			padding-top: Spacing(24);
			background-color: $menu-bg-color;
			overflow: hidden;

			// Open / close animation
			position: fixed;
			left: -100vw;
			top: 0px;
			width: 100%;
			height: calc(100% - var(--headerHeight));
			transition: $global-transition;

			&.open {
				left: 0px;
			}
		}


		// DESKTOP
		@include breakpoint($fixed-app-header-breakpoint up) {
			flex-grow: 1;
			padding-left: Spacing(8);
			overflow: hidden;

			.Menu {
				height: 100%;
			}
		}
	}



	//* ANCHOR TOGGLERS *//

	& > ul {
		@extend %list-neutralizer;
		flex-shrink: 0;
		height: 100%;
		display: flex;
		align-items: stretch;

		@include breakpoint($laptop-2 up) {
			margin-right: Spacing(12);
		}


		// GROW ON MOBILE
		@include breakpoint($mobile-0 down) {
			flex-grow: 1;

			& > li {
				flex-grow: 1;
				flex-basis: auto;
			}
		}


		// SPACINGS IN DESKTOP
		@include breakpoint($fixed-app-header-breakpoint up) {
			& > li + li {
				margin-left: Spacing(16);
			}
		}


		// BUTTONS AND LINK
		& > li > button,
		& > li > a {
			@extend %header-links-buttons;
			cursor: pointer;
			@include get-icon(before, $header-icons, icon, inherit, rem-calc(24), 1, true);

			&:before,
			&:after {
				transition: $global-transition;
			}

			@include breakpoint($mobile-0 down) {
				width: 100%;
				padding: Spacing(4);
			}

			@include breakpoint($fixed-app-header-breakpoint down) {
				color: white;

				&.focus-visible {
					box-shadow: inset 0 0 0 2px Yellow(0);
					border-radius: $border-radius-4;
				}

				@include hover {
					color: Yellow(0);
				}
			}

			@include breakpoint($fixed-app-header-breakpoint up) {
				flex-direction: column;
				padding: 0px;
				font-family: $font-family-condensed;
				font-size: rem-calc(11);
				line-height: rem-calc(24);
				text-transform: uppercase;

				&:before {
					font-size: rem-calc(32);
				}
			}
		}


		// ICONS
		@each $class, $icon in $header-icons {
			li.#{$class} > button:before,
			li.#{$class} > a:before {
				content: $icon;
			}
		}


		// OPEN
		li > button {
			@include get-icon(after, $common-icons, close, inherit, rem-calc(16));

			&:after {
				position: absolute;
				opacity: 0;
			}

			&.open {
				&:before {
					opacity: 0;
				}

				&:after {
					opacity: 1;
				}

				@include breakpoint($fixed-app-header-breakpoint down) {
					background-color: $header-dropdown-bg-color;
				}

				@include breakpoint($fixed-app-header-breakpoint up) {
					color: Orange(0);
				}
			}
		}
	}



	//* ANCHOR MENU TOGGLER *//

	$menu-toggler-size: var(--headerHeight);

	& > ul > li.menu {
		order: 1;

		@include breakpoint($fixed-app-header-breakpoint up) {
			display: none;
		}

		button {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: $menu-toggler-size;
			height: $menu-toggler-size;
			cursor: pointer;

			@include breakpoint($mobile-0 down) {
				width: 100%;
				height: 100%;
			}

			@include hover {
				--menuTogglerColor: #{Yellow(0)};
			}
		

			// TOGGLE ICON
			&:before,
			&:after,
			span {
				width: 20px;
				height: 3px;
				background-color: var(--menuTogglerColor, white);
			}
		
			&:before,
			&:after {
				position: static;
				content: " ";
				transition: 300ms ease-in-out;
				opacity: 1;
			}
		
			span {
				margin: 3px 0px;
				font-size: 0px;
				transition: $global-transition;
			}
		
			
			// EXPANDED MENU
			&.active {
				background-color: $menu-bg-color;
		
				// Short lines (they create the x)
				&:before,
				&:after {
					position: absolute;
				}
		
				// short top line
				&:before {
					transform: rotate(45deg);
				}
		
				// long line
				span {
					opacity: 0;
				}
		
				// short bottom line
				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
}	