
// COOKIES
// EPOLD8-51 - This is rendered outside of the react root div from an external script.
// Switched it to EU cookie modal, so the previous styling was deleted.
// The client was asked about keeping the EU style and agreed on that. 


#cookie-consent-banner .cck-container {
	@include breakpoint($fixed-app-header-breakpoint down) {
		bottom: var(--headerHeight);
	}
}