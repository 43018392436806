
// SWITCH LANGUAGE DROPDOWN

.LanguageSwitcher {
	padding: Spacing(24);
	background-color: $header-dropdown-bg-color;
	@extend %theme-dark-blue;
	transition: opacity $global-transition;

	@include breakpoint($fixed-app-header-breakpoint down) {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: calc(100% - var(--headerHeight));
		overflow-x: hidden;
		overflow-y: auto;
	}

	@include breakpoint($fixed-app-header-breakpoint up) {
		position: absolute;
		top: 100%;
		right: 0px;
		padding: Spacing(40) Spacing(56) Spacing(40) Spacing(32);
		box-shadow: 0px 5px 10px 0px transparentize(black, 0.55);

		@include get-icon(before, $common-icons, header-active-indicator-small, $header-dropdown-bg-color, rem-calc(12), 1);

		&:before {
			position: absolute;
			bottom: 99.9%;
			right: 15px;
		}
	}



	//* ANCHOR OPEN CLOSE ANIMATION *//

	opacity: 0;
	pointer-events: none;
	visibility: hidden;
	z-index: -2;

	&.open {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
		z-index: $z-index-LanguageSwitcher;
	}



	//* ANCHOR TYPOGRAPHY *//

	--txColor: 		#{Yellow(0)};

	h2 {
		@extend %h4;
	}



	//* ANCHOR LIST *//

	$li-spacing: Spacing(8);

	ul {
		@extend %list-neutralizer;
		margin: -$li-spacing 0;

		@include breakpoint($mobile-0 up) {
			columns: 2;
			gap: Spacing(20);
		}

		@include breakpoint($mobile-1 up) {
			columns: 3;
		}

		@include breakpoint($tablet-0 up) {
			columns: 4;
			gap: Spacing(40);
		}

		@include breakpoint($fixed-app-header-breakpoint up) {
			columns: 2;
		}

		li {
			padding: $li-spacing 0;
			page-break-inside: avoid;
		}
	}



	//* ANCHOR LINKS *//

	$link-padding-tb: Spacing(8); 	
	$link-padding-lr: Spacing(16); 	

	a {
		display: inline-flex;
		align-items: center;
		padding-right: $link-padding-lr;
		font-size: rem-calc(14);
		line-height: rem-calc(15);
		border-radius: $border-radius-34;
			
		span {
			padding: $link-padding-tb $link-padding-lr;
			margin-right: Spacing(12);
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			transition: $global-transition;
			border-radius: inherit;
		}


		// HOVER
		@include hover {
			--linkColor: #{Yellow(0)};

			span {
				background-color: Yellow(0);
				color: Blue(1);
			}
		}


		// FOCUS
		&.focus-visible {
			--linkColor: #{Yellow(0)};
			box-shadow: 0 0 0 2px Yellow(0);
		}
	}
}