
// MAIN LAYOUT

.App {
	overflow-x: hidden;
	min-height: 100vh;

	@include breakpoint($fixed-app-header-breakpoint down) {
		padding-bottom: var(--headerHeight);
	}

	
	// MAX APP WIDTH
	& > header,
	& > main,
	& > footer {
		max-width: $app-width;
		margin: 0 auto;
	}
}