
// APP FOOTER

.AppFooter {


	//* ANCHOR VARIABLES *//

	$footer-breakpoint: $laptop-0;



	//* ANCHOR FOOTER TOP *//

	.footer-top {
		display: flex;
		align-items: center;
		padding: Spacing(32) var(--pageSpacing); 
		@include full-screen-background(Primary(0));
		@extend %theme-blue;


		// CONTENT
		.ClickableDrupalContent {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			@include breakpoint($footer-breakpoint up) {
				flex-direction: row;
			}
		}

		// TEXT
		p {
			display: flex;
			align-items: center;
			max-width: 580px;

			@include breakpoint($footer-breakpoint up) {
				margin-right: Spacing(32);
				margin-bottom: 0px;
			}
			
			// Icon
			@include get-icon(before, $common-icons, email, inherit, rem-calc(48));

			&:before {
				margin-right: Spacing(32);
			}
		}

		// BUTTON
		a.button {
			margin-bottom: 0px;
		}
	}



	//* ANCHOR FOOTER BOTTOM LAYOUT *//

	.footer-bottom {
		display: flex;
		flex-direction: column;
		padding: Spacing(48) 0;


		// SMALL
		@include breakpoint($footer-breakpoint down) {
			& > div {
				padding-left: var(--pageSpacing);
				padding-right: var(--pageSpacing);

				& + div {
					margin-top: Spacing(24);
					padding-top: Spacing(24);
					border-top: 1px solid Grey(-1);
				}
			}
		}


		// LARGE
		@include breakpoint($footer-breakpoint up) {
			padding-left: var(--pageSpacing);
			padding-right: var(--pageSpacing);
			flex-direction: row;
			align-items: center;

			.footer-bottom-second {
				padding: 0 Spacing(48);
				margin: 0 Spacing(48);
				border-width: 0 1px 0 1px;
				border-style: solid;
				border-color: Grey(-1);
			}
		}


		// CONTENT
		.ClickableDrupalContent {
			& > *:last-child {
				margin-bottom: 0px;
			}
		}
	}



	//* ANCHOR LOGO *//

	.logo {
		align-self: center;

		a {
			display: block;
			width: rem-calc(176);
			height: rem-calc(17);
			font-size: 0px;
			background-image: url(~sass/images/logo.svg);
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center center;

			@include breakpoint($footer-breakpoint down) {
				margin: auto;
			}
		}
	}



	//* ANCHOR COPYRIGHT *//

	.footer-bottom-first {
		margin-top: Spacing(16);

		.ClickableDrupalContent p {
			font-size: rem-calc(14);
			line-height: rem-calc(16);
			text-align: center;

			@include breakpoint($footer-breakpoint up) {
				text-align: left;
				max-width: 263px;
			}
		}
	}


	
	//* ANCHOR LINKS *//

	.footer-bottom-second {
		flex-grow: 1;

		.ClickableDrupalContent {
			@include breakpoint($mobile-1 up) {
				display: grid;
				grid-template-columns: auto auto;
			}
		}

		div.inline-links {
			justify-content: center;

			@include breakpoint($mobile-1 down) {
				padding-bottom: Spacing(24);
				margin-bottom: Spacing(24);
				border-bottom: 1px solid Grey(-1);
				margin-right: calc(-1 * var(--pageSpacing));
				margin-left: calc(-1 * var(--pageSpacing));
				padding-right: var(--pageSpacing);
				padding-left: var(--pageSpacing);
			}

			@include breakpoint($mobile-1 up) {
				margin-right: Spacing(48);
				padding-right: Spacing(48);
				border-right: 1px solid Grey(-1);
				margin-bottom: 0px;
			}
		}

		ul li {
			@include typography('p.small-text', true);
			--ulPaddingLeft: #{rem-calc(17)};
			--listItemSpacing: #{Spacing(6)};
			--ulDecorationTop: 6px;
			--ulDecorationLeft: 0px;

			&:before {
				width: 7px;
				height: 7px;
			}

			a {
				border-bottom: 0px;
			}
		}
	}



	//* ANCHOR SOCIAL *//

	$social-bg-color-hover: Primary(0);
	$social-br-color-focus: Primary(0);

	.footer-bottom-third {
		flex-shrink: 0;
		
		.ClickableDrupalContent ul {
			@extend %list-styling-remover;
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: Spacing(32);
				height: Spacing(32);
				border-bottom: 0px;
				border-radius: 50%;
				background-image: none;
				box-shadow: 0px 0px 0px 0px $social-br-color-focus;


				// HOVER
				&:before {
					content: " ";
					position: absolute;
					top: 0px;
					left: 0px;
					right: 0px;
					bottom: 0px;
					margin: auto;
					width: 0px;
					height: 0px;
					background-color: $social-bg-color-hover;
					border-radius: inherit;
					z-index: -1;
					transition: 200ms ease-out;
				}

				@include hover {
					color: white;

					&:before {
						width: 100%;
						height: 100%;
					}
				}

				// FOCUS
				&.focus-visible {
					box-shadow: inset 0px 0px 0px 2px $social-br-color-focus, 0px 2px 5px rgba(0, 0, 0, 0.38);
				}


				// ICON
				@include get-icon(after, $social-icons, icon, inherit, rem-calc(16), 1, true);
			}


			// ICONS
			@each $social, $icon in $social-icons {
				li.#{$social} a:after {
					content: $icon;
				}
			}

			li.X a:after {
				font-size: rem-calc(13);
			}

			li.youTube a:after {
				font-size: rem-calc(12);
			}
		}
	}
}