
// RELATED CONTENT

.RelatedContent {


	//* ANCHOR VARIABLES *//

	--nodeTeaserBgColor:				#{Grey(-3)};
	
	--slickSliderItemSpacing:			#{Spacing(12)};

	@include breakpoint($main-breakpoint up) {
		--slickSliderItemSpacing:		#{Spacing(24)};
	}

	// Layout related variables for increased specificity
	&.layout {
		--layoutPaddingTop:				var(--bgItemVerticalSpacing);
		--layoutPaddingBottom:			0px;

		// Curve color on elements with layout class after it
		& + .layout.curve:after {
			--curveColor: 				white;
		}
	}

	// DEFAULT VERSION
	&.default {
		--themeColor: 					#{Blue(2)};
		--nodeTeaserImageBgColor: 		#{tint(Blue(2), 10%)};
		--nodeTeaserNoImageBgColor: 	#{tint(Blue(2), 10%)};
	}


	// FEATURED OPERATIONS VERSION
	&.featured_operations {
		--themeColor: 					#{Blue(-1)};
		--nodeTeaserImageBgColor: 		#{tint(Blue(-1), 20%)};
		--nodeTeaserNoImageBgColor: 	#{tint(Blue(-1), 20%)};
		--slickArrowColor: 				white;
		--slickArrowColorHover: 		#{Yellow(-1)};
	}



	//* ANCHOR BACKGROUND *//
	
	&.layout {
		@extend %colored-layout;

		&:before {
			height: 50%;

			@include breakpoint($slick-slider-breakpoint up) {
				min-height: 264px; // So that arrows are always on blue background.
			}
		}
	}



	//* ANCHOR SLICK SLIDER *//

	.slick-slider {

		// BREAKPOINT AND DOWN
		@include breakpoint($slick-slider-breakpoint down) {
			grid-template-columns: $slick-slider-nav-button-size-mobile 1fr $slick-slider-nav-button-size-mobile;

			// Items
			.slick-list {
				grid-column: 1 / span 3;
			}

			// Arrows
			.slick-prev {
				grid-column: 1;
				grid-row: 2;
			}

			.slick-next {
				grid-row: 2;
				grid-column: 3;
			}

			// Dots
			.slick-dots {
				grid-column: 2;
				grid-row: 2;
				margin: 0 Spacing(24);
				align-items: center;
			}
		}


		// BREAKPOINT AND UP
		@include breakpoint($slick-slider-breakpoint up) {
			column-gap: Spacing(8);
			grid-template-columns: 1fr minmax(auto, $column-width) 1fr;

			// Items
			.slick-list {
				grid-column: 2;
			}

			// Arrows
			.slick-prev {
				grid-column: 1;
			}

			.slick-next {
				grid-column: 3;
				margin-left: auto;
			}

			// Dots
			.slick-dots {
				grid-column: 2;
				grid-row: 2;
			}
		}
	}
	


	//* ANCHOR TYPOGRAPHY *//

	h2 {
		text-align: center;
		--txColor: white;
	}



	//* ANCHOR ARROWS *//

	.slick-slider .slick-arrow {
		@include breakpoint($slick-slider-breakpoint down) {
			color: Primary(0);
		}
	}
}