
// INLINE LINKS
// Used with buttons and highlighted links

div.inline-links {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: Spacing(16);
	margin-bottom: calc(var(--globalVerticalSpacing));

	

	//* ANCHOR CHILDREN *//

	& > a.button,
	& > a.highlighted {
		margin-bottom: 0px;
	}
}