
// EMPACT

.Empact {

	//* ANCHOR LOGO *//

	&:before {
		content: " ";
		display: block;
		width: 225px;
		height: 72px;
		margin-bottom: Spacing(16);
		background-image: url(~sass/images/empact-logo.png);
		background-repeat: no-repeat;
		background-size: 100%;

		@include breakpoint($main-breakpoint up) {
			margin-bottom: Spacing(32);
		}
	}
}