
// NODE AND SEARCH TEASERS

.NodeTeaser,
.searchTeaser {

	//* ANCHOR VARIABLES *//

	--nodeTeaserPaddingLr: 			#{Spacing(24)};
	--nodeTeaserPaddingTb: 			#{Spacing(32)};

	@include breakpoint($main-breakpoint up) {
		--nodeTeaserPaddingLr: 		#{Spacing(40)};
	}



	//* ANCHOR PLACEHOLDERS *//

	%teaser-meta-styles {
		font-size: rem-calc(12);
		line-height: rem-calc(20);
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		color: inherit;
	}



	//* ANCHOR STYLING *//

	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: $teaser-border-radius;
	overflow: hidden;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);



	//* ANCHOR IMAGE AND PLACEHOLDERS *//

	.image-wrapper {
		flex-shrink: 0;
		position: relative;
		padding-top: var(--nodeTeaserImageWrapperPaddingT, $teaser-image-wrapper-padding-top);
		overflow: hidden;
		background-color: var(--nodeTeaserImageBgColor, $teaser-image-bg-color); // A placeholder bg color until image is loaded

		img,
		.no-image {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		
		img {
			object-fit: cover;
		}
	}


	// NO IMAGE ICONS
	.no-image {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--nodeTeaserNoImageBgColor, $teaser-image-bg-color);
		@include get-icon(before, $content-type-icons, icon, transparentize(white, 0.5), inherit);
		transition: background-color $global-transition;
	}

	@each $ct, $info in $content-type-icons {
		&.ct_#{$ct} .no-image:before {
			content: nth($info, 1);
			font-size: nth($info, 2);
		}
	}



	//* ANCHOR CONTENT *//

	.content-wrapper {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		position: relative;
		background-color: var(--nodeTeaserBgColor, $teaser-bg-color);
		padding-left: var(--nodeTeaserPaddingLr);
		padding-right: var(--nodeTeaserPaddingLr);
		padding-bottom: var(--nodeTeaserPaddingTb);
		transition: background-color $global-transition;

		// LAST CHILD
		&:last-child {
			margin-bottom: 0px;
		}
	}



	//* ANCHOR TITLE *//
	// h3 is the default, h2 is used in search

	h3, h2 {
		position: static; // Important in order to make the entire box clickable.
		@extend %paragraph;
		font-family: $font-family;
		font-weight: $font-weight-bold;
		padding-top: var(--nodeTeaserPaddingTb);
		margin-bottom: auto;

		a, button, span {
			color: var(--nodeTeaserTxColor, $link-color);
		}
	}



	//* ANCHOR MAKE THE ENTIRE BOX CLICKABLE *//

	h3 a:not(.button):before,
	h2 a:not(.button):before, // search teasers
	button:before {
		content: " ";
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		box-sizing: content-box;
		border-radius: $teaser-border-radius;
		transition: box-shadow $global-transition;
		z-index: 1;
	}

	// For teaser with image or image placeholder
	.image-wrapper + .content-wrapper a:not(.button):before {
		padding-top: var(--nodeTeaserImageWrapperPaddingT, $teaser-image-wrapper-padding-top);
	}



	//* ANCHOR PUBLISH DATE *//

	div.publish-date {
		position: absolute;
		top: -$teaser-publish-date-size * 0.5;
		left: -$teaser-publish-date-size * 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: $teaser-publish-date-size*0.5 + rem-calc(7);
		padding-right: rem-calc(6);
		width: $teaser-publish-date-size;
		height: $teaser-publish-date-size;
		background-color: Yellow(0);
		border-radius: 0 50% 50% 0;

		time {
			font-family: $font-family-condensed;
			font-size: rem-calc(10);
			line-height: rem-calc(11);
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			color: $text-color;
		}
	}



	//* ANCHOR START END DATE *//
	// Used in Events Teasers

	.start-end-date {
		padding-top: var(--nodeTeaserPaddingTb);
		order: -1;
		margin-bottom: calc(-1 * #{Spacing(20)});
		@extend %small-capitalized-heading;

		time {
			color: var(--teaserMetaTxColor, $text-color);
			transition: $global-transition;

			& + time:before {
				content: "-";
				margin: 0 Spacing(4);
			}
		}
	}



	//* ANCHOR DESCRIPTION *//

	.ClickableDrupalContent {
		margin-top: $teaser-content-items-spacing;
		@extend %small-text;
		color: var(--nodeTeaserTxColor, $link-color);
		transition: color $global-transition;
	}
}



//* ANCHOR HOVER AND FOCUS *//

// HOVER
// Hover is on article because of the hidden overflow.

.NodeTeaser:not(.ct_vacancy):not(.ct_internship):not(.ct_faq):not(.parent):not(.ct_child_abuse),
.searchTeaser {
	@include hover {
		--nodeTeaserBgColor: 		#{$teaser-bg-color-hover};
		--nodeTeaserTxColor: 		white;
		--teaserMetaTxColor: 		white;
		--nodeTeaserTagIconColor: 	#{Secondary(0)};
		--nodeTeaserNoImageBgColor: #{$teaser-image-bg-color-hover};
	}
}


// FOCUS
// Focus is on a:before because of the focus-visible polyfill.
.NodeTeaser,
.searchTeaser {
	a.focus-visible:before,
	button.focus-visible:before {
		box-shadow: inset 0px 0px 0px 5px var(--nodeTeaserFocusColor, $link-color);
	}
}



//* ANCHOR NODE TEASERS ONLY *//

.NodeTeaser {

	//* ANCHOR META *//

	.Meta {
		display: flex;
		margin-top: $teaser-content-items-spacing;

		& > h2 {
			display: none;
		}
			
		li {
			display: inline;
			line-height: rem-calc(20);
			color: var(--nodeTeaserTxColor, $link-color);

			label {
				@extend %show-for-sr;
			}

			span {
				@extend %teaser-meta-styles;
			}

			&:not(:last-child) {
				padding-right: 0px;
				
				&:after {
					content: ",";
					margin-left: 2px;
					margin-right: Spacing(4);
				}
			}
		}


		// Icon
		@include get-icon(before, $common-icons, tag, var(--nodeTeaserTagIconColor, Grey(1)), rem-calc(15), 1);

		&:before {
			margin-right: Spacing(12);
			margin-top: Spacing(4);
		}
	}

	ul.meta {
		@extend %list-neutralizer;

		li {
			display: table-row;
			font-size: rem-calc(12);
			line-height: rem-calc(16);

			* {
				color: var(--teaserMetaTxColor, Grey(0));
				transition: color $global-transition;
			}

			& + li > * {
				padding-top: Spacing(8);
			}
		}

		label {
			display: table-cell;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
			padding-right: Spacing(12);
		}
	}



	//* ANCHOR STATUS *//

	div.status span {
		display: inline-block;
		padding: Spacing(8);
		text-align: center;
		font-size: rem-calc(13);
		line-height: rem-calc(16);
		font-weight: $font-weight-bold;
		color: white;
		text-transform: uppercase;
		text-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
		border-radius: $border-radius-4;
	}

	.NodeList[class*="closed"] & div.status span {
		background-color: Red(1);
	}

	.NodeList[class*="open"] & div.status span {
		background-color: Green(1);
	}



	//* ANCHOR PARENT TITLE *//
	// In crime areas

	--teaserParentMarginBottom: 		#{Spacing(8)};

	@include breakpoint($main-breakpoint up) {
		--teaserParentMarginBottom: 	#{Spacing(16)};
	}

	p.parent-title {
		font-size: rem-calc(12);
		line-height: rem-calc(16);
		font-weight: $font-weight-regular;
		margin-bottom: var(--teaserParentMarginBottom);
		color: var(--teaserMetaTxColor, $text-color);
		transition: color $global-transition;
	}



	//* ANCHOR TEASER LABEL *//
	// White box in right corner. Used in Crime Areas (EMPACT) and promoted Publications.

	span.teaser-label {
		position: absolute;
		top: 0px;
		right: 0px;
		padding: Spacing(8) Spacing(12);
		font-size: rem-calc(10);
		line-height: rem-calc(16);
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		background-color: white;
		border-radius: 0 $teaser-border-radius 0 $teaser-border-radius;
		box-shadow: 2px -2px 0px 2px white; // reduces color border in right corner. 

		@include breakpoint($tablet-0 up) {
			padding: Spacing(16) Spacing(24);
			font-size: rem-calc(14);
			line-height: rem-calc(20);
		}
	}



	//* ANCHOR FORTHCOMING TEASER LABEL *//
	// Label next ot the publish date. Used in Publications.

	span.forthcoming-label {
		@extend %forthcoming-label;
		position: absolute;
		top: 0px;
		left: $teaser-publish-date-size*0.5 + Spacing(12);
		transform: translateY(-50%);
	}



	//* ANCHOR STAFF MEMBERS *//

	&.ct_staff_member {
		display: grid;

		& > div {
			grid-column: 1;
			grid-row: 1;
		}


		// IMAGE
		.image-wrapper {
			--nodeTeaserImageWrapperPaddingT: 37.11%;
		}


		// CONTENT
		.content-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: Spacing(16);
			padding-bottom: Spacing(8);
			background-color: transparent;

			@include breakpoint($main-breakpoint up) {
				flex-direction: row;
				align-items: flex-end;
				justify-content: center;
			}

			& > div {
				padding-left: Spacing(12);
				padding-right: Spacing(12);
				padding-bottom: Spacing(8);

				@include breakpoint($main-breakpoint up) {
					max-width: 50%;
				}

				&:first-child {
					display: flex;
					flex-direction: column;
				}
			}

			h3 {
				@extend %h2;
				padding-top: 0px;
				color: white;
				text-align: right;
				text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.24);

				@include breakpoint($main-breakpoint down) {
					text-align: center;
				}
			}

			.button {
				margin-bottom: 0px;
			}
		}
	}



	//* ANCHOR TENDERS (PROCUREMENTS) *//
	
	&.ct_etendering, // comes from external system
	&.ct_tender {

		// VARIABLES
		@include breakpoint($main-breakpoint up) {
			--nodeTeaserPaddingTb: 		#{Spacing(56)};
			--nodeTeaserPaddingLr: 		#{Spacing(56)};
		}


		// STYLING
		@extend %blue-bg-teaser;


		// CONTENT
		.content-wrapper {
			padding-top: var(--nodeTeaserPaddingTb);
		}


		// TITLE
		h3 {
			padding-top: 0px;
			margin-bottom: Spacing(16);
		}


		// STATUS
		div.status {
			order: -1;
			margin-bottom: Spacing(16);
		}
	}



	//* ANCHOR CRIME AREAS *//

	&.ct_crime_areas {

		// PARENT TEASER
		&.parent {
			@extend %blue-bg-teaser;

			@include hover {
				--nodeTeaserBgColor: #{$teaser-bg-color-hover};
			}
		}


		// CHILDREN
		&:not(.parent) h3 {
			padding-top: 0px;
		}


		// TEASER LABEL (EMPACT)
		span.teaser-label {
			width: rem-calc(88);
			height: rem-calc(38);
			background-image: url(~sass/images/empact-logo.png);
			background-repeat: no-repeat;
			background-size: 56px;
			background-position: center center;
			font-size: 0px;

			@include breakpoint($tablet-0 up) {
				width: rem-calc(136);
				height: rem-calc(60);
				background-size: 88px;
			}
		}


		// PARENT TITLE
		p.parent-title {
			order: -1;
			padding-top: var(--nodeTeaserPaddingTb);
		}
	}



	//* ANCHOR VACANCIES AND INTERNSHIPS *//

	&.ct_vacancy,
	&.ct_internship {
		$vacancy-elements-spacing: Spacing(16);

		// LAYOUT
		.content-wrapper {
			padding-top: var(--nodeTeaserPaddingTb);
		}

		h3 {
			padding-top: 0px;
		}

		.post-type,
		.deadline,
		.contract-type {
			order: -1;
			margin-bottom: $vacancy-elements-spacing;
		}

		.deadline + .contract-type {
			margin-top: -$vacancy-elements-spacing;
		}

		.department,
		.reference-number {
			margin-top: $vacancy-elements-spacing;
		}

		.department + .reference-number {
			margin-top: 0;
		}

		label {
			margin-right: Spacing(4);
		}


		// TYPOGRAPHY
		.content-wrapper > div {
			@extend %small-capitalized-heading;

			* { 
				color: inherit;
			}

			&:not(.post-type) {
				text-transform: initial;
			}

			// Roboto regular
			&.contract-type,
			&.reference-number {
				font-weight: $font-weight-regular;
				font-family: $font-family;
			}

			// Black
			&.post-type,
			&.deadline {
				--txColor: black;
			}

			// Grey
			&.department,
			&.reference-number {
				--txColor: #{Grey(1)};
			}

			// Red
			&.post-type.restricted {
				--txColor: #{Red(0)};
			}
		}


		// DECORATIVE LINE
		$initial-color: var(--vacancyTeaserDecorBgColor, Secondary(1));
		$final-color: Primary(0);

		&:after {
			content: " ";
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: Spacing(8);
			background-image: linear-gradient(90deg, $final-color, $final-color 50%, $initial-color 50%, $initial-color);
			background-size: 200% 100%;
			background-position: 100% 0;
			transition: $global-transition;
			z-index: 1;
		}

		@include hover {
			&:after {
				background-position: 0 0;
			}
		}


		// Focus color according to category
		--nodeTeaserFocusColor:		#{$initial-color};
	}


	// INTERNSHIP THEME COLORS
	&.ct_internship {
		--vacancyTeaserDecorBgColor: #{Purple(0)};

		.content-wrapper > div.post-type {
			--txColor: #{Purple(0)};
		}
	}



	//* ANCHOR FAQS *//

	&.ct_faq {
		overflow: visible;

		$icon-size: Spacing(48);

		.content-wrapper {
			padding-top: var(--nodeTeaserPaddingTb);
			border-radius: $teaser-border-radius;
		}

		h3 {
			padding-top: 0px;
		}


		// CATEGORIES
		.categories {
			order: -1;

			ul {
				@extend %list-neutralizer;
			}
			
			li {
				display: inline-block;
				margin-bottom: Spacing(16);
				@extend %small-capitalized-heading;
				text-transform: none;

				&:not(:last-child):after {
					content: ",";
					margin-right: Spacing(4);
				}
			}
		}


		// ICON
		button {
			@include get-icon(after, $common-icons, link-arrow, white, rem-calc(12));

			&:after {
				position: absolute;
				left: 0px;
				right: 0px;
				bottom: -$icon-size*0.5;
				margin: auto;
				padding-left: 2px;
				width: $icon-size;
				height: $icon-size;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $link-color;
				border-radius: 50%;
				transform: rotate(90deg);
				transition: $global-transition;
				opacity: 0;
				z-index: 1	;
			}

			@include hover {
				&:after {
					opacity: 1;
				}
			}
		}


		// EXPANDABLE CONTENT
		div.expandable-content {
			display: none;
			height: 0;
			overflow: hidden;

			.ClickableDrupalContent {
				padding-top: Spacing(16);
				margin-bottom: Spacing(16);
				// So that the content can be selected and the links are clickable.
				// EPOLD8-379
				position: relative;
				z-index: 1;

				*:not(strong) {
					@extend %small-text;
				}

				
				// VARIABLES
				--globalVerticalSpacing: 	#{Spacing(12)};
				--listItemSpacing: 			#{Spacing(8)};

				// ul
				--ulPaddingLeft: 			#{Spacing(32)};
				--ulDecorationTop: 			5px;
				--ulDecorationLeft: 		#{Spacing(8)};
				
				// ol
				--olPaddingLeft: 			#{Spacing(32)};
				--olDecorationLeft: 		#{Spacing(8)};
				--olDecorationFontSize: 	#{rem-calc(16)};
			}
		}


		// EXPANDED
		&.expanded {
			margin-bottom: $icon-size*0.5;
			
			div.expandable-content {
				display: block;
				height: auto;
			}

			button:after {
				opacity: 1;
				transform: rotate(-90deg);
			}
		}
	}



	//* ANCHOR CHILD ABUSE *//
	// This teaser has a different markup than the rest.
	
	&.ct_child_abuse {
		@extend %blue-bg-teaser;


		// IMAGE
		.image-wrapper {
			--nodeTeaserImageBgColor: white;
			padding-top: 0px;

			.Image {
				display: block;
				line-height: 0px;
			}

			img {
				position: static;
				width: 100%;
				height: auto;
				object-fit: none;
			}
		}


		// CONTENT
		.content-wrapper {
			padding: Spacing(16);

			h3 {
				padding-top: 0px;
				font-family: $font-family;
				font-size: rem-calc(12);
				line-height: rem-calc(20);
				font-weight: $font-weight-bold;
				color: white;
				text-transform: uppercase;
			}
		}


		// CONTENT ANIMATION
		// Only for devices with hover
		@media (hover: hover) and (pointer: fine) {

			.content-wrapper {
				position: absolute;
				bottom: -100px;
				width: 100%;
				opacity: 0;
				max-height: calc(100% - 24px);
				transition: $global-transition;

				// In case the content is taller than the container.
				h3 {
					overflow: hidden;
				}
			}


			// Content appears on hover and focus
			a:hover,
			a.focus-visible {
				.content-wrapper {
					bottom: 0px;
					opacity: 1;
				}
			}

			a.focus-visible:before {
				box-shadow: none;
			}
		}
	}



	//* ANCHOR AGREEMENTS *//
	// image improvements
	
	&.ct_agreement {
		.image-wrapper {
			--nodeTeaserImageBgColor: white;

			img {
				object-fit: contain;
				padding: var(--nodeTeaserPaddingTb) var(--nodeTeaserPaddingLr);
			}
		}
	}



	//* ANCHOR CENTRES  *//
	// home page centres section

	&.centre {
		@extend %blue-bg-teaser;
	}
}



//* ANCHOR SEARCH TEASERS ONLY *//

.searchTeaser {
	@extend %blue-bg-teaser;


	// HIGHLIGHTED TEXT
	strong {
		font-weight: inherit;
		text-decoration: underline;
		color: Yellow(0);
	}


	// CONTENT TYPE
	.type {
		display: flex;
		align-items: center;
		margin-top: $teaser-content-items-spacing;
		color: var(--nodeTeaserTxColor);
		@extend %teaser-meta-styles;

		// Icon
		@include get-icon(before, $common-icons, tag, var(--nodeTeaserTagIconColor, Grey(1)), rem-calc(15), 1);

		&:before {
			margin-right: Spacing(12);
		}
	}


	// HOVER
	strong, span {
		transition: color $global-transition;
	}
}