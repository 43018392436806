
// COUNTRY PAGE

//* ANCHOR COUNTRY INFO *//

.countryInfo {

	// VARIABLES
	$li-spacing: 		Spacing(12);


	// LIST
	ul {
		@extend %list-neutralizer;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: Spacing(24) - $li-spacing;
		background-color: Blue(1);
		border-radius: $border-radius-8;

		@include breakpoint($tablet-0 down) {
			flex-direction: column;
			align-items: flex-start;
		}

		@include breakpoint($main-breakpoint up) {
			padding-left: Spacing(32) - $li-spacing;
			padding-right: Spacing(32) - $li-spacing;
		}
	}


	// ITEMS
	li {
		display: flex;
		padding: $li-spacing;
		font-size: rem-calc(14);
		line-height: rem-calc(16);
		--txColor: white;

		label {
			flex-shrink: 0;
			margin-right: Spacing(16);
			font-weight: $font-weight-bold;
			text-transform: uppercase;
		}
	}
}



//* ANCHOR COUNTRY AGENCIES *//

.countryAgencies {

	// VARIABLES
	--agencyPaddingLr: 			#{Spacing(24)};

	@include breakpoint($main-breakpoint up) {
		--agencyPaddingLr: 		#{Spacing(40)};
	}

	&.layout {
		@include breakpoint($main-breakpoint up) {
			--layoutPaddingTop:			#{Spacing(56)};
		}
	}


	// STYLING
	.countryInfo + & {
		margin-top: var(--bgItemVerticalSpacing);
	}


	// HEADING
	h2 {
		text-align: center;
	}


	// LISTS
	ul {
		@extend %list-neutralizer;
	}


	// AGENCY ITEM
	& > div > ul > li {
		border-radius: $border-radius-8;
		box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.24);
		overflow: hidden;

		& + li {
			margin-top: Spacing(24);

			@include breakpoint($main-breakpoint up) {
				margin-top: Spacing(64);
			}
		}
	}


	// IMAGE
	img {
		width: 100%;
		height: auto;
	}


	// SPACINGS
	li > h3 {
		padding-top: var(--globalVerticalSpacing);
		padding-right: var(--agencyPaddingLr);
		padding-left: var(--agencyPaddingLr);
	}

	.ClickableDrupalContent,
	.contactDetails {
		padding: Spacing(40) var(--agencyPaddingLr);
	}

	.ClickableDrupalContent {
		padding-top: 0px;
	}


	// CONTACT DETAILS
	.contactDetails {
		@extend %theme-dark-blue;
		background-color: var(--themeColor);

		.layout {
			row-gap: Spacing(24);

			@include breakpoint($mobile-1 down) {
				& > div + div {
					padding-top: Spacing(20);
					border-top: 1px solid transparentize(white, 0.7);
				}
			}
		}

		h4 {
			text-transform: uppercase;
		}

		h5 {
			margin-bottom: Spacing(16);
			@extend %paragraph;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
		}

		li {
			@extend %small-text;

			& + li {
				margin-top: Spacing(12);
			}
		}
	}
}