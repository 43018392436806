
// FORMS

.Form {


	//* ANCHOR FIELDSETS *//

	fieldset {
		padding: 0px;
		margin: 0px;
		border: 0px;

		legend {
			padding: 0px;
		}
	}



	//* ANCHOR SPACINGS *//

	.fields {

		& > div + div {
			margin-top: var(--globalVerticalSpacing);
		}

		& + .message {
			margin-top: var(--globalVerticalSpacing);
		}
	}



	//* ANCHOR LABELS *//
	
	label {
		@extend %form-label;
	}



	//* ANCHOR REQUIRED INDICATOR *//

	span.required {
		color: Red(0);
		margin-left: Spacing(4);
	}



	//* ANCHOR INPUTS *//

	input,
	textarea,
	select {
		@extend %form-input;

		// FOCUS
		&.focus-visible {
			border-color: $input-br-color-focus;
			box-shadow: $input-box-shadow-focus;
		}
	}



	//* ANCHOR SELECTS *//

	select {
		padding-right: Spacing(56);
		appearance: none;
		background-image: url(~sass/images/select-arrow-blue.png);
		background-position: calc(100% - #{$input-padding-lr} + #{Spacing(8)}) center;
		background-repeat: no-repeat;
		cursor: pointer;
	}



	//* ANCHOR CHECKBOX *//

	.field-type-checkbox {
		position: relative;
		padding: Spacing(4) 0;

		input {
			@include create-radio-or-checkbox (
				$border-radius: $border-radius-8
			);

			& + label {
				margin-bottom: 0px;
				@extend %paragraph;
				font-weight: $font-weight-bold;

				&:after {
					margin-left: -1px;
				}
			}
		}

		& + .field-type-checkbox {
			margin-top: Spacing(16);
		}
	}



	//* ANCHOR RADIO *//

	.field-type-radio {

		label.radio-option {
			position: relative;
			padding: Spacing(4) 0px;
			margin-bottom: Spacing(8);
			@extend %paragraph;
			font-weight: $font-weight-bold;
		}

		input {
			@include create-radio-or-checkbox (
				$border-radius: 50%,
				$element-type: span
			);
		}
	}



	//* ANCHOR ACTIONS *//

	.actions {
		margin-top: var(--globalVerticalSpacing);

		.button {
			margin-bottom: 0px;
		}
	}
}