
// AGREEMENT META

.agreementMeta {
	
	
	//* ANCHOR VARIABLES *//

	$agreement-meta-breakpoint: $mobile-1;
	$image-size: 250px;



	//* ANCHOR BOX *//

	& > div {
		padding: Spacing(32);
		border-radius: $border-radius-8;
		background-color: white;
		@extend %theme-white;
		box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.24);
	}



	//* ANCHOR GENERAL RULES *//

	ul {
		@extend %list-neutralizer;
	}



	//* ANCHOR LAYOUT *//

	@include breakpoint($agreement-meta-breakpoint up) {
		& > div {
			display: grid;
			grid-template-columns: 1fr $image-size;
			column-gap: Spacing(24);
		}

		// WITHOUT IMAGE
		h2,
		.websites,
		.dates {
			grid-column: 1 / 3;
		}


		// WITH IMAGE
		.Image {
			grid-column: 2 / 3;
			grid-row: 1 / 5; // use 5 for cases were the image is taller than the text

			& + h2,
			& + h2 + *,
			& + h2 + * + * {
				grid-column: 1 / 2;
			}
		}
	}



	//* ANCHOR TITLE *//

	h2 {
		@extend %h3;
		margin-bottom: Spacing(16);

		@include breakpoint($agreement-meta-breakpoint up) {
			margin-bottom: Spacing(24);
		}
	}



	//* ANCHOR IMAGE *//

	.Image {
		display: flex;

		img {
			display: block;
			width: auto;
			max-width: $image-size;
			max-height: 130px; // to prevent huge images
			height: auto;
			margin: auto;
			border-radius: $border-radius-4;
		}

		@include breakpoint($agreement-meta-breakpoint down) {
			margin-bottom: Spacing(24);
		}
	}



	//* ANCHOR WEBSITES *//

	.websites {

		& + * {
			margin-top: Spacing(16);
		}

		& + h2 {
			margin-top: var(--globalVerticalSpacing);
		}

		li {
			@extend %paragraph;

			& + li {
				margin-top: Spacing(4);
			}
		}
	}



	//* ANCHOR DATES *//

	.dates li {
		display: inline;
		@extend %small-text;

		&:not(:last-child):after {
			content: "|";
			position: relative;
			top: -1px;
			margin: 6px;
		}
		
		label {
			font-weight: $font-weight-bold;
		}
	}
}