
// DECORATIVE HEADING

// Used on all headings and text elements

.decorative-heading {
	display: block;
	color: var(--decorativeHeadingTxColor, $text-color);

	&:after {
		content: " ";
		display: block;
		width: rem-calc(34);
		height: rem-calc(3);
		margin-top: Spacing(8);
		background-color: var(--decorativeHeadingLineColor, Yellow(0));
	}

	// ALIGN RIGHT
	&.text-align-right:after {
		margin-left: auto;
	}

	// ALIGN CENTER
	&.text-align-center:after {
		margin-left: auto;
		margin-right: auto;
	}
}
