
// BUTTONS

//* ANCHOR USER AGENT OVERRIDES *//

button {
	border: 0px;
	padding: 0px;
	cursor: pointer;
	background: transparent;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-align: inherit;

	&:focus {
		outline: 0px;
	}
}

input.button {
	border: 0px;
	cursor: pointer;
}



//* ANCHOR CLASS STYLING *//

.button,
%button {


	// * ANCHOR PRIMARY (DEFAULT) *//

	display: inline-block;
	padding: var(--btnPaddingTb, $btn-padding-tb) var(--btnPaddingLr, $btn-padding-lr);
	margin-bottom: var(--globalVerticalSpacing);
	text-align: center;
	font-family: var(--btnFontFamily, $font-family);
	font-size: var(--btnFontSize, $btn-font-size);
	line-height: var(--btnLineHeight, $btn-line-height);
	font-weight: $font-weight-bold;
	color: var(--btnTxColor, $btn-tx-color);
	background-color: var(--btnBgColor, $btn-bg-color);
	border-radius: $border-radius-34;
	box-shadow: 0px 0px 0px 0px var(--btnBgColor, $btn-bg-color);
	outline: $btn-extra-br-width solid transparent; // so that the animated box-shadow doesn't leave a pixel trace after the animation is over https://stackoverflow.com/a/12352196
	transition: background-color $global-transition,
				color $global-transition,
				box-shadow $global-transition;

	// FOCUS
	&.focus-visible {
		background-color: var(--btnBgColorFocus, white);
		color: var(--btnTxColorFocus, $btn-bg-br-color-hf);
		box-shadow: 0px 0px 0px $btn-extra-br-width var(--btnBrColorFocus, $btn-bg-br-color-hf), 0px 8px 4px rgba(0, 0, 0, 0.4);
	}

	// HOVER
	// Should be after focus in order to override it.
	@include hover {
		background-color: var(--btnBgColorHover, $btn-bg-br-color-hf);
		box-shadow: 0px 0px 0px $btn-extra-br-width var(--btnBgColorHover, $btn-bg-br-color-hf);
		color: var(--btnTxColor, $btn-tx-color);
	}


	// FIREFOX FIX
	// Hides empty space between background-color and box-shadow on hover in FF.
	position: relative;
	z-index: 1;

	&:after {
		content: " ";
		position: absolute;
		top: -2px;
		left: -2px;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		border: 2px solid var(--btnBgColorHover, $btn-bg-br-color-hf);
		border-radius: inherit;
		background-color: var(--btnBgColorHover, $btn-bg-br-color-hf);
		transition: $global-transition;
		opacity: 0;
		z-index: -1;
	}

	@include hover {
		&:after {
			opacity: 1;
		}
	}


	// DISABLED
	&:disabled {
		--btnBgColor: #{Grey(0)};
		pointer-events: none;
	}
}



// * ANCHOR SIZES *//

.button {
	&.small {
		--btnPaddingTb:			#{Spacing(8)};
		--btnPaddingLr:			#{Spacing(16)};

		--btnFontSize:			#{rem-calc(14)};
		--btnLineHeight:		#{rem-calc(16)};

		--btnIconTxSpacing: 	#{Spacing(8)};
	}

	&.large {
		--btnPaddingTb:			#{Spacing(16)};
		--btnPaddingLr:			#{Spacing(32)};

		--btnFontFamily:		#{$font-family-condensed};
		--btnFontSize:			#{rem-calc(20)};
		--btnLineHeight:		#{rem-calc(24)};
	}
}



// * ANCHOR COLORS *//

.button.turquoise,
%button-turquoise {
	--btnBgColor:				#{Secondary(0)};
	--btnTxColor:				#{Blue(3)};
	--btnBgColorHover:			#{Secondary(1)};
	--btnBrColorFocus:			#{Secondary(1)};
	--btnTxColorFocus:			#{Blue(3)};
}

.button.yellow,
%button-yellow {
	--btnBgColor:				#{Yellow(0)};
	--btnTxColor:				#{Blue(1)};
	--btnBgColorHover:			#{Yellow(1)};
	--btnBrColorFocus:			#{Yellow(1)};
	--btnTxColorFocus:			#{Blue(3)};
}

.button.orange,
%button-orange {
	--btnBgColor:				#{Orange(0)};
	--btnTxColor:				white;
	--btnBgColorHover:			#{Orange(2)};
	--btnBrColorFocus:			#{Orange(2)};
	--btnTxColorFocus:			#{Orange(2)};
}

.button.white,
%button-white {
	--btnBgColor:				white;
	--btnTxColor:				#{Primary(0)};
	--btnBgColorHover:			#{Grey(-3)};
	--btnBgColorFocus:			#{$btn-bg-color};
	--btnBrColorFocus:			white;
	--btnTxColorFocus:			white;
}



// * ANCHOR ICONS *//
// IMPORTANT: All icons should be added in :before since :after is used for an FF fix.
// Just extend the placeholders %btn-icon-right or %btn-icon-left and include the mixin icon.


.button {
	// MORE
	&.more {
		@extend %btn-icon-right;
		@include get-icon(before, $common-icons, link-arrow, inherit, rem-calc(12));

		&:before {
			padding-left: 3px;
		}
	}


	// ANCHOR
	&.anchor {
		@extend %btn-icon-right;
		@include get-icon(before, $common-icons, link-arrow, inherit, rem-calc(12));

		&:before {
			transform: rotate(90deg);
		}
	}


	// RETRY
	&.retry {
		@extend %btn-icon-right;
		@include get-icon(before, $common-icons, retry, inherit, rem-calc(13));

		&:before {
			padding-bottom: 1px;
		}
	}


	// BACK
	&.back {
		@extend %btn-icon-left;
		@include get-icon(before, $common-icons, link-arrow, inherit, rem-calc(12));

		&:before {
			padding-right: 3px;
			transform: rotate(180deg);
		}
	}
}