
// PRINT

@include breakpoint(print) {


	//* ANCHOR VARIABLES *//
	:root {
		--globalVerticalSpacing: 		#{Spacing(16)};
		--curveFontSize: 				0px;
	}

	.NodePanel {
		--nodePanelVerticalSpacing:		#{Spacing(24)};
		--nodeHeaderMarginBottom: 		#{Spacing(24)};
	}

	.box {
		--boxPaddingTb: 				#{Spacing(20)};
		--boxPaddingLr: 				#{Spacing(24)};
	}



	//* ANCHOR HIDDEN ELEMENTS *//

	.AppHeader,
	.AppFooter,
	.NodeHeader .header-top,
	.NodeHeader .header-bottom,
	.Languages,
	.Downloads,
	.Weblinks,
	.NodeList,
	.RelatedContent,
	.Form {
		display: none !important;
	}


	// IMAGES
	.NodePanel:not(.ct_child_abuse) .ImageGallery,
	.ImageGallery + .modal,
	.ImageGallery button,
	.countryAgencies .Image,
	.ClickableDrupalContent .Image,
	.ClickableDrupalContent img,
	.NodeImages {
		display: none !important;
	}


	// FLAGS
	%countries-with-flag-image {
		&:before,
		&:after {
			content: none !important;
		}
	}



	//* ANCHOR AVOID PAGE BREAK *//
	
	.Meta,
	.agreementMeta,
	.Block.child-abuse-privacy,
	.contactDetails,
	.TopMeta,
	.ClickableDrupalContent .box,
	.ClickableDrupalContent blockquote,
	.ClickableDrupalContent table tr,
	.ClickableDrupalContent table td,
	.ClickableDrupalContent table th,
	.ClickableDrupalContent %selector-of-colored-layout
	{ 
		page-break-inside: avoid;
	}



	//* ANCHOR EXPANDABLE CONTENT *//

	.ClickableDrupalContent .expandable {

		div.expandable-content {
			display: block;
			height: auto;
		}

		&.read-more > button {
			display: none;

			& + div.expandable-content {
				padding-top: 0px;
			}
		}
	}



	//* ANCHOR NODE HEADER *//

	.NodePanel .NodeHeader {

		&.layout {
			--layoutPaddingBottom: 	#{Spacing(24)};
		}


		// LAYOUT AND SPACINGS
		& > div {
			text-align: left;
		}

		h1,
		.subtitle {
			max-width: none;
			margin-left: 0;
			margin-right: 0;
		}

		.title-top .decorative-heading {
			margin-bottom: Spacing(16);

			&:after {
				margin-left: 0px;
			}
		}

		.subtitle, 
		.dates {
			margin-top: Spacing(16);
		}


		// PUBLISH DATE
		.publish-date {
			position: static;
			background-color: transparent;
			display: inline;
			width: auto;
			height: auto;
			font-family: inherit;
			font-size: inherit;
			font-weight: inherit;
			color: inherit;
			text-transform: none;
			
			label {
				position: static;
				width: auto;
				height: auto;
				clip: auto;
				white-space: normal;
				color: inherit;
			}

			time {
				padding: 0px;
				color: inherit;
			}

			&:not(:last-child):after {
				content: ",";
				margin-right: Spacing(8);
			}
		}
	}



	//* ANCHOR META *//

	.Meta {
		page-break-inside: avoid;

		ul, li {
			display: inline;
		}

		& > ul > li:not(:last-child) {
			padding-right: Spacing(16);
		}

		label {
			display: inline;
			margin-right: Spacing(16);
			margin-bottom: 0px;
		}
	}



	//* ANCHOR AGREEMENT META *//

	.agreementMeta.layout {
		padding-bottom: 0px;

		&:before {
			content: none;
		}

		div {
			padding: 0px;
			box-shadow: none;
			background-color: transparent;
			border-radius: 0px;
		}

		h2 {
			@include typography(h2);
		}

		.websites + h2 {
			margin-top: var(--nodePanelVerticalSpacing);
		}
	}



	//* ANCHOR COUNTRY NODE *//

	// COUNTRY INFO
	.countryInfo {
		ul {
			flex-direction: column;
			align-items: flex-start;
		}

		li {
			padding: 6px;
		}
	}


	// COUNTRY AGENCIES
	.countryAgencies.layout {

		--layoutPaddingTop: 0px;
		--layoutPaddingBottom: 0px;

		&:before {
			content: none;
		}

		h2 {
			text-align: left;
		}

		& > div > ul > li {
			border-radius: 0px;
			box-shadow: none;

			& > h3,
			& > .ClickableDrupalContent {
				padding: 0px;
			} 
		}

		.contactDetails {
			margin-top: var(--globalVerticalSpacing);
			padding: rem-calc(15 18);
			border-radius: $border-radius-8;

			h5 {
				margin-bottom: Spacing(4);
			}

			li + li {
				margin-top: Spacing(4);
			}
		}
	}



	//* ANCHOR TOP META *//

	.TopMeta {
		--itemsPerRow: 2;

		li {
			position: relative;
			padding-left: $top-meta-icon-placeholder-size;

			&:before {
				position: absolute;
				top: Spacing(8);
				left: 0px;
			}
		}
	}
}