
// MIXINS


//* ANCHOR MEDIA HOVER *//

// USE:			Wraps its content with the appropriate hover interaction media feature. This prevents hover effects to appear when not needed, for example in touch screen devices.
// AUTHOR:		NP

// FOR MORE INFO: https://app.nuclino.com/EWXGD/SASS/Interaction-Media-Features-52293e4e-2cc6-465d-9029-8c347e83f1c2

// EXAMPLE: @include hover { color: white; }

@mixin hover {
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			@content;
		}
	}
}



//* ANCHOR BACKGROUND COLOR ANIMATION *//

// USE: Animation from one color to another from all directions
// AUTHOR: NP

// INSTRUCTIONS: 
//		For $direction use ttb, btt, ltr and rtl
//		For $state use hover, focus or both

// EXAMPLE: @include bg-color-animation(transparent, $primary-0, btt, hover);

@mixin bg-color-animation(
	$initial-color,
	$final-color,
	$direction,
	$text-color: null,
	$state: both,
	$transition: $global-transition
){

	$final-bg-position: null;

	// PARENT ELEMENT
	transition: $transition;

	// Top to Bottom
	@if $direction == ttb {
		background-image: linear-gradient($initial-color, $initial-color 50%, $final-color 50%, $final-color);
		background-size: 100% 200%;

		$final-bg-position: 0 -100%;
	}

	// Bottom to Top
	@else if $direction == btt {
		background-image: linear-gradient($initial-color, $initial-color 50%, $final-color 50%, $final-color);
		background-size: 100% 200%;

		$final-bg-position: 0 100%;
	}

	// Left to Right
	@else if $direction == ltr {
		background-image: linear-gradient(90deg, $final-color, $final-color 50%, $initial-color 50%, $initial-color);
		background-size: 200% 100%;
		background-position: 100% 0;

		$final-bg-position: 0 0;
	}

	// Right to Left
	@else if $direction == rtl {
		background-image: linear-gradient(90deg, $initial-color, $initial-color 50%, $final-color 50%, $final-color);
		background-size: 200% 100%;

		$final-bg-position: 100% 0;
	}

	// Error
	@else {
		@error 'Direction should be one of the following: ttb, btt, ltr and rtl';
	}


	// STATE
	// Both hover and focus
	@if $state == both {
		@include hover {
			background-position: $final-bg-position;
			color: $text-color;
		}

		&.focus-visible {
			background-position: $final-bg-position;
			color: $text-color;
		}

	}
	
	// Hover
	@else if $state == hover {
		@include hover {
			background-position: $final-bg-position;
			color: $text-color;
		}
	}
	
	// Focus
	@else if $state == focus {
		&.focus-visible {
			background-position: $final-bg-position;
			color: $text-color;
		}
	}
	
	// Error
	@else {
		@error '$state should be one of the following, both, hover, focus';
	}

	@if $state == focus or $state == both {
		&.focus-visible {
			outline: none;
		}
	}
}



//* ANCHOR SCROLLBARS *//

// USE: 	Mixin to customize scrollbars
// AUTHOR:	Hugo Giraudel, improvements by NP

// INSTRUCTIONS:
// $size - Horizontal scrollbar's height and vertical scrollbar's width
// $foreground-color - Scrollbar's color
// $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
// $ff-size - thin - Since size doesn't work in Firefox and scrollbar-width is only supported in FF. Values: thin, auto, none.

// EXAMPLE:   @include scrollbars($scrollbar-width, $scrollbar-thumb-color, $scrollbar-track-color);

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 30%), $ff-size: thin) {

	// For Google Chrome Safari and Edge
	&::-webkit-scrollbar {
		width:  $size;
		height: $size;
	}

	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}
	
	&::-webkit-scrollbar-track {
		background: $background-color;
	}

	// For Firefox
	& {
		scrollbar-width: $ff-size;
		scrollbar-color: $foreground-color $background-color;
	}
}



//* ANCHOR FULL SCREEN BACKGROUND *//

// USE: expands background-color further than parents width in order to fill the entire screen.
// AUTHOR: NP

// EXAMPLE:  @include full-screen-background(grey);

@mixin full-screen-background($background-color, $position: before, $z-index: -1) {
	position: relative;

	&:#{$position} {
		content: " ";
		position: absolute;
		top: 0px;
		right: -100vw;
		bottom: auto;
		left: -100vw;
		margin: auto;
		height: 100%;
		width: 200vw;
		background-color: $background-color;
		z-index: $z-index;
	}
}



//* ANCHOR INPUT PLACEHOLDER *//

// !! IMPORTANT INFO !! : Please rethink using placeholders. They confuse users and do not offer extra value to UX.

// USE: styles input placeholder text
// EXAMPLE: @include input-placeholder(#ddd, 1rem, $font-weight-bold, italic);

@mixin input-placeholder($color, $font-size, $font-weight, $font-style) {
	&::-webkit-input-placeholder { /* WebKit browsers */
		color: $color;
		font-size: $font-size;
		font-weight: $font-weight;
		font-style: $font-style;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: $color;
		opacity:  1;
		font-size: $font-size;
		font-weight: $font-weight;
		font-style: $font-style;	
	}
	&:-ms-input-placeholder { /* Internet Explorer 10+ */
		color: $color;
		font-size: $font-size;
		font-weight: $font-weight;
		font-style: $font-style;
	}
}



//* ANCHOR RADIO BUTTON OR CHECKBOX MIXIN *//

// USE: mixin that hides real input of radio and checkbox and styles label :before and :after elements so that they resemble the input.
// AUTHOR: NP

// INSTRUCTIONS: Add  $border-radius: 50% for radio or $border-radius: 0px or greater for checkbox

@mixin create-radio-or-checkbox (
	$border-radius, // use 50% for radio button or px for checkboxes

	// label
	$element-type: label, // the element that is rendered after the input
	$label-input-margin: Spacing(24),

	// radio or checkbox
	$input-size: Spacing(32),
	$input-border-width: rem-calc(1),
	$input-border-color: $input-br-color,
	$input-bg-color: transparent,

	// when checked 
	$checked-input-border-color: $input-border-color,
	$checked-input-border-width: $input-border-width,
	$checked-input-bg-color: $input-bg-color,

	// check icon / radio inner circle
	$checked-indicator-size: Spacing(12), // check icon font size / radio inner circle width-height
	$checked-indicator-color: Primary(0) // check icon color / radio inner circle background
	) {

	$checked-indicator-left-position: ($input-size - $input-border-width*2 - $checked-indicator-size) * 0.5 + $input-border-width;

	// hides the real input
	// Visible for errors
	width: auto;
	display: inline-block;
	position: absolute;
	top: 0px;
	right: auto;
	bottom: 0px;
	left: $checked-indicator-left-position;
	margin: auto;
	opacity: 0;
	z-index: -1;

	// label
	& + #{$element-type} {
		position: relative;
		display: inline-block;
		padding-left: $input-size + $label-input-margin;
		transition: $global-transition;
		cursor: pointer;

		&:before {
			content: " ";
			position: absolute;
			top: 0px;
			right: auto;
			bottom: 0px;
			left: 0px;
			display: inline-block;
			width: $input-size;
			height: $input-size;
			margin: auto;
			border-width: $input-border-width;
			border-color: $input-border-color;
			border-style: solid;
			background-color: $input-bg-color;
			transition: $global-transition;
		}

		&:after {
			position: absolute;
			top: 0px;
			right: auto;
			bottom: 0px;
			left: $checked-indicator-left-position;
			margin: auto;
			content: " ";
			height: $checked-indicator-size;
			opacity: 0;
			transition: opacity $global-transition;
		}
	}

	// checked
	&:checked + #{$element-type} {
		&:before {
			border-color: $checked-input-border-color;
			border-width: $checked-input-border-width;
		}

		&:after {
			opacity: 1;
		}
	}

	// focus
	&.focus-visible + #{$element-type} {
		&:before {
			border-color: $input-br-color-focus;
			box-shadow: $input-box-shadow-focus;
		}
	}

	// radio
	@if $border-radius == 50% {
		& + #{$element-type}:before {
			border-radius: 50%;
		}

		& + #{$element-type}:after {
			border-radius: 50%;
			width: $checked-indicator-size;
			background-color: $checked-indicator-color;
		}
	}

	// checkbox
	@else {
		& + #{$element-type}:before {
			border-radius: $border-radius;
		}

		& + #{$element-type} {
			@include get-icon(after, $common-icons, check-mark, $checked-indicator-color, $checked-indicator-size);

			&:after {
				height: $checked-indicator-size;
				line-height: normal;
			}
		}
	}
}



//* ANCHOR EUROPOL DECORATIVE CURVE *//

// USE: Adds a decorative curve and the extra top padding needed. Used in layouts and boxes.
// AUTHOR: NP

// EXAMPLE: @include decorative-curve(top, var(--layoutPaddingTop));
//			@include decorative-curve(bottom, var(--globalVerticalSpacing));

@mixin decorative-curve(
	$position: top,
	$original-spacing: 0px
) {
	position: relative;
	@include get-icon(after, $common-icons, box-curve, var(--curveColor, $curve-color), var(--curveFontSize, #{rem-calc(8)}), 1);

	&:after {
		position: absolute;
		left: 0px;
		right: 0px;
		margin: auto;
		text-align: center;
	}


	@if $position == top {
		padding-top: calc(#{$original-spacing} + var(--curveFontSize)); // Adds extra top padding as a placeholder space for the curve icon.

		&:after {
			top: 0px;
		}
	} 

	@else if $position == bottom {
		margin-bottom: calc(#{$original-spacing} + var(--curveFontSize));
		z-index: 1; // In order to appear over other layouts when used in bottom position

		&:after {
			top: 100%;
			color: var(--themeColor);
		}
	}

	@else {
		@error "ERROR: $position must be either top or bottom.";
	}
}