
// TYPOGRAPHY

//* ANCHOR MAP *//
// You can add more css rules and they will be included automatically, no need to update the mixin.

$typography: (
	h1:	(
		font-family:			$font-family-condensed,
		font-weight:			$font-weight-bold,
		font-size:				rem-calc(36),
		line-height:			rem-calc(43),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			font-size:			rem-calc(56),
			line-height:		rem-calc(64)
		)
	),
	h2: (
		font-family:			$font-family-condensed,
		font-weight:			$font-weight-bold,
		font-size:				rem-calc(30),
		line-height:			rem-calc(40),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			font-size:			rem-calc(40),
			line-height:		rem-calc(48)
		)
	),
	h3: (
		font-family:			$font-family-condensed,
		font-weight:			$font-weight-bold,
		font-size:				rem-calc(24),
		line-height:			rem-calc(32),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			font-size:			rem-calc(28),
			line-height:		rem-calc(32)
		)
	),
	h4: (
		font-family:			$font-family-condensed,
		font-weight:			$font-weight-bold,
		font-size:				rem-calc(21),
		line-height:			rem-calc(28),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			font-size:			rem-calc(24),
			line-height:		rem-calc(32)
		)
	),
	h5: (
		font-weight:			$font-weight-bold,
		font-size:				rem-calc(19),
		line-height:			rem-calc(26),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			line-height:		rem-calc(24),
		)
	),
	p: (
		font-weight:			$font-weight-regular,
		font-size:				rem-calc(16),
		line-height:			rem-calc(24),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			font-size:			rem-calc(19)
		)
	),
	'p.small-text': (
		font-weight:			$font-weight-regular,
		font-size:				rem-calc(14),
		line-height:			rem-calc(16),
		margin-bottom:			var(--globalVerticalSpacing),
		desktop: (
			line-height:		rem-calc(20)
		)
	),
	'.decorative-heading': (
		font-family:			$font-family-condensed,
		font-weight:			$font-weight-bold,
		font-size:				rem-calc(13),
		line-height:			rem-calc(16),
		margin-bottom:			var(--globalVerticalSpacing),
		text-transform:			uppercase
	),
	'.subtitle': (
		font-family:			$font-family-condensed,
		font-size:				rem-calc(16),
		line-height:			rem-calc(22),
		color: 					var(--subtitleTxColor, Grey(0)),
		desktop: (
			font-size:			rem-calc(24),
			line-height:		rem-calc(36)
		)
	)
);



//* ANCHOR TYPOGRAPHY MIXIN *//

// USE:			Exports typographic elements from $typography map
// AUTHOR:		NP

// INSTRUCTIONS: 
//	For $element-to-export use one of the keys in $typography map, located in _variables.scss.
//	For $remove-margins use true in order to omit margin rules. False is the default option.

// CASES: 
// 1. Export all typographic elements by including the mixin in an @each loop 
// @each $element, $styles in $typography {
// 	#{$element} {
// 		@include typography($element);
// 	}
// }

// 2. Include on a specific element.
// h2.style-as-h3 {
// 		@include typography(h3, true);
// }

// 3. Create a placeholder for this specific style in order to reduce css size.
// %paragraph-styles {
// 		@include typography(p);
// }


@mixin typography($element-to-export, $remove-margins: false) {

	$styles: null;

	// DEFINE THE CORRECT MAP
	@if $remove-margins == true {
		$mobile-map: map-get($typography, $element-to-export);		

		// Check if there are desktop rules
		@if map-has-key($mobile-map, desktop) == true {
			$desktop-map: map-get(map-get($typography, $element-to-export), desktop);
			
			// Remove margins
			$mobile-map-no-margin: map-remove($mobile-map, margin-bottom, desktop);
			$desktop-map-no-margin: map-remove($desktop-map, margin-bottom);

			// Check if desktop map is empty
			@if length($desktop-map-no-margin) == 0 {
				$styles: $mobile-map-no-margin;
			}
			
			@else {
				// Merge maps
				$desktop-to-merge: (desktop: ($desktop-map-no-margin)); // A new map to nest it inside the $styles one and keep the layout identical to the $typography map.
				$styles: map-merge($mobile-map-no-margin, $desktop-to-merge);
			}

		}
		
		@else {
			$mobile-map-no-margin: map-remove($mobile-map, margin-bottom);
			$styles: $mobile-map-no-margin;
		}
	}
	
	@else {
		$styles: map-get($typography, $element-to-export);
	}


	// EXPORT THE RULES
	@each $rule, $value in $styles {

		// Desktop
		@if type-of($value) == map { 

			@include breakpoint($typography-breakpoint up) {
				@each $inner-rule, $inner-value in $value {
					#{$inner-rule}: $inner-value;
				}
			}
		}
		
		// Mobile
		@else {
			#{$rule}: $value;
		}
	}
}



//* ANCHOR USER AGENT OVERRIDES *//

h1, h2, h3, h4, h5, p {
	margin-top: 0px;
}



//* ANCHOR EXPORT ALL TYPOGRAPHIC STYLES *//

@each $element, $styles in $typography {
	#{$element} {
		@include typography($element);
	}
}