
// FLAGS
// Used in agreements list and node as well as in country expandables in ClickableDrupalContent.


//* ANCHOR LIST OF COUNTRY CODE NAMES *//

$list-of-countries: (
	AD, AE, AL, AM, AT, AU, BA, BE, BG, BR, CA, CH, CL, CN, CO, CY, CZ, DE, DK, EC, EE, ES, FI, FR, GB, GE, GR, HR, HU, IE, IL, IN, IS, IT, JP, KR, LI, LT, LU, LV, MC, MD, ME, MK, MT, MX, NL, NO, NZ, PL, PT, QA, RO, RS, RU, SE, SG, SI, SK, SM, TR, UA, US, XK 
);



//* ANCHOR LOOP FOR IMAGE EXPORT *//

@each $country in $list-of-countries {
	.country_#{$country} {
		
		// selector for counties that have a flag image.
		@extend %countries-with-flag-image; 

		&:before, // Agreement meta
		&:after, // Agreement no-image
		&.expandable > button:before, // Expandables
		&.expandable > p:first-child > button:before // Expandables
		{
			background-image: url(~sass/images/flags/#{$country}.svg);
		}
	}
}



//* ANCHOR AGREEMENT META *//

.agreementMeta h2%countries-with-flag-image {
	display: flex;
	align-items: center;

	&:before {
		content: " ";
		display: inline-block;
		width: 45px;
		height: 29px;
		margin-right: Spacing(16);
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		box-shadow: 0px 0px 2px 0px Grey(-1); // for white flags on white bg.

		@include breakpoint($main-breakpoint up) {
			width: 50px;
			height: 33px;
		}
	}
}



//* ANCHOR AGREEMENT TEASERS *//
// Included in :after so that it covers the agreements icon.

.NodeTeaser.ct_agreement .no-image:after {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}



//* ANCHOR COUNTRY EXPANDABLES *//
// CKEditor, after an update, adds a <p> element around <button>. We decided to style both cases in order to avoid markup changes.

.ClickableDrupalContent .expandable%countries-with-flag-image > button,
.ClickableDrupalContent .expandable%countries-with-flag-image > p:first-child > button {
	&:before {
		content: " ";
		display: inline-block;
		width: 35px;
		height: 24px;
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		border: 1px solid Grey(-1);
		flex-shrink: 0;

		@include breakpoint($typography-breakpoint up) {
			width: 47px;
			height: 32px;
		}
	}
}