
// EVENT TOP META

.TopMeta {


	//* ANCHOR HEADINGS *//

	h2 {
		text-align: center;
		text-transform: uppercase;
		grid-column: 1 / 4;
	}

	h3 {
		margin-bottom: Spacing(4);
	}



	//* ANCHOR LAYOUT *//

	ul.layout {
		@extend %list-neutralizer;

		@include breakpoint($mobile-1 up) {
			--itemsPerRow: 2;
		}

		@include breakpoint($tablet-1 up) {
			--itemsPerRow: 3;
		}
	}



	//* ANCHOR ITEMS *//

	& > ul > li {
		@include breakpoint($mobile-1 down) {
			display: flex;
			flex-direction: column;
			align-items: center;

			* {
				text-align: center;
			}
		}

		@include breakpoint($mobile-1 up) {
			position: relative;
			padding-left: $top-meta-icon-placeholder-size;
		}


		// ICONS
		@include get-icon(before, $top-meta-icons, icon, Secondary(-1), rem-calc(36), 1);

		&:before {
			display: flex;
			justify-content: center;
			width: $top-meta-icon-placeholder-size;

			@include breakpoint($mobile-1 down) {
				height: $top-meta-icon-placeholder-size;
				align-items: center;
			}
			
			@include breakpoint($mobile-1 up) {
				position: absolute;
				top: Spacing(8);
				left: 0px;
			}
		}

		@each $class, $icon in $top-meta-icons {
			&.#{$class}:before {
				content: $icon;
			}
		}

		&.event-location:before {
			font-size: rem-calc(40);
		}

		&.tender-type:before,
		&.tender-status:before {
			font-size: rem-calc(41);
		}

		&.tender-awarded:before {
			font-size: rem-calc(42);
		}

		&.event-venue:before {
			font-size: rem-calc(38);
		}


		// TEXT
		time,
		span,
		a,
		li {
			@extend %h5;
			--txColor: #{Grey(-1)};
		}


		// LISTS
		ul {
			@extend %list-neutralizer;

			li {
				display: inline;

				&:not(:last-child):after {
					content: ",";
					margin-right: Spacing(4);
				}
			}
		}
	}
}