
// VARIABLES


//******************** SASS ********************//

//* ANCHOR FONT FAMILIES *//

$font-family: 				'Roboto', sans-serif;
$font-family-condensed: 	'Roboto Condensed', sans-serif;



//* ANCHOR FONT WEIGHTS *//

$font-weight-regular: 	400;
$font-weight-bold: 		700;
$font-weight-black: 	900; // No italics. Only used in ol numbers.



//* ANCHOR LINE HEIGHT *//

// We decided to change the line-height of paragraphs and elements with the same typography in order to enhance legibility. This change applies only in cases with long content and not in general. This is why we didn't alter the line height in _typography.scss.

$content-line-height: 	1.5;



//* ANCHOR WIDTHS *//

$app-width:			rem-calc(1920);
$column-width:		rem-calc(1024);



//* ANCHOR TRANSITIONS *//

$global-transition-duration: 250ms;
$global-transition-ease: ease-out;

$global-transition: $global-transition-duration $global-transition-ease;



//* ANCHOR BORDER RADIUS *//

$border-radius-4:		4px;
$border-radius-8:		8px;
$border-radius-16:		16px;
$border-radius-34:		34px;
$border-radius-48:		48px;

$teaser-border-radius: 	$border-radius-8;
$image-border-radius: 	$border-radius-8;



//* ANCHOR Z-INDEX *//

$z-index-NodeHeader: 				10;
$z-index-LanguageSwitcher: 			11;
$z-index-menu: 						12;
$z-index-AppHeader: 				100;
$z-index-filters: 					101;
$z-index-ImageGalleryModal: 		102;
$z-index-loading: 					103;



//* ANCHOR BUTTONS *//

$btn-padding-tb:					Spacing(12);
$btn-padding-lr:					Spacing(24);
$btn-font-size:						rem-calc(16);
$btn-line-height:					rem-calc(24);
$btn-tx-color:						white;
$btn-bg-color:						Primary(0);
$btn-bg-br-color-hf: 				Primary(1); // hover and focus
$btn-extra-br-width: 				4px;

// ICONS
$btn-icon-size:						Spacing(16);
$btn-icon-text-spacing:				Spacing(16);
$btn-extra-padding: 				calc(var(--btnPaddingLr, #{$btn-padding-lr}) + #{$btn-icon-size} + var(--btnIconTxSpacing, #{$btn-icon-text-spacing}));



//* ANCHOR SCROLLBARS *//

$scrollbar-width: 					Spacing(6);
$scrollbar-thumb-color: 			Secondary(0);
$scrollbar-track-color: 			Grey(-2);



//* ANCHOR APP HEADER *//

$menu-bg-color: 					Blue(2);



//* ANCHOR GLOBAL COLORS *//

$body-bg-color:				white;
$text-color:				Grey(2);

$link-color:				Primary(0);
$link-contrast-color:		white;



//* ANCHOR BACKGROUNDS *//
// Used in boxes and layouts

$bg-blue:					Primary(0);
$bg-dark-blue:				Blue(3);
$bg-yellow:					Yellow(0);
$bg-grey:					Grey(-2);


// COMPONENT BACKGROUNDS
$downloads-bg-color: 				$bg-dark-blue;
$header-dropdown-bg-color: 		$bg-dark-blue;



//* ANCHOR DECORATION COLORS *//

$decoration-yellow:			Yellow(0);
$decoration-blue:			Primary(0);
$decoration-turquoise:		Secondary(0);



//* ANCHOR BLOCKQUOTE *//
// Default styles that are used along with css variables for color theming.

$bq-br-color:				#{Grey(-2)};
$bq-tx-color-secondary:		#{Grey(1)};
$bq-icon-bg-color:			$body-bg-color;



//* ANCHOR EXPANDABLES *//

$expandable-br-color:				black;
$expandable-btn-tx-color-hover:		Primary(1);



//* ANCHOR CURVES *//

$curve-color:						$body-bg-color;



//* ANCHOR SLICK SLIDER *//

$slick-slider-dot-bg-color: 			Primary(0);
$slick-slider-dot-bg-color-hover: 		Primary(1);
$slick-slider-dot-bg-color-active: 		Orange(0);
$slick-slider-nav-button-size-mobile: 	Spacing(32); 
$slick-slider-list-spacing-tb: 			Spacing(16); 
$slick-slider-list-dots-spacing: 		Spacing(24); 



//* ANCHOR INPUTS *//

$input-padding-tb: 						Spacing(12);
$input-padding-lr: 						Spacing(24);
$input-br-color: 						Grey(1);
$input-br-color-focus: 					Grey(2);
$input-box-shadow-focus: 				0px 0px 9px rgba(0, 0, 0, 0.24);



//* ANCHOR TEASERS *//

$teaser-publish-date-size: 							rem-calc(78px);

$teaser-content-items-spacing: 						Spacing(24);
$teaser-image-wrapper-padding-top: 					54%;
$teaser-image-wrapper-padding-top-portrait: 		140.545%;

// Bg color
$teaser-bg-color: 						white;
$teaser-bg-color-hover: 				Blue(0);
$teaser-bg-color-dark:	 				$bg-dark-blue;

// Image bg color
$teaser-image-bg-color: 				Grey(0);
$teaser-image-bg-color-hover: 			tint($teaser-bg-color-hover, 10);
$teaser-image-bg-color-dark: 			tint($teaser-bg-color-dark, 10);



//* ANCHOR TOP META *//

$top-meta-icon-placeholder-size: Spacing(72);




//******************** CSS ********************//

:root {

	//* ANCHOR VERTICAL SPACING *//

	--globalVerticalSpacing: 		#{Spacing(24)};

	@include breakpoint($main-breakpoint up) {
		--globalVerticalSpacing: 	#{Spacing(32)};
	}



	//* ANCHOR PAGE SPACING *//

	--pageSpacing: 					#{Spacing(24)};

	@include breakpoint($main-breakpoint up) {
		--pageSpacing: 				#{Spacing(56)};
	}



	//* ANCHOR VERTICAL SPACING ON ITEMS WITH BACKGROUND *//

	--bgItemVerticalSpacing: 		#{Spacing(32)};

	@include breakpoint($main-breakpoint up) {
		--bgItemVerticalSpacing: 	#{Spacing(64)};
	}



	//* ANCHOR HEADER HEIGHT *//

	--headerHeight: 				#{rem-calc(56)};

	@include breakpoint($fixed-app-header-breakpoint up) {
		--headerHeight: 			#{rem-calc(96)};
	}



	//* ANCHOR CURVE FONT SIZE *//

	--curveFontSize: #{rem-calc(8)};

	@include breakpoint($mobile-1 up) {
		--curveFontSize: #{rem-calc(12)};
	}

	@include breakpoint($main-breakpoint up) {
		--curveFontSize: #{rem-calc(16)};
	}
}



//* ANCHOR NODE PANEL AND NODE HEADER SPACINGS *//

// SCSS
$node-header-margin-bottom:  calc(var(--nodeHeaderMarginBottom) + var(--curveFontSize));


// CSS
:root {
	--nodePanelVerticalSpacing:			#{Spacing(32)};
	--nodeHeaderMarginBottom: 			#{Spacing(48)};

	@include breakpoint($main-breakpoint up) {
		--nodePanelVerticalSpacing:		#{Spacing(48)};
		--nodeHeaderMarginBottom: 		#{Spacing(64)};
	}
}