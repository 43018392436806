

// CONTAINS IMAGE GALLERY AND NODE IMAGES RULES
// NodImages is used for node images that do not open in a modal.

// GLOBAL RULES
.ImageGallery,
.NodeImages {


	//* ANCHOR VARIABLES *//

	--imageCollectionSpacing: 		#{Spacing(16)};



	// * ANCHOR LAYOUT *//
	// Fixed height images that appear in grid for large screens and in scrollable on row list in smaller ones.

	// SMALL SCREENS
	@include breakpoint($image-components-breakpoint down) {
		margin-left: calc(-1 * var(--pageSpacing));
		margin-right: calc(-1 * var(--pageSpacing));
	}

	& > ul {
		@extend %list-neutralizer;
		display: flex;
		margin-top: calc(-1 * var(--imageCollectionSpacing) *0.5);
		margin-bottom: calc(-1 * var(--imageCollectionSpacing) *0.5);

		li {
			flex-shrink: 0;
			padding: calc(var(--imageCollectionSpacing) * 0.5);
			line-height: 0; // Removes extra empty bottom space
		}


		// SCROLLBAR IN SMALL SCREENS
		@include breakpoint($image-components-breakpoint down) {
			overflow-x: auto;
			overflow-y: visible;

			li:first-child {
				padding-left: var(--pageSpacing);
			}

			li:last-child {
				padding-right: var(--pageSpacing);
			}
		}


		// GRID IN LARGE SCREENS
		@include breakpoint($image-components-breakpoint up) {
			flex-wrap: wrap;
			margin-left: calc(-1 * var(--imageCollectionSpacing) * 0.5);
			margin-right: calc(-1 * var(--imageCollectionSpacing) * 0.5);
		}
	}



	// * ANCHOR ITEMS *//

	& > ul > li {
		@include breakpoint($image-components-breakpoint up) {
			max-width: 100%;
		}

		// WRAPPER
		& > div {
			position: relative;
			overflow: hidden;
			border-radius: $image-border-radius;
		}


		// IMAGES
		img {
			max-width: 100%;
			height: auto;
			border-radius: $image-border-radius;
		}
	}



	// * ANCHOR ONE IMAGE *//

	& > ul > li:first-child:last-child {
		margin: auto;
	}



	// * ANCHOR IMAGE COLLECTION WITH MAX HEIGHT *//

	& > ul > li img {
		width: auto;
		height: 240px;
		object-fit: contain;
	}
}



// IMAGE GALLERY ONLY
.ImageGallery {


	// * ANCHOR CLICKABLE IMAGES *//

	& > ul > li {

		// BUTTON AND ANIMATIONS
		button {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			font-size: 0px;
			border-radius: $image-border-radius;
			transition: $global-transition;
			z-index: 1;

			
			// ICON
			@include get-icon(after, $common-icons, expand, white, rem-calc(12));

			&:before,
			&:after {
				position: absolute;
				right: 0px;
				bottom: 0px;
				width: rem-calc(30);
				height: rem-calc(30);
				transition: inherit;
			}

			// Icon bg
			&:before {
				content: " ";
				background-color: $link-color;
				opacity: 0.8;
				border-radius: $image-border-radius 0 $image-border-radius 0;
			}

			// Icon
			&:after {
				display: flex;
				align-items: center;
				justify-content: center;
			}


			// HOVER
			@include hover {
				// Icon bg
				&:before {
					width: 100%;
					height: 100%;
					opacity: 0.1;
				}

				// Icon
				&:after {
					transform: rotate(360deg) scale(1.25, 1.25);
				}
			}


			// FOCUS
			&.focus-visible {
				box-shadow: inset 0px 0px 0px 5px $link-color;

				// Icon bg
				&:before {
					opacity: 1;
				}
			}
		}
	}



	//* ANCHOR IMAGE GRID *//
	// Used in Multimedia CT

	.NodePanel.ct_multimedia & {

		// VARIABLES
		--itemsPerRow:				1;

		@include breakpoint($mobile-0 up) {
			--itemsPerRow:			2;
		}

		@include breakpoint($mobile-1 up) {
			--itemsPerRow:			3;
		}


		// OVERRIDES
		margin-left: auto;
		margin-right: auto;


		// MASONRY GRID
		& > ul {
			display: block;
			margin: 0px;
			columns: var(--itemsPerRow);
			overflow: hidden;

			& > li {
				page-break-inside: avoid;
				padding: 0px;
				margin-bottom: var(--imageCollectionSpacing);

				& > div {
					background-color: Grey(-2); // placeholder for lazy loaded images
				}

				img {
					width: 100%;
					height: auto;
					object-fit: none;
				}
			}
		}
	}



	// * ANCHOR MODAL *//

	& + .modal {
		@include full-screen-background(white);
		position: fixed;
		top: 50%;
		width: 100%;
		max-width: $app-width;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: Spacing(24) var(--pageSpacing);
		margin: 0px !important;
		background-color: white;
		z-index: $z-index-ImageGalleryModal;

		@include breakpoint($main-breakpoint up) {
			padding-top: Spacing(32);
			padding-bottom: Spacing(64);
		}


		// OPEN CLOSE ANIMATION
		left: 100%;
		transform: translate(0%, -50%);
		pointer-events: none;
		transition: $global-transition;
		visibility: hidden;

		&.open {
			left: 50%;
			transform: translate(-50%, -50%);
			pointer-events: all;
			visibility: visible;
		}


		// GENERAL BUTTON RULES
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px;
			margin: 0px;
			border-radius: 50%;
		}


		// CLOSE BUTTON
		button.close {
			flex-shrink: 0;
			width: Spacing(40);
			height: Spacing(40);
			border: 1px solid Primary(0);
			--btnBgColor: white;
			--btnTxColor: #{Primary(0)};
			@include get-icon(before, $common-icons, close, inherit, 1rem, 1, true);

			@include breakpoint($main-breakpoint up) {
				width: Spacing(48);
				height: Spacing(48);
			}

			&:before {
				transition: $global-transition;
			}

			@include hover {
				&:before {
					color: white;
				}
			}
		}


		// IMAGES
		& > ul {
			position: relative;
			@extend %list-neutralizer;
			flex-grow: 1;
			margin: Spacing(24) 0;

			@include breakpoint($main-breakpoint up) {
				margin: Spacing(48) 0;
			}

			& > li {
				position: absolute;
				top: 0px;
				left: 0px;
				align-items: center;
				justify-content: center;
				text-align: center;
				width: 100%;
				height: 100%;
				display: none;
				animation: fade-in-delayed 2s ease-out; // in order for lazy loaded images to fade in

				&.active {
					display: flex;
				}
			}

			picture {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
			}

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}


		//  NAVIGATION
		nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: auto;

			button.button {
				width: Spacing(48);
				height: Spacing(48);
				@include get-icon(before, $common-icons, link-arrow, inherit, rem-calc(23), 1, true);

				&:before {
					padding-left: 3px;
				}

				&.previous:before {
					transform: rotate(180deg);
				}

				@include breakpoint($main-breakpoint up) {
					width: Spacing(56);
					height: Spacing(56);
				}
			}
		}


		// LOADER
		& > ul:before {
			content: " ";
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			margin: auto;
			font-size: 10px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			animation: circles-loader 1.1s infinite ease;
			transform: translateZ(0);
		}
	}
}