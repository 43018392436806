
// NODE METADATA

.Meta {

	& > ul {
		@extend %list-neutralizer;

		& > li {
			line-height: 1.9;
		}
	}



	//* ANCHOR LAYOUT *//

	@include breakpoint($inline-list-breakpoint down) {
		& > ul > li + li {
			margin-top: Spacing(32);
		}
	}

	@include breakpoint($inline-list-breakpoint up) {
		ul, li {
			display: inline;
		}

		& > ul > li:not(:last-child) {
			padding-right: Spacing(16);
		}
	}



	//* ANCHOR LABEL *//

	label {
		--txColor: 		#{Grey(1)};

		display: block;
		margin-bottom: Spacing(16);
		font-family: $font-family-condensed;
		font-size: rem-calc(19);
		line-height: rem-calc(24); 
		font-weight: $font-weight-bold;

		&:after {
			content: ":";
		}

		@include breakpoint($inline-list-breakpoint up) {
			display: inline;
			margin-right: Spacing(16);
			margin-bottom: 0px;
		}
	}


	
	//* ANCHOR INNER LISTS *//

	li > ul {
		@extend %inline-list-with-circles;
	}
	
	

	//* ANCHOR CONTENT WITHOUT LIST *//
	
	li {
		span,
		time,
		a {
			@extend %inline-list-item;
		}
	}
}