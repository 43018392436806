
// CLICKABLE DRUPAL CONTENT
// Content that comes from ck-editor.

.ClickableDrupalContent,
%ClickableDrupalContent // used in ck-editor.scss
 {

	// Styled as a paragraph by default.
	@extend %paragraph;



	//* ANCHOR TYPOGRAPHY *//

	p, %paragraph {
		line-height: $content-line-height;
	}

	strong {
		font-weight: $font-weight-bold;
		color: inherit;
	}

	p {
		word-wrap: break-word;
	}


	// HEADING CLASSES
	// Used on any element in order to apply each heading's styles.
	.h2 {
		@extend %h2;
	}

	.h3 {
		@extend %h3;
	}

	.h4 {
		@extend %h4;
	}

	.h5 {
		@extend %h5;
	}


	// TEXT ALIGN
	// They also help in image alignment
	.text-align-center {
		text-align: center;
	}

	.text-align-right {
		text-align: right;
	}
	
	.text-align-justify {
		text-align: justify;
	}
	


	//* ANCHOR BLOCKQUOTE *//

	blockquote {
		position: relative;
		@extend %paragraph;
		margin: calc(#{Spacing(12)} + var(--globalVerticalSpacing)) 0px; // margin top and bottom create an extra gutter above the line. The --globalVerticalSpacing is added in order to prevent margin collapse.
		padding: Spacing(38) 0;
		border-width: 1px 0px 1px 0px;
		border-style: solid;
		border-color: var(--bqBrColor, $bq-br-color);

		@include breakpoint($typography-breakpoint up) {
			padding: Spacing(48);
		}

		& > *:last-child {
			margin-bottom: 0px;
		}


		// ICONS
		@include get-icon(both, $common-icons, quotes, var(--decorationColor, $decoration-yellow), rem-calc(19));

		&:before,
		&:after {
			position: absolute;
			padding-right: Spacing(24);
			background-color: var(--bqIconBgColor, $bq-icon-bg-color);
		}

		&:before {
			position: absolute;
			top: - Spacing(12);
			left: 0px;
		}

		&:after {
			position: absolute;
			bottom: - Spacing(12);
			right: 0px;
			transform: rotate(180deg);
		}


		// WITH TITLE
		&.with-title {

			* {
				margin-bottom: 0px;
			}

			h5 {
				color: var(--bqTxColorSecondary, $bq-tx-color-secondary);
			}

			p {
				margin-top: Spacing(20);

				@include breakpoint($typography-breakpoint up) {
					margin-top: Spacing(24);
				}

				// Decorative line
				&:before {
					content: " ";
					display: block;
					width: rem-calc(46);
					height: rem-calc(5);
					background-color: var(--decorationColor, $decoration-yellow);
					margin-bottom: Spacing(16);
				}
			}
		}


		// BLUE
		&.blue {
			--decorationColor: #{$decoration-blue};
		}
	}



	//* ANCHOR LISTS *//

	ul, ol {
		@extend %list-neutralizer;
		margin-bottom: var(	--globalVerticalSpacing);

		& > li {
			@extend %list-item;
		}


		// COLORS

		// Blue
		&.blue > li:before {
			--decorationColor: #{$decoration-blue};
		}

		// Turquoise
		&.turquoise > li:before {
			--decorationColor: #{$decoration-turquoise};
		}
	}


	//* ANCHOR Unordered list *//

	ul {
		--ulDecorationTop: 9px;

		& > li {
			@extend %ul-item;
		}
	

		// CIRCLE LIST
		&.circle > li:before {
			top: calc(var(--ulDecorationTop, #{$ul-decoration-top}) - #{$ul-circle-br-width});
			left: calc(var(--ulDecorationLeft, #{$ul-decoration-left}) - #{$ul-circle-br-width});
			background-color: transparent;
			border: $ul-circle-br-width solid var(--decorationColor, $decoration-yellow);
			box-sizing: content-box;
		}
	}



	//* ANCHOR Ordered list *//

	$ol-padding-left: 			Spacing(48);
	$ol-decoration-top: 		1px;
	$ol-decoration-left: 		Spacing(16);
	$ol-decoration-font-size: 	rem-calc(18);

	ol {
		counter-reset: counter;

		& > li {
			padding-left: var(--olPaddingLeft, $ol-padding-left);

			&:before {
				content: counter(counter);
				counter-increment: counter;
				position: absolute;
				top: var(--olDecorationTop, $ol-decoration-top);
				left: var(--olDecorationLeft, $ol-decoration-left);
				display: inline-block;
				font-size: var(--olDecorationFontSize, $ol-decoration-font-size);
				font-weight: $font-weight-black;
				color: var(--decorationColor, $decoration-yellow);
				letter-spacing: -1.5px;
			}
		}
	}



	//* ANCHOR LINKS *//

	p,
	li,
	p > strong,
	h1, h2, h3, h4, h5,
	td, th {
		& > a {
			@extend %underlined-link;
			word-wrap: break-word;
		}
	}
	
	// MORE
	a:not(.button).more {
		@include get-icon(after, $common-icons, link-arrow, inherit, rem-calc(12));

		&:after {
			margin-left: rem-calc(6);
		}
	}


	// TWEETABLE
	a.tweetable {
		@include get-icon(before, $social-icons, twitter, inherit, rem-calc(13));

		&:before {
			position: relative;
			top: -1px;
			margin-left: 2px;
			margin-right: rem-calc(6);
		}
	}


	// HIGHLIGHTED
	a.highlighted {

		// Variables
		$icon-size: Spacing(24);
		$padding-l: Spacing(24);
		$padding-r: $padding-l + $icon-size + Spacing(12);


		// Styling
		position: relative;
		display: inline-block;
		padding: Spacing(8) $padding-r Spacing(8) $padding-l;
		@extend %paragraph;
		background-color: Yellow(-1);
		border-radius: $border-radius-48;
		margin-bottom: var(--globalVerticalSpacing);
		color: $link-color;

		@include breakpoint($main-breakpoint up) {
			padding-top: Spacing(16);
			padding-bottom: Spacing(16);
		}


		// Icon
		&:after {
			position: absolute;
			right: $padding-l;
			top: 0px;
			bottom: 0px;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			width: $icon-size;
			height: $icon-size;
		}

		// Arrow icon
		&:not([rel~="external"]) {
			@include get-icon(after, $common-icons, link-arrow, inherit, rem-calc(12));
		}

		// Hover
		@include hover {
			background-color: Yellow(1);
			color: Primary(1);
		}

		// Focus
		&.focus-visible {
			box-shadow: 0px 0px 0px 2px var(--linkColor, $link-color);
		}
	}



	//* ANCHOR EXPANDABLES *//
	// CKEditor, after an update, adds a <p> element around <button>. We decided to style both cases in order to avoid markup changes.

	.expandable {

		// VARIABLES

		// Scss
		$border-radius: 					$border-radius-16;
		$arrow-size:						Spacing(40);
	

		// Css
		--expPaddingTb:						#{Spacing(16)};
		--expPaddingLr:						#{Spacing(24)};
		--expContentMarginBottom:			#{Spacing(40)};

		@include breakpoint($typography-breakpoint up) {
			--expPaddingTb:					#{Spacing(32)};
			--expPaddingLr:					#{Spacing(48)};
			--expContentMarginBottom:		#{Spacing(56)};
		}
	
	
		// WRAPPER
		position: relative;
		margin-bottom: var(--globalVerticalSpacing);
	
	
		// BUTTON
		& > button,
		& > p:first-child > button {
			@extend %paragraph;
			text-align: left;
			color: var(--linkColor, $link-color);
			transition: $global-transition;
			cursor: pointer;
			box-sizing: border-box;

			// Arrow
			@include get-icon(after, $common-icons, expandable-arrow, inherit, rem-calc(10));

			&:after {
				transition: $global-transition;
			}

			// Hover
			@include hover {
				color: var(--expBtnTxColorHover, $expandable-btn-tx-color-hover);
			}

			// Focus
			&.focus-visible {
				box-shadow: 0px 0px 0px 3px var(--linkColor);
			}
		}

		& > p:first-child {
			margin: 0px;
		}


		// CONTENT
		div.expandable-content {
			display: none;
			height: 0;
			overflow: hidden;
		}


		// EXPANDED
		&.expanded {
			--expandableBrColor: var(--linkColor);

			// Arrow
			button:after {
				transform: rotate(180deg);
			}

			// Content
			div.expandable-content {
				display: block;
				height: auto;
			}
		}

		
		// WITH BORDER AND PADDINGS VERSION
		// Not read-more version
		&:not(.read-more) {
			border: 1px solid var(--expandableBrColor, $expandable-br-color);
			border-radius: $border-radius;

			// Button
			& > button,
			& > p:first-child > button {
				display: flex;
				align-items: center;
				gap: Spacing(16);
				width: 100%;
				padding: var(--expPaddingTb) var(--expPaddingLr);
				@extend %h3;
				border-radius: $border-radius;

				// Icon
				&:after {
					display: flex;
					align-items: center;
					justify-content: center;
					width: $arrow-size;
					height: $arrow-size;
					margin-left: auto;
					font-size: rem-calc(12);
					padding-top: 5px;
				}

				// Hover
				@include hover {
					color: var(--expandableBtnTxColorHover, $expandable-btn-tx-color-hover);
				}

				// Focus
				&.focus-visible {
					box-shadow: 0px 0px 0px 3px var(--linkColor);
				}
			}

			// Content
			div.expandable-content {
				padding: 0 var(--expPaddingLr);

				& > *:last-child {
					margin-bottom: 0px;
				}

				// Padding bottom of content
				// When the padding is on div.expandable-content the animation is not smooth
				&:after {
					content: " ";
					width: 100%;
					height: var(--expContentMarginBottom);
					display: block;
				}
			}

			// Expanded
			&.expanded {
				--expandableBrColor: var(--linkColor);
	
				// Arrow
				button:after {
					padding-top: 0px;
					padding-bottom: 2px;
				}
			}
		}


		// READ MORE VERSION
		&.read-more {

			// Button
			& > button,
			& > p:first-child > button {
				@extend %underlined-link;

				&:after {
					display: inline-block;
					position: relative;
					top: -1px;
					margin-left: Spacing(8);
					margin-right: 2px;
				}
			}

			// Content
			div.expandable-content {
				padding-top: var(--globalVerticalSpacing);

				& > *:last-child {
					margin-bottom: 0px;
				}
			}

			// Expanded
			&.expanded > button:after {
				top: -2px;
			}
		}


		// COUNTRY
		&[class*="country_"] {
			border: 0px;
			background-color: white;
			box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.24);
			border-radius: $border-radius-8;
			--expPaddingTb:					#{Spacing(24)};
			--expPaddingLr:					#{Spacing(24)};
			--expContentMarginBottom:		#{Spacing(24)};

			@include breakpoint($main-breakpoint up) {
				--expPaddingTb:				#{Spacing(32)};
				--expPaddingLr:				#{Spacing(32)};
				--expContentMarginBottom:	#{Spacing(32)};
			}

			& > button,
			& > p:first-child > button {
				color: #{Grey(2)};

				&:after {
					color: $link-color;
				}

				// Hover
				@include hover {
					color: $expandable-btn-tx-color-hover;

					&:after {
						color: $expandable-btn-tx-color-hover;
					}
				}
			}

			div.expandable-content{
				--txColor: #{$text-color};
			}
		}
	}



	//* ANCHOR TABLES *//

	table {

		// IMAGES
		// /report-a-crime/report-cybercrime-online
		img {
			margin-bottom: 0px;
			border-radius: 0px;
			vertical-align: middle;
		}

		// PARAGRAPHS
		p {
			font-size: inherit;
			line-height: inherit;
			font-weight: inherit;
			margin-bottom: 0px;
		}
	}



	//* ANCHOR IMAGES *//

	$image-border-radius: $border-radius-8;

	img {
		max-width: 100%;
		height: auto;
		margin-bottom: var(--globalVerticalSpacing);
		border-radius: $image-border-radius;
	}

	// Images inside paragraphs
	p > img:last-child {
		margin-bottom: 0px;
	} 


	// WITH PICTURE
	// So that picture has the same height as the image
	.Image {
		line-height: 0;
	}


	// IMAGE WITH CAPTION

	.caption-img {
		display: inline-block;
		margin: 0px;
		margin-bottom: var(--globalVerticalSpacing);
		line-height: 0; // removes white space between image and caption

		img {
			float: none !important;
			border-radius: $image-border-radius $image-border-radius 0 0;
			margin-bottom: 0px;
		}

		figcaption {
			text-align: left;
			padding: Spacing(16);
			@extend %paragraph;
			border-radius: 0 0 $image-border-radius $image-border-radius;
			background-color: Grey(-1);
			color: $text-color;
		}
	}


	// ALIGNMENT CLASSES
	.align-left {
		@include breakpoint($main-breakpoint up) {
			float: left;
			margin-right: Spacing(64);
		}
	}

	.align-right {
		@include breakpoint($main-breakpoint up) {
			float: right;
			margin-left: Spacing(64);
		}
	}

	.align-center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}


	// WITHOUT MARGINS INSIDE A BOX
	.box img.no-margin {
		border-radius: 0px;
		margin-left: calc(-1 * var(--boxPaddingLr));
		margin-right: calc(-1 * var(--boxPaddingLr));
		width: calc(100% + (var(--boxPaddingLr) * 2));
		max-width: none;

		&:first-child {
			margin-top: calc(-1 * var(--boxPaddingTb));
		}

		&:last-child {
			margin-bottom: calc(-1 * var(--boxPaddingTb));
		}
	}



	//* ANCHOR SPACINGS BETWEEN ELEMENTS *//

	// LAYOUT
	.layout {
		&:not(:last-child) {
			margin-bottom: var(--globalVerticalSpacing);
		}

		// For grids of boxes
		& > div.box,
		& > div.expandable {
			margin-bottom: 0px;
		}
	}

	// Layout with background color after layout with background color
	%selector-of-colored-layout + %selector-of-colored-layout {
		margin-top: calc(-1 * var(--globalVerticalSpacing));
	}

	
	// VIDEO WRAPPER
	.video-wrapper + %selector-of-colored-layout {
		margin-top: var(--bgItemVerticalSpacing);
	}


	// LAST ELEMENT
	& > *:last-child,
	& > div:last-child > *:last-child,
	& > .layout:last-child > div > *:last-child,
	& > .expandable:last-child .expandable-content > *:last-child,
	& > .expandable:last-child .expandable-content > div:last-child > *:last-child {
		margin-bottom: 0px;
	}


	// EXTRA LAYOUT CLASSES THAT REMOVE SPACINGS
	.layout.remove-bottom-spacing {
		margin-bottom: calc(-1 * var(--nodePanelVerticalSpacing));
	}

	.layout.add-bottom-curve > div:last-child {
		@include decorative-curve(bottom);
		position: static;
		margin-bottom: 0px;

		&:after {
			text-shadow: 0 0 1px var(--themeColor); // fixes empty space between 2 curves
		}
	}
}