
// DOWNLOADS

.Downloads {


	//* ANCHOR VARIABLES *//

	// SCSS
	$downloads-breakpoint: 			$tablet-0;

	$downloads-items-spacing: 		Spacing(8);
	$downloads-global-spacing: 		Spacing(4);

	$icon-size: 					Spacing(32);
	$icon-spacing: 					$downloads-global-spacing*2;


	// CSS
	&.layout {
		@include breakpoint($main-breakpoint up) {
			--layoutPaddingTop:			#{Spacing(56)};
		}
	}

	

	//* ANCHOR GENERAL STYLING *//

	ul {
		@extend %list-neutralizer;

		li {
			font-size: rem-calc(16);
			line-height: rem-calc(24);
			font-weight: $font-weight-bold;
		}

		a {
			@extend %underlined-link;
			border-bottom: 0px;
		}
	}



	//* ANCHOR LAYOUT *//

	& > div {
		& > ul > li,
		& > ul > li > div {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		& > ul {
			margin: -$downloads-items-spacing;

			@include breakpoint($downloads-breakpoint up) {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}
		}

		& > ul > li {
			position: relative;
			min-height: $icon-size + $downloads-items-spacing;
			padding: $downloads-items-spacing;
			padding-left: $downloads-items-spacing + $icon-size + $icon-spacing;
			margin-right: Spacing(16) - $downloads-items-spacing;
		}
	}



	//* ANCHOR MAIN FILE *//

	div.file {
		margin-right: $downloads-global-spacing;


		// FILE TYPE ICONS
		.type {
			position: absolute;
			top: auto;
			bottom: auto;
			left: $downloads-items-spacing;
			display: flex;
			align-items: center;
			justify-content: center;
			width: $icon-size;
			height: $icon-size;
			border: 1px solid white;
			border-radius: 50%;

			// Icons
			@include get-icon(before, $file-type-icons, icon, inherit, 1rem, 1, true);

			@each $class, $info in $file-type-icons {
				$icon: nth($info, 1);
				$size: nth($info, 2);

				&.#{$class}:before {
					content: $icon;
					font-size: $size;
				}
			}
		}


		// TITLE
		& > a {
			margin-right: $downloads-global-spacing;
		}


		// LANGUAGE
		.language {
			text-transform: uppercase;
			margin-right: $downloads-global-spacing;
		}


		// DIVIDER WITH OTHER LANGUAGES
		&:not(:last-child):after {
			content: "-";
			margin-left: $downloads-global-spacing;
		}
	}



	//* ANCHOR OTHER LANGUAGES *//

	.otherLanguages {

		// LABEL
		label {
			margin-right: $downloads-global-spacing;
		}


		// LIST
		ul {
			display: flex;
			flex-wrap: wrap;

			li:not(:last-child):after {
				content: ",";
				margin-left: 2px;
				margin-right: Spacing(6);
			}
		}
	}
}