
// ANIMATIONS

//* ANCHOR FADE IN WITH DELAY *//
// Used in ImageGallery modal to smooth the appearance of lazy loaded images.

@keyframes fade-in-delayed {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}



//* ANCHOR LOADER *//
// Used in ImageGallery.
// SRC: https://projects.lukehaas.me/css-loaders

$loader-color: Secondary(0);

@keyframes circles-loader {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em $loader-color, 1.8em -1.8em 0 0em transparentize($loader-color, 0.8), 2.5em 0em 0 0em transparentize($loader-color, 0.8), 1.75em 1.75em 0 0em transparentize($loader-color, 0.8), 0em 2.5em 0 0em transparentize($loader-color, 0.8), -1.8em 1.8em 0 0em transparentize($loader-color, 0.8), -2.6em 0em 0 0em transparentize($loader-color, 0.5), -1.8em -1.8em 0 0em transparentize($loader-color, 0.3);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.3), 1.8em -1.8em 0 0em $loader-color, 2.5em 0em 0 0em transparentize($loader-color, 0.8), 1.75em 1.75em 0 0em transparentize($loader-color, 0.8), 0em 2.5em 0 0em transparentize($loader-color, 0.8), -1.8em 1.8em 0 0em transparentize($loader-color, 0.8), -2.6em 0em 0 0em transparentize($loader-color, 0.8), -1.8em -1.8em 0 0em transparentize($loader-color, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.5), 1.8em -1.8em 0 0em transparentize($loader-color, 0.3), 2.5em 0em 0 0em $loader-color, 1.75em 1.75em 0 0em transparentize($loader-color, 0.8), 0em 2.5em 0 0em transparentize($loader-color, 0.8), -1.8em 1.8em 0 0em transparentize($loader-color, 0.8), -2.6em 0em 0 0em transparentize($loader-color, 0.8), -1.8em -1.8em 0 0em transparentize($loader-color, 0.8);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.8), 1.8em -1.8em 0 0em transparentize($loader-color, 0.5), 2.5em 0em 0 0em transparentize($loader-color, 0.3), 1.75em 1.75em 0 0em $loader-color, 0em 2.5em 0 0em transparentize($loader-color, 0.8), -1.8em 1.8em 0 0em transparentize($loader-color, 0.8), -2.6em 0em 0 0em transparentize($loader-color, 0.8), -1.8em -1.8em 0 0em transparentize($loader-color, 0.8);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.8), 1.8em -1.8em 0 0em transparentize($loader-color, 0.8), 2.5em 0em 0 0em transparentize($loader-color, 0.5), 1.75em 1.75em 0 0em transparentize($loader-color, 0.3), 0em 2.5em 0 0em $loader-color, -1.8em 1.8em 0 0em transparentize($loader-color, 0.8), -2.6em 0em 0 0em transparentize($loader-color, 0.8), -1.8em -1.8em 0 0em transparentize($loader-color, 0.8);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.8), 1.8em -1.8em 0 0em transparentize($loader-color, 0.8), 2.5em 0em 0 0em transparentize($loader-color, 0.8), 1.75em 1.75em 0 0em transparentize($loader-color, 0.5), 0em 2.5em 0 0em transparentize($loader-color, 0.3), -1.8em 1.8em 0 0em $loader-color, -2.6em 0em 0 0em transparentize($loader-color, 0.8), -1.8em -1.8em 0 0em transparentize($loader-color, 0.8);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.8), 1.8em -1.8em 0 0em transparentize($loader-color, 0.8), 2.5em 0em 0 0em transparentize($loader-color, 0.8), 1.75em 1.75em 0 0em transparentize($loader-color, 0.8), 0em 2.5em 0 0em transparentize($loader-color, 0.5), -1.8em 1.8em 0 0em transparentize($loader-color, 0.3), -2.6em 0em 0 0em $loader-color, -1.8em -1.8em 0 0em transparentize($loader-color, 0.8);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em transparentize($loader-color, 0.8), 1.8em -1.8em 0 0em transparentize($loader-color, 0.8), 2.5em 0em 0 0em transparentize($loader-color, 0.8), 1.75em 1.75em 0 0em transparentize($loader-color, 0.8), 0em 2.5em 0 0em transparentize($loader-color, 0.8), -1.8em 1.8em 0 0em transparentize($loader-color, 0.5), -2.6em 0em 0 0em transparentize($loader-color, 0.3), -1.8em -1.8em 0 0em $loader-color;
	}
  }
  
