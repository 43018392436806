
// PUBLICATION NODE
// Named as document CT

.NodePanel.ct_document {


	//* ANCHOR STYLING *//

	.documentColumnWrapper {
		display: flex;
		column-gap: Spacing(40);
		row-gap: var(--nodePanelVerticalSpacing);

		@include breakpoint($mobile-1 down) {
			flex-direction: column;
			align-items: center;
		}
	}

	

	//* ANCHOR IMAGES *//

	.NodeImages {
		margin: 0px;
		max-width: rem-calc(250);
		flex-shrink: 0;


		// OVERRIDES
		& > ul {
			flex-direction: column;
			gap: var(--imageCollectionSpacing);
			margin: 0px;
			overflow: visible;

			li {
				padding: 0px;
			}

			.Image img {
				height: auto;
			}
		}
	}



	//* ANCHOR CONTENT *//

	.ClickableDrupalContent {
		flex-grow: 1;
	}
}