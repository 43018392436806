
// TIMELINE

.NodePanel.ct_timeline {
	@extend %theme-dark-blue;
	background-color: #{Blue(2)};
	padding-left: 0px;
	padding-right: 0px;
	--nodePanelVerticalSpacing: 0px; // removed margin between slides

	

	//* ANCHOR VARIABLES *//

	--timelineSlidePaddingT: 				#{Spacing(32)};
	--timelineElementLineSpacing: 			#{Spacing(12)};

	--timelineButtonSize: 					#{Spacing(32)};
	--timelineArrowButtonPadding: 			#{Spacing(16)};
	--timelineArrowButtonFontSize: 			#{Spacing(10)};

	@include breakpoint($fixed-app-header-breakpoint up) {
		--timelineSlidePaddingT: 			#{Spacing(56)};
		--timelineElementLineSpacing: 		#{Spacing(20)};

		--timelineButtonSize: 				#{Spacing(40)};
		--timelineArrowButtonPadding: 		#{Spacing(24)};
		--timelineArrowButtonFontSize: 		#{Spacing(12)};
	}



	//* ANCHOR SLIDE *//

	.slide {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		height: calc(100svh - var(--headerHeight));
		max-height: calc(100svh - var(--headerHeight));
		width: 100%;
		overflow: hidden;
		z-index: 0;
		--bgItemVerticalSpacing: 0px; // removes padding

		@include breakpoint($fixed-app-header-breakpoint up) {
			height: 100vh;
			max-height: 100vh;
		}

		.slide-content {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			flex-grow: 1;
			padding: 0 var(--pageSpacing);

			@include breakpoint($laptop-2 up) {
				--pageSpacing: #{Spacing(80)};
			}
		}

		a.next {
			flex-shrink: 0;
		}


		// GRADIENT
		&:after {
			content: " ";
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 100%;
			height: 30vh;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0.1998) 52.97%, rgba(0, 0, 0, 0) 100%);
			z-index: -1;
		}
	}



	//* ANCHOR FIRST SLIDE *//

	#slide1 {
		text-align: center;
		
		@include breakpoint($fixed-app-header-breakpoint up) {
			height: calc(100vh - var(--headerHeight)) ;
			justify-content: center;
		}
		
		.slide-content {
			padding-top: Spacing(38);
			
			@include breakpoint($fixed-app-header-breakpoint up) {
				padding-top: calc(12vh + #{Spacing(56)});
			}
		}


		// CONTENT
		h1,
		.ClickableDrupalContent {
			max-width: $column-width;
		}
	}



	//* ANCHOR SLIDE TITLE *//

	h2 {
		flex-shrink: 0;
		max-width: $column-width;
		text-align: center;
		margin-bottom: 0px;
	}

	#slide1 h2 {
		@include typography(h1);
	}



	//* ANCHOR IMAGES *//

	.slide > .Image img {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		object-fit: cover;
		z-index: -1;
	}



	//* ANCHOR 2 COLUMN LAYOUT *//

	.slide:not(#slide1) .ClickableDrupalContent {
		flex-grow: 1;
		width: 100%;

		.layout.columns-2 {
			--layoutColumnGap: 15%;
		}
	}



	//* ANCHOR SCROLL *//

	.slide-content {
		overflow-y: auto;
		overflow-x: hidden;
	}


	// SCROLLBAR
	@include breakpoint($fixed-app-header-breakpoint up) {
		.slide:not(#slide1) .slide-content {
			overflow: hidden;

			.ClickableDrupalContent {
				overflow: hidden;
			}

			.layout.columns-2 {
				overflow: hidden;
				max-height: 100%;
				height: 100%;
				grid-template-rows: 100%;

				& > div {
					height: 100%;
					max-height: 100%;
					overflow: auto;
					min-height: 0;
					padding: 0 Spacing(24);
				}
			}
		}

		#slide1 .slide-content {
			width: 100%;
		}
	}

	// Custom scrollbar for non devices 
	@media (hover: hover) and (pointer: fine) {
		.slide-content .layout.columns-2 > div,
		.slide .slide-content {
			@include scrollbars($scrollbar-width, $scrollbar-thumb-color, $scrollbar-track-color);
		}
	}



	//* ANCHOR LINES *//

	// BASIC LINE RULES
	%timeline-line {
		content: " ";
		position: absolute;
		left: 0px;
		right: 0px;
		margin: auto;
		width: 2px;
		background-color: white;	
	}


	// TOP
	.slide:not(#slide1) h2 {
		position: relative;
		padding-top: var(--timelineSlidePaddingT);

		&:before {
			@extend %timeline-line;
			height: calc(var(--timelineSlidePaddingT) - var(--timelineElementLineSpacing));
			top: 0px;
		}
	}


	// BOTTOM
	.slide:not(#slide1) .ClickableDrupalContent {
		position: relative;
		padding-top: Spacing(24);
		
		@include breakpoint($two-column-layout-breakpoint up) {
			margin-top: calc(var(--timelineElementLineSpacing) + 5px);
			padding-top: Spacing(38);
		}

		&:after {
			@extend %timeline-line;
			height: 100%;
			top: 0;

			@include breakpoint($two-column-layout-breakpoint down) {
				content: none;
			}
		}
	}

	#slide1 .slide-content {
		position: relative;

		&:after {
			@extend %timeline-line;
			flex-grow: 1;
			position: static;
			display: block;
			margin-top: calc(var(--timelineElementLineSpacing) + 5px);

			@include breakpoint($two-column-layout-breakpoint down) {
				content: none;
			}
		}
	}



	//* ANCHOR NEXT AND PREVIOUS BUTTONS *//

	// BOTH
	a.next,
	a.previous {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0px var(--timelineArrowButtonPadding) var(--timelineArrowButtonPadding);
		font-size: 0px;
		@include get-icon(after, $common-icons, expandable-arrow, var(--nextArrowColor, white), var(--timelineArrowButtonFontSize));

		&:before,
		&:after {
			width: var(--timelineButtonSize);
			height: var(--timelineButtonSize);
			transition: $global-transition;
		}

		&:before {
			content: " ";
			display: block;
			border: var(--nextArrowBrWidth, 1px) solid var(--nextArrowColor, white);
			border-radius: 50%;
		}

		&:after {
			padding-top: 1px;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
		}


		// HOVER AND FOCUS
		@include hover {
			--nextArrowColor:		#{Secondary(0)};
		}

		&.focus-visible {
			--nextArrowColor:		#{Secondary(0)};
			--nextArrowBrWidth: 	3px;
		}
	}


	// PREVIOUS 
	a.previous {
		margin-bottom: var(--timelineElementLineSpacing);
		transform: rotate(180deg);
	}


	// NEXT
	a.next {
		margin-top: var(--timelineElementLineSpacing);
		z-index: 2;
	}

	.slide:last-child a.next {
		display: none;
	}



	//* ANCHOR TOGGLE MENU BUTTON *//

	.toggleTimelineMenu {
		position: sticky;
		right: Spacing(24);
		top: Spacing(24);
	}



	//* ANCHOR MENU TOGGLER *//

	.toggleTimelineMenu {
		position: fixed;
		top: var(--timelineArrowButtonPadding);
		right: var(--timelineArrowButtonPadding);
		width: var(--timelineButtonSize);
		height: var(--timelineButtonSize);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px;
		margin: 0px;
		border-radius: 50%;
		font-size: 0px;
		@include get-icon(before, $common-icons, menu, inherit, rem-calc(12));

		@include breakpoint($fixed-app-header-breakpoint up) {
			top: calc(var(--headerHeight) + #{rem-calc(47)});
			right: Spacing(20);
		}
	}



	//* ANCHOR MENU MODAL *//
	// For general modal styles see _modals.scss 

	
	.timelineMenuModal {

		//  HEADING
		h1 {
			--txColor: #{Yellow(0)};
		}


		// LIST
		ol {
			@extend %list-neutralizer;
			padding-top: 2px; // visible focus on first element

			@include breakpoint($fixed-app-header-breakpoint up) {
				max-width: $column-width;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				margin-top: Spacing(24);
			}
		}

		li {
			@extend %paragraph;

			@include breakpoint($fixed-app-header-breakpoint up) {
				& + li:before {
					content: " ";
					width: 1px;
					height: Spacing(20);
					background-color: transparentize(white, 0.7);
					display: block;
					margin: 6px auto;
				}
			}

			@include breakpoint($fixed-app-header-breakpoint down) {
				& + li {
					margin-top: Spacing(16);
				}
			}

			a {
				@include hover {
					--linkColor: #{Yellow(0)};
				}

				&.focus-visible {
					box-shadow: 0px 0px 0px 1px white;
					border-radius: 2px;
				}
			}

			&.current a {
				--linkColor: #{Orange(0)};
			}
		}
	}
}



//* ANCHOR DEACTIVATE SCROLL AND HIDE FOOTER *//

body.timeline {
	overflow: hidden;

	.AppFooter {
		display: none;
	}
}