
// BREAKPOINTS



//* ANCHOR INSTRUCTIONS *//

// Please use device names for breakpoints followed by a number.
// Before importing this file you need to import _em-calc.scss or copy em-calc() in your functions scss file.
// This file is not necessary if the project uses foundation. Also em-calc() is not needed with foundation.
// If you chose to use foundation's mixin you can just copy the breakpoint variables in you _variables.scss file and use them in foundations map in _settings.scss. For example:
	// $breakpoints: (
	// 		small:    0,
	//		medium:   $mobile-1,
	//		large:    $tablet-1
	// );
// You can also create breakpoint variables like $typography-breakpoint: $tablet-1; which indicates the breakpoint that typography styles switch.



//* ANCHOR BREAKPOINT VARIABLES *//

$mobile-0: 		em-calc(420px);
$mobile-1: 		em-calc(640px);
$tablet-0: 		em-calc(800px);
$tablet-1: 		em-calc(1024px);
$laptop-0: 		em-calc(1280px);
$laptop-1: 		em-calc(1366px);
$laptop-2: 		em-calc(1440px);
$desktop-1: 	em-calc(1920px);


$main-breakpoint: 					$tablet-1;
$typography-breakpoint: 			$tablet-0;
$slick-slider-breakpoint: 			$tablet-0;
$image-components-breakpoint: 		$tablet-1;
$two-column-layout-breakpoint: 		$tablet-0;
$fixed-app-header-breakpoint:		$laptop-0 - em-calc(1);



//* ANCHOR BREAKPOINT MIXIN *//

// USE:			Media queries
// AUTHOR:		NP

// CASES:
// 1. Breakpoint and up
// 	@include breakpoint($large-0 up){...}

// 2. Breakpoint and down
// @include breakpoint($large-0 down){...} 

// 3. Between breakpoints (smaller first)
// @include breakpoint($large-0 $large-3){...}

// 4. Print
// @include breakpoint(print){...}

// 5. Breakpoint +/- 1px and up or down
// @include breakpoint(($large-0 - em-calc(1px)) down){...}


@mixin breakpoint($query) {

	$error-message: "The input of breakpoint mixin should be: (1) a combination of two BREAKPOINT VARIABLES, (2) a combination of a BREAKPOINT VARIABLE and the words UP/DOWN or (3) the word PRINT.";

	// SCREEN
	@if type-of($query) == list {
		$breakpoint: nth($query, 1);
		$direction: nth($query, 2);

		// From breakpoint and up
		// +1px in order for the rules to apply after the breakpoint
		// For example 1025px instead of 1024px
		@if $direction == up {
			@media screen and (min-width: ($breakpoint + em-calc(1px))) {
				@content;
			}
		}

		// From breakpoint and down
		@else if $direction == down {
			@media screen and (max-width: $breakpoint) {
				@content;
			}
		}

		// Between breakpoints
		@else {
			@media screen and (min-width: $breakpoint) and (max-width: $direction) {
				@content;
			}
		}
	}

	// PRINT
	@else if type-of($query) == string {
		@if $query == print {
			@media print {
				@content;
			}
		}

		@else {
			@error $error-message;
		}
	}

	// ERROR MESSAGE
	@else {
		@error $error-message;
	}
}