
// SOCIAL SHARE

.SocialShare {
	position: relative;

	& > button {
		width: Spacing(32);
		height: Spacing(32);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px;
		margin: 0px;
		@include get-icon(before, $common-icons, share, primary(0), rem-calc(16), 0, true);
	}



	//* ANCHOR DROPDOWN *//

	& > div {
		position: absolute;
		right: -10px;
		top: calc(100% + #{Spacing(16)});
		min-width: 142px;
		padding: Spacing(16);
		border: 1px solid Secondary(0);
		background-color: Blue(2);
		border-radius: $border-radius-8;
		text-align: left;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: $global-transition;
		z-index: $z-index-NodeHeader;

		// Triangle
		&:before {
			content: " ";
			position: absolute;
			top: -6px;
			right: 19px;
			width: Spacing(12);
			height: Spacing(12);
			border: inherit;
			background-color: inherit;
			transform: rotate(45deg);
			border-bottom: 0px;
			border-right: 0px;
		}

		// Open 
		&.open {
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}
	}



	//* ANCHOR LAYOUT *//

	label {
		display: block;
		margin-bottom: Spacing(16);
		color: Yellow(0);
	}

	ul {
		@extend %list-neutralizer;

		li {
			padding: Spacing(6) 0;
		}

		li + li {
			margin-top: Spacing(16);
		}
	}



	//* ANCHOR TEXT *//

	label, 
	li {
		@extend %small-text;
		font-weight: $font-weight-bold;
	}



	//* ANCHOR LINKS AND ICONS *//

	$icon-color: Blue(2);
	$circle-size: Spacing(24);

	li {
		a {
			position: relative;
			display: flex;
			align-items: center;
			color: Secondary(-1);

			&:before,
			&:after {
				width: $circle-size;
				height: $circle-size;
				border-radius: $border-radius-16;
				background-color: Secondary(-1);
			}

			// Icon
			&:before {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: Spacing(12);
				// removes empty space between box-shadow and icon in focus
				position: relative;
				left: -1px;
			}


			// Hover and focus helper
			&:after {
				content: " ";
				position: absolute;
				z-index: -1;
				margin: 0px; // external link override
				transition: $global-transition;
			}

			// Hover
			@include hover {
				color: $icon-color;

				&:after {
					width: 100%;
				}
			}

			// Focus
			&.focus-visible {
				&:after {
					width: 100%;
					background-color: transparent;
					box-shadow: 0px 0px 0px 3px Secondary(-1);
				}
			}
		}

		// Icons
		&.facebook a {
			@include get-icon(before, $social-icons, facebook, $icon-color, rem-calc(12));
		}

		&.linkedIn a {
			@include get-icon(before, $social-icons, linkedIn, $icon-color, rem-calc(11));
		}

		&.X a {
			@include get-icon(before, $social-icons, X, $icon-color, rem-calc(10));

			&:before {
				padding-top: 1px;
			}
		}

		&.email a {
			@include get-icon(before, $common-icons, email-fill, $icon-color, rem-calc(10));

			&:before {
				padding-top: 1px;
			}
		}
	}
}