
// CHILD ABUSE NODE

.NodePanel.ct_child_abuse {

	@include breakpoint($main-breakpoint up) {

		
		//* ANCHOR VARIABLES *//

		$column-number: 2;
		$column-spacing: Spacing(64);
		$max-width: ($column-width - $column-spacing) * 0.5;

		

		//* ANCHOR STYLING *//

		display: grid;
		grid-template-columns: repeat($column-number, 1fr);
		column-gap: $column-spacing;
		row-gap: 0px;


		
		//* ANCHOR IMAGE *//

		& > .image-wrapper .ImageGallery > ul > li {
			margin-left: 0px;
		}


		
		//* ANCHOR FULL WIDTH ITEMS *//

		.NodeHeader,
		& > .Block.child-abuse-privacy {
			width: 100%;
			grid-column: 1 / span $column-number;
		}


		
		//* ANCHOR ITEMS IN COLUMNS *//

		& > .Form,
		& > .image-wrapper,
		& > .ClickableDrupalContent {
			width: 100%;
			max-width: $max-width;
			margin-top: 0px;
		}


		
		//* ANCHOR VERTICAL SPACING *//

		.NodeHeader {
			margin-bottom: $node-header-margin-bottom;

			& + div {
				margin-top: 0px;
			}
		}


		
		//* ANCHOR WITH FORM *//

		& > .Form {
			grid-column: 2 / 3;
			grid-row: 2 / 4;
			margin-left: 0px;
		}

		& > div.image-wrapper.has-form + .ClickableDrupalContent {
			margin-left: auto;
			margin-right: 0px;
			margin-top: Spacing(48);
		}


		
		//* ANCHOR WITHOUT FORM *//

		& > div.image-wrapper {
			margin-left: auto;
		}

		& > .ClickableDrupalContent {
			margin-left: 0px;
		}
	}

	
	
	//* ANCHOR BLOCK *//

	.Block {

		@include breakpoint($main-breakpoint up) {
			--layoutPaddingTop: 		#{Spacing(64)};
			--layoutPaddingBottom: 		#{Spacing(64)};
		}

		@extend %layout;
		@extend %colored-layout;
		@extend %theme-yellow;
		@include decorative-curve(top, var(--layoutPaddingTop));
	}
}