
// SUBSCRIPTION PAGE
// div inside Page CT

.NewsletterSubscriptionManagement {


	//* ANCHOR VARIABLES *//

	$subscription-breakpoint:		$mobile-1;		 



	//* ANCHOR SPACINGS *//

	form > * + * {
		margin-top: var(--globalVerticalSpacing);
	}



	//* ANCHOR GROUPS *//

	.group {
		display: flex;
		flex-direction: column;
		padding: Spacing(24);
		box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.24);
		border-radius: $border-radius-8;
		border-top: 8px solid Primary(0);
		background-color: white;

		@include breakpoint($subscription-breakpoint up) {
			flex-direction: row;
		}

		@include breakpoint($main-breakpoint up) {
			padding: Spacing(40) Spacing(56);
		}


		// IMAGES
		&:nth-of-type(1):before,
		&:nth-of-type(2):after,
		&:nth-of-type(3):before {
			content: " ";
			display: flex;
			align-items: center;
			margin-bottom: Spacing(24);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			@include breakpoint($subscription-breakpoint up) {
				width: 30%;
				flex-basis: 25%;
				flex-shrink: 0;
				margin-bottom: 0px;
			}
		}

		&:nth-of-type(1):before {
			height: 187px;
			background-image: url(~sass/images/subscription-press-release.svg);

			@include breakpoint($subscription-breakpoint down) {
				height: 131px;
			}
		}

		&:nth-of-type(2):after {
			height: 197px;
			background-image: url(~sass/images/subscription-publications-documents.svg);

			@include breakpoint($subscription-breakpoint down) {
				height: 138px;
				order: -1;
			}
		}

		&:nth-of-type(3):before {
			height: 228px;
			background-image: url(~sass/images/subscription-vacancies-internships.svg);

			@include breakpoint($subscription-breakpoint down) {
				height: 160px;
			}
		}


		// SPACINGS
		@include breakpoint($subscription-breakpoint up) {
			&:nth-of-type(1) fieldset,
			&:nth-of-type(3) fieldset {
				padding-left: Spacing(56);
			}

			&:nth-of-type(2) fieldset {
				padding-right: Spacing(56);
			}
		}
	}



	//* ANCHOR FIELDSET *//

	fieldset {
		flex-grow: 1;

		
		// LEGEND
		legend {
			@extend %h3;
			margin-bottom: var(--globalVerticalSpacing);
		}


		// CONTENT
		.ClickableDrupalContent {
			@extend %small-text;
			margin-bottom: Spacing(16);

			p {
				@extend %small-text;
				margin-bottom: Spacing(4);
			}
		}

		p {
			line-height: $content-line-height;
		}
	}


	// IN GROUP
	.group fieldset legend {
		text-transform: uppercase;
		margin-bottom: Spacing(20);
	}



	//* ANCHOR EMAIL *//

	form > label {
		margin-bottom: 0px;

		span:not(.required) {
			display: inline-block;
			@extend %h3;
			margin-bottom: var(--globalVerticalSpacing);
		}
	}
}