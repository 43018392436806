
// FONTS
// Separated from icons file in order not to be included in extra css files like ck-editor and talentLink-vacancies.


//* ANCHOR ICON FONT FILES *//

@font-face {
	font-family: 'europol-icons';
	src: url('~sass/fonts/icons/europol-icons.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}