
// SITEMAP

nav.sitemap > ul {


	//* ANCHOR VARIABLES *//

	$sitemap-breakpoint: 	$tablet-0;

	--decorationColor: 		#{Secondary(0)};
	--itemsPerRow: 			1;

	@include breakpoint($sitemap-breakpoint up) {
		--itemsPerRow: 		2;
	}



	//* ANCHOR STYLING *//

	@extend %list-neutralizer;
	display: grid;
	grid-template-columns: repeat(var(--itemsPerRow, 1), 1fr);
	gap: Spacing(32);



	//* ANCHOR LINKS *//

	a {
		border-radius: 2px;

		@include hover {
			--linkColor: #{Yellow(0)};
		}

		&.focus-visible {
			box-shadow: 0 0 0 1px white;
		}
	}



	//* ANCHOR BOXES *//

	& > li {
		--linkColor: white;
		padding: Spacing(32);
		background-color: Blue(2);
		border-radius: $border-radius-8;

		@include breakpoint($sitemap-breakpoint up) {
			padding: Spacing(56);
		}


		// BOX TITLE
		& > a {
			display: inline-block;
			@extend %h3;
			--linkColor: #{Secondary(0)};

			& + ul {
				margin-top: Spacing(16);
			}
		}
	}



	//* ANCHOR LISTS *//

	ul {
		@extend %list-neutralizer; 
	}

	& > li > ul {

		// SPACINGS
		li + li {
			margin-top: Spacing(16);
		}

		li ul {
			margin-top: Spacing(16);
		}


		// NO LIST DECORATION
		& > li {
			@extend %paragraph;
			font-family: $font-family-condensed;
		}

		
		// LINE LIST DECORATION
		& > li > ul > li {
			padding-top: Spacing(8);
			padding-bottom: Spacing(8);
			margin-left: Spacing(16);
			padding-left: Spacing(16);
			border-left: 1px solid var(--decorationColor);
			--linkColor: #{Grey(-1)};
		}

		// BULLET LIST DECORATION
		& > li > ul > li > ul > li {
			@extend %ul-item;
			@extend %small-text;
			font-family: $font-family;
			--linkColor: #{Grey(0)};
			--ulDecorationTop: 5px;
		}
	}
}