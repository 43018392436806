
// LAYOUTS


//* ANCHOR STYLING *//

.layout,
%layout {

	--layoutPaddingTop: 		#{Spacing(32)};
	--layoutPaddingBottom: 		#{Spacing(32)};

	@include breakpoint($main-breakpoint up) {
		--layoutPaddingTop: 		#{Spacing(40)};
		--layoutPaddingBottom: 		#{Spacing(40)};
	}


	//* ANCHOR GENERAL *//

	display: grid;
	align-items: start;
	column-gap: var(--layoutColumnGap, Spacing(40));
	row-gap: var(--layoutRowGap, var(--globalVerticalSpacing));
	grid-template-columns: repeat(var(--itemsPerRow, 1), 1fr);


	// REMOVE BOTTOM MARGIN
	& > div > *:last-child,
	& > div > *:last-child:not(.inline-links, nav) > *:last-child {
		margin-bottom: 0px;
	}
}
	


// COLUMNS, WIDTH AND CURVES

.layout {
	
	//* ANCHOR ADDS LEFT AND RIGHT MARGINS *//

	&.margins {
		padding-right: var(--pageSpacing);
		padding-left: var(--pageSpacing);
	}



	//* ANCHOR COLUMN WIDTH *//

	&.column-width {
		max-width: $column-width;
		padding-right: var(--pageSpacing);
		padding-left: var(--pageSpacing);
		margin-left: auto;
		margin-right: auto;
		box-sizing: content-box;
	}



	//* ANCHOR 2 COLUMNS *//

	&.columns-2 {

		@include breakpoint($two-column-layout-breakpoint up) {
			--itemsPerRow: 2;
		}

		@include breakpoint($tablet-1 up) {

			&.expanded-1 {
				grid-template-columns: 2fr 1fr;
			}

			&.expanded-2 {
				grid-template-columns: 1fr 2fr;
			}
		}
	}



	//* ANCHOR 3 COLUMNS *//

	&.columns-3 {

		@include breakpoint($mobile-1 $tablet-1) {
			--itemsPerRow: 2;

			// Expanded item stays expanded
			&.expanded-1 > div:nth-child(1),
			&.expanded-3 > div:nth-child(3) {
				grid-column: 1/calc(var(--itemsPerRow) + 1);
			}
		}

		@include breakpoint($tablet-1 up) {
			--itemsPerRow: 3;

			&.expanded-1 {
				grid-template-columns: 2fr 1fr 1fr;
			}

			&.expanded-3 {
				grid-template-columns: 1fr 1fr 2fr;
			}
		}
	}



	//* ANCHOR 4 COLUMNS *//

	&.columns-4 {

		@include breakpoint($mobile-1 up) {
			--itemsPerRow: 2;
		}

		@include breakpoint($tablet-0 up) {
			--itemsPerRow: 3;
		}

		@include breakpoint($tablet-1 up) {
			--itemsPerRow: 4;
		}
	}
}



//* ANCHOR BG COLORS *//
// If a new color is added please make sure to extend %colored-layout in order to obtain the general styling. 


%colored-layout {
	padding-top: var(--layoutPaddingTop);
	padding-bottom: var(--layoutPaddingBottom);
	@include full-screen-background(var(--themeColor, transparent));
}


// GREY
.layout.grey {
	@extend %colored-layout;
	@extend %theme-grey;
}


// BLUE
.layout.blue {
	@extend %colored-layout;
	@extend %theme-blue;
}


// DARK BLUE
.layout.dark-blue {
	@extend %colored-layout;
	@extend %theme-dark-blue;
}


// YELLOW
.layout.yellow {
	@extend %colored-layout;
	@extend %theme-yellow;
}


// COLORED LAYOUTS SELECTOR
// Used in order to select only layout with background color.
.layout.grey,
.layout.blue,
.layout.dark-blue,
.layout.yellow {
	@extend %selector-of-colored-layout;
}



//* ANCHOR WITH CURVE *//
// Should be after bg colors in order to override padding-top.

.layout.curve {
	@include decorative-curve(top, var(--layoutPaddingTop));
}