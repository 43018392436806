
//* ANCHOR VARIABLES *//

// TABLE
$table-border-width: 1px;
$table-border-style: solid;
$table-border-color: Grey(2);
$table-border-radius: $border-radius-8;
$table-margin: 0 0 var(--globalVerticalSpacing) 0;

// TH
$th-padding: Spacing(12);
$th-font-size: rem-calc(16);
$th-line-height: rem-calc(24);
$th-font-weight: $font-weight-bold;
$th-text-align: left;
$th-color: var(--thTxColor, white);
$th-bg-color: var(--thBgColor, Grey(2));
$th-border-width: 0px;
$th-border-style: solid;
$th-border-color: transparent;

// TD
$td-padding: $th-padding;
$td-font-size: $th-font-size;
$td-line-height: $th-line-height;
$td-font-weight: $font-weight-regular;
$td-text-align: $th-text-align;
$td-color: Grey(2);
$td-bg-color: white;
$td-zebra-bg-color: Grey(-2);
$td-border-width: $th-border-width;
$td-border-style: $th-border-style;
$td-border-color: $th-border-color;



//* ANCHOR STYLING *//

table {
	max-width: 100%;
	margin: $table-margin;
	border: $table-border-width $table-border-style $table-border-color;
	// Horizontal scroll for tables that expand over the page width
	display: inline-block;
	overflow-x: auto;

	@media (hover: hover) and (pointer: fine) {
		@include scrollbars(8px, $scrollbar-thumb-color, $scrollbar-track-color);
	}


	// TH
	th {
		padding: $th-padding;
		font-size: $th-font-size;
		line-height: $th-line-height;
		font-weight: $th-font-weight;
		text-align: $th-text-align;
		border: $th-border-width $th-border-color $th-border-style;
	}

	thead th {
		color: $th-color;
		background-color: $th-bg-color;
	}


	// TD
	td {
		padding: $td-padding;
		font-size: $td-font-size;
		line-height: $td-line-height;
		font-weight: $td-font-weight;
		text-align: $td-text-align;
		color: $td-color;
		background-color: $td-bg-color;
		border: $td-border-width $td-border-color $td-border-style;
	}


	// ZEBRA
	tbody tr:nth-child(even) {
		td, th {
			background-color: $td-zebra-bg-color;
		}
	}


	// CAPTIONS
	caption {
		text-align: center;
		@extend %h3;
		padding: $th-padding;
		color: $th-color;
		background-color: var(--captionBgColor, #{lighten(Grey(2), 6%)});
	}


	// ROUNDED CORNERS
	border-collapse: separate;
	border-spacing: 0px;
	border-radius: $table-border-radius;


	// First line has a white space between border and background.
	$first-line-border-radius: $table-border-radius - 2px;

	// First line
	thead > tr:first-child,
	tbody:first-child > tr:first-child {

		& > th:first-child,
		& > td:first-child {
			border-radius: $first-line-border-radius 0 0 0;
		}

		& > th:last-child,
		& > td:last-child {
			border-radius: 0 $first-line-border-radius 0 0;
		}
	}

	// With captions
	caption + thead > tr:first-child,
	caption + tbody > tr:first-child {
		& > *:first-child,
		& > *:last-child {
			border-radius: 0px;
		}
	}


	// Last Line
	tbody > tr:last-child {

		& > th:first-child,
		& > td:first-child {
			border-radius: 0 0 0 $table-border-radius;
		}

		& > th:last-child,
		& > td:last-child {
			border-radius: 0 0 $table-border-radius 0;
		}
	}
}


// COLORS
table.yellow,
%table-yellow {
	--thTxColor: #{Grey(2)};
	--thBgColor: #{Yellow(0)};
	--captionBgColor: #{lighten(Yellow(0), 12%)};
}