
// NODE AND SEARCH LISTS
// This file also includes SearchList.

.NodeList,
.searchList {
	--layoutPaddingTop: 	var(--bgItemVerticalSpacing);



	//* ANCHOR BACKGROUND AND THEME *//

	@extend %colored-layout;

	&:not(.staff_members) {
		@extend %theme-grey;
	}



	//* ANCHOR TYPOGRAPHY *//

	& > div {
		& > h2,
		& > h3 {
			text-align: center;
		}
	}

	p.info {
		margin-top: -#{Spacing(16)};
		text-align: center;
		@extend %small-text;
	}

	p.results {
		@extend %h3;

		span {
			color: Grey(1);
		}
	}



	//* ANCHOR GRID LAYOUT *//

	& > div > ul.layout,
	& > div > ol.layout,
	& > div > .childAbuseMagicGrid > ul { // child abuse with magic grid

		// ITEMS ON GRID
		--itemsPerRow:			1;

		@include breakpoint($mobile-0 up) {
			--itemsPerRow:		2;
		}

		@include breakpoint($tablet-0 up) {
			--itemsPerRow:		3;
		}

		@include breakpoint($laptop-0 up) {
			--itemsPerRow:		4;
		}
	

		// GAPS
		--layoutColumnGap:				#{Spacing(24)};
		--layoutRowGap:					#{Spacing(24)};

		@include breakpoint($main-breakpoint up) {
			--layoutColumnGap:			#{Spacing(32)};
			--layoutRowGap:				#{Spacing(32)};
		}


		// STYLING
		text-align: left;
		@extend %list-neutralizer;
	}



	//* ANCHOR SPACINGS *//
	// Only for lists so that .footer is not affected

	ul.layout,
	ol.layout {
		
		// MORE BUTTON
		& + .button {
			margin-bottom: 0px;
			margin-top: var(--globalVerticalSpacing);
			margin-left: auto;
			margin-right: auto;
			display: block;
		}


		// HEADING 
		// In vacancies and multimedia
		& + h2,
		& + h3,
		& + .footer {
			margin-top: Spacing(40);

			@include breakpoint($main-breakpoint up) {
				margin-top: Spacing(48);
			}
		}
	}



	//* ANCHOR NO RESULTS TEXT *//

	div.no-results {
		padding-top: calc(var(--globalVerticalSpacing) * 2); 
		padding-bottom: var(--globalVerticalSpacing); 
		text-align: center;
	}
}



// NODE LIST RULES ONLY

.NodeList {

	//* ANCHOR STAFF MEMBERS CAROUSEL *//

	&.staff_members {
		@extend %theme-dark-blue;

		& > div {
			max-width: $column-width;
			margin-left: auto;
			margin-right: auto;
		}

		.results span {
			color: Grey(0);
		}

		// SLIDER
		.slick-slider {

			// Arrows
			.slick-arrow {
				@include breakpoint($slick-slider-breakpoint up) {
					align-self: center;
				}
			}
		}
	}



	//* ANCHOR TENDERS (PROCUREMENTS) *//

	&[class*="etendering"] > div > ul.layout,
	&[class*="tenders"] > div > ul.layout {

		--itemsPerRow:		1;

		@include breakpoint($mobile-1 up) {
			--itemsPerRow:		2;
		}
	}



	//* ANCHOR FAQS *//

	&.faq {

		// ITEMS ON GRID
		& > div > ul.layout {
	
			@include breakpoint($mobile-0 up) {
				--itemsPerRow:		1;
			}
	
			@include breakpoint($tablet-0 up) {
				--itemsPerRow:		2;
			}
	
			@include breakpoint($laptop-0 up) {
				--itemsPerRow:		3;
			}
		}
	}



	//* ANCHOR PUBLICATIONS *//
	// Change image height only in lists with publications

	&[class*="publications"],
	&.guides {
		--nodeTeaserImageWrapperPaddingT: #{$teaser-image-wrapper-padding-top-portrait};

		// ITEMS ON GRID
		& > div > ul.layout {

			@include breakpoint($mobile-1 up) {
				--itemsPerRow:		3;
			}

			@include breakpoint($tablet-1 up) {
				--itemsPerRow:		4;
			}
	
			@include breakpoint($laptop-2 up) {
				--itemsPerRow:		5;
			}
		}
	}



	//* ANCHOR CRIME AREAS *//
	// Change image height only in lists with crime areas
	
	&.crime_areas {
		--nodeTeaserImageWrapperPaddingT: 	75.75%;
	}



	//* ANCHOR CHILD ABUSE *//
	// With magic grid

	&.child_abuse .childAbuseMagicGrid ul {
		@extend %list-neutralizer;
		display: block;
		margin: calc(-1 * var(--layoutRowGap) * 0.5) calc(-1 * var(--layoutColumnGap) * 0.5);

		& > li {
			width: calc(100% / var(--itemsPerRow));
			padding: calc(var(--layoutRowGap) * 0.5) calc(var(--layoutColumnGap) * 0.5);
		}
	}



	//* ANCHOR AGREEMENTS *//
	// Included here and not in teasers so that it doesn't apply in RelatedContent.

	&.agreements .NodeTeaser {
		@extend %blue-bg-teaser;
	}
}