
// NODE PANEL

.NodePanel {
	padding-left: var(--pageSpacing);
	padding-right: var(--pageSpacing);



	//* ANCHOR COMPONENTS WITH MAX WIDTH *//

	& > .ClickableDrupalContent,
	& > .ImageGallery,
	& > .Downloads,
	& > .Meta,
	& > .TopMeta,
	& > .agreementMeta,
	& > .Weblinks,
	& > .Empact,
	& > .Block,
	& > .Form,
	& > .Languages,
	& > .countryAgencies,
	& > .countryInfo,
	& > .NewsletterSubscriptionManagement,
	& > .relatedNewsAlias,
	& > .documentColumnWrapper
	{

		@include breakpoint($main-breakpoint up) {
			max-width: $column-width;
			margin-left: auto;
			margin-right: auto;
		}
	}


	
	//* ANCHOR VERTICAL SPACING BETWEEN COMPONENTS *//
	
	// CHILD ELEMENTS
	div, form, nav {
		@extend %child-element;
	}

	& > %child-element + %child-element {
		margin-top: var(--nodePanelVerticalSpacing);
	}



	//* ANCHOR NODE HEADER VERTICAL SPACING *//

	// WITH NON LAYOUT COMPONENTS
	.NodeHeader + *:not(.layout) {
		margin-top: $node-header-margin-bottom;
	}


	// LAYOUT COMPONENT
	.NodeHeader + .layout {
		--layoutPaddingTop: #{$node-header-margin-bottom};
	}


	// COMPONENTS WITH BACKGROUND COLOR
	& > .layout:not(.RelatedContent) + .layout {
		margin-top: 0px;
	}


	// CONTENT WITH LAYOUT AS FIRST CHILD
	.layout + .ClickableDrupalContent > %selector-of-colored-layout:first-child {
		margin-top: calc(-1 * var(--nodePanelVerticalSpacing));
	}

	.NodeHeader.layout + .ClickableDrupalContent > %selector-of-colored-layout:first-child {
		margin-top: calc(-1 * #{$node-header-margin-bottom});
		padding-top: $node-header-margin-bottom;
	}



	//* ANCHOR LAST COMPONENT *//
	
	& > *:last-child:not(.modal),
	& > .ImageGallery:nth-last-child(2) {
		padding-bottom: var(--bgItemVerticalSpacing);
	}

	& > .ClickableDrupalContent:last-child {
		padding-bottom: 0px;

		& > *:last-child {
			margin-bottom: var(--bgItemVerticalSpacing);
		}

		& > %selector-of-colored-layout:last-child {
			margin-bottom: 0px;
			padding-bottom: var(--bgItemVerticalSpacing);
		}
	}



	//* ANCHOR PADDING BOTTOM ON LAYOUT COMPONENTS WITH BG COLOR *//

	& > %selector-of-colored-layout:not(.NodeHeader) {
		--layoutPaddingBottom: var(--bgItemVerticalSpacing);
	}
}