
// COLOR THEMES

// They include all necessary css variables so that every element works well on each background color.

// In order for nested boxes (inside layouts or other boxes) to have the proper color and not inherit the color styles of the parent element, all variables should be declared inside each theme. For example a grey box nested inside a blue themed element will inherit the blue theme's variables that are not specified inside the grey theme. 

// !! IMPORTANT !! Any new variable added in the map should be added for each and every color.


//* ANCHOR MAP *//

$color-theme-variables: (
	'white': (
		themeColor: 					$body-bg-color,
		txColor:						$text-color,
		linkColor: 						$link-color,
		linkContrastColor: 				$link-contrast-color,
		bqBrColor: 						$bq-br-color,
		bqTxColorSecondary: 			$bq-tx-color-secondary,
		bqIconBgColor: 					$body-bg-color,
		decorationColor: 				$decoration-yellow,
		expandableBrColor: 				$expandable-br-color,
		expandableBtnTxColorHover: 		$expandable-btn-tx-color-hover,
		curveColor:						$curve-color,
		decorativeHeadingTxColor:		$text-color,
		decorativeHeadingLineColor: 	Yellow(0),
		slickSliderDotBgColor:			$slick-slider-dot-bg-color
	),
	'grey': (
		themeColor: 					$bg-grey,
		txColor:						$text-color,
		linkColor: 						Blue(0),
		linkContrastColor: 				white,
		bqBrColor: 						Grey(0),
		bqTxColorSecondary: 			$bq-tx-color-secondary,
		bqIconBgColor: 					$bg-grey,
		decorationColor: 				$decoration-yellow,
		expandableBrColor: 				$expandable-br-color,
		expandableBtnTxColorHover: 		Blue(1),
		curveColor:						$bg-grey,
		decorativeHeadingTxColor:		$text-color,
		decorativeHeadingLineColor: 	Yellow(0),
		slickSliderDotBgColor:			$slick-slider-dot-bg-color,
		slickSliderDotBgColorHover:		$slick-slider-dot-bg-color-hover,
		slickSliderDotBgColorActive:	$slick-slider-dot-bg-color-active

	),
	'yellow': (
		themeColor: 					$bg-yellow,
		txColor:						$text-color,
		linkColor: 						$link-color,
		linkContrastColor: 				$link-contrast-color,
		bqBrColor: 						Grey(2),
		bqTxColorSecondary: 			$text-color,
		bqIconBgColor: 					$bg-yellow,
		decorationColor: 				$decoration-blue,
		expandableBrColor: 				$expandable-br-color,
		expandableBtnTxColorHover: 		Blue(1),
		curveColor:						$bg-yellow,
		decorativeHeadingTxColor:		Yellow(4),
		decorativeHeadingLineColor: 	Yellow(4),
		slickSliderDotBgColor:			$slick-slider-dot-bg-color,
		slickSliderDotBgColorHover:		$slick-slider-dot-bg-color-hover,
		slickSliderDotBgColorActive:	Blue(2)

	),
	'blue': (
		themeColor: 					$bg-blue,
		txColor:						white,
		linkColor: 						Secondary(-1),
		linkContrastColor: 				Blue(3),
		bqBrColor: 						Grey(0),
		bqTxColorSecondary: 			white,
		bqIconBgColor: 					$bg-blue,
		decorationColor: 				$decoration-yellow,
		expandableBrColor: 				Grey(-1),
		expandableBtnTxColorHover: 		Secondary(0),
		curveColor:						$bg-blue,
		decorativeHeadingTxColor:		Grey(-1),
		decorativeHeadingLineColor: 	Grey(-1),
		slickSliderDotBgColor:			Orange(0),
		slickSliderDotBgColorHover:		Secondary(0),
		slickSliderDotBgColorActive:	white

	),
	'dark-blue': (
		themeColor: 					$bg-dark-blue,
		txColor:						white,
		linkColor: 						Secondary(-1),
		linkContrastColor: 				Blue(3),
		bqBrColor: 						Grey(0),
		bqTxColorSecondary: 			white,
		bqIconBgColor: 					$bg-dark-blue,
		decorationColor: 				$decoration-yellow,
		expandableBrColor: 				Grey(0),
		expandableBtnTxColorHover: 		Secondary(0),
		curveColor:						$bg-dark-blue,
		decorativeHeadingTxColor:		Yellow(0),
		decorativeHeadingLineColor: 	Yellow(0),
		slickSliderDotBgColor:			Orange(0),
		slickSliderDotBgColorHover:		Secondary(0),
		slickSliderDotBgColorActive:	white

	)
);



//* ANCHOR EXPORT LOOP *//

@each $color, $variables-map in $color-theme-variables {

	// 1st @each creates placeholders for each color
	%theme-#{$color} {

		// 2nd @each exports variables inside the placeholder.
		@each $variable, $value in $variables-map {

			// Curve color needs a more specific selector in order to obtain the theme's color and not be affected by the box's background.
			@if $variable == curveColor {
				.box.curve:after,
				&.layout + .layout.curve:after, // for sequences of layouts with curves
				&.layout + div > .layout.curve:first-child:after // for cases were the next element after a layout is a wrapper but the wrapper's first child is .layout.curve (NodeList footer)
				{
					--#{$variable}: #{$value};
				}
			}

			@else {
				--#{$variable}: #{$value};
			}
		}
	}
}